import React, { useState } from "react";
import ReactPlayer from "react-player";

function Video2018() {
  const [ylink, setYlink] = useState(
    "https://www.youtube.com/watch?v=oOrPeIpV6wk"
  );
  const [yname, setYname] = useState("Biography of a 'Platform' - Utkarsh");
  const [idty, setIdty] = useState("1");

  const playlist = [
    {
      id: "1",
      name: "Biography of a 'Platform' - Utkarsh",
      link: "https://www.youtube.com/watch?v=oOrPeIpV6wk",
    },
    {
      id: "2",
      name: "Myntra's Web Tech Journey - Vijaya Krishna Kudva (Myntra)",
      link: "https://www.youtube.com/watch?v=hnSBV1ANAYk",
    },
    {
      id: "3",
      name: "Showcasing relevant, performant and visually diverse content on Homepage - Rahul Pradeep",
      link: "https://www.youtube.com/watch?v=NjJaZSDySOA",
    },
    {
      id: "4",
      name: "Just In Time Personalization - Vaibhav Srivastava, Arun Agarwal",
      link: "https://www.youtube.com/watch?v=2NXVCzPfrU4",
    },
    {
      id: "5",
      name: "Intro to AI for India - Mayur Datar",
      link: "https://www.youtube.com/watch?v=2P0Skb7gRAg",
    },
    {
      id: "6",
      name: "Interesting logistics problems (and data solutions) at Zoomcar - Vinayak Hegde (Zoomcar)",
      link: "https://www.youtube.com/watch?v=bBQGJWeE6FY",
    },
    {
      id: "7",
      name: "Operating, Optimizing & Governing Big Data Platform at Scale - Rishabh Dua, Arya Ketan",
      link: "https://www.youtube.com/watch?v=p1vvOmYyISU",
    },
    {
      id: "8",
      name: "Natural Language Processing R&D in Alibaba - Luo Si (Alibaba)",
      link: "https://www.youtube.com/watch?v=_FfgbKvZdXU",
    },
    {
      id: "9",
      name: "Building Knowledgeable Machines - Partha Talukdar (IISc)",
      link: "https://www.youtube.com/watch?v=HWjwA6OU2Ks",
    },
    {
      id: "10",
      name: "Propagation of connectivity policies across multi-datacenter networks - Neeraj Bisht, Prasad Reddy",
      link: "https://www.youtube.com/watch?v=-ZpvJDngRX8",
    },
    {
      id: "11",
      name: "The Evolution of Application Security in E-Commerce - Prajal Kulkarni, Mohan Kallepalli",
      link: "https://www.youtube.com/watch?v=sQD5aEBizxw",
    },
    {
      id: "12",
      name: "OLTP in Supply Chain: Handling super-scale and change propagation - Vijay Babu, Rajesh Kannan",
      link: "https://www.youtube.com/watch?v=rUJPhb0RIhw",
    },
    {
      id: "13",
      name: "Clustering millions of search queries to detect missing products - Akshit Jain, Vignesh N",
      link: "https://www.youtube.com/watch?v=X-0cz5_5WFs",
    },
    {
      id: "14",
      name: "Demand modelling with RNN and GAN - Yadunath Gupta (Myntra), Aruna Rajan (Myntra)",
      link: "https://www.youtube.com/watch?v=iBFluZ_tsFE",
    },
    {
      id: "15",
      name: "Geocoder - Vishal Srivastava, Lucky Dhakad",
      link: "https://www.youtube.com/watch?v=4uTrWVK49Fo",
    },
    {
      id: "16",
      name: "You are Who You Shop For: A Case for 'Behavioural' Demography - Abhranil Chatterjee, Sneha Shukla",
      link: "https://www.youtube.com/watch?v=sNHKXQOGzRE",
    },
    {
      id: "17",
      name: "Shop your Right Size - Ghani Mohammed Abdulla (Myntra), Shreya Singh (Myntra)",
      link: "https://www.youtube.com/watch?v=dm3ITsu0RC8",
    },
    {
      id: "18",
      name: "Machine Learning Platform at Flipkart - Naresh Sankapelly, Roshan Nair",
      link: "https://www.youtube.com/watch?v=b5B_X2EEo44",
    },
    {
      id: "19",
      name: "Scaling write-heavy OLTP systems with strong data guarantees - Pradeep S, Gokulvanan V Velan",
      link: "https://www.youtube.com/watch?v=U4BqVgA1pLk",
    },
    {
      id: "20",
      name: "What’s in a query? Understanding user’s need! - Mohit Kumar, Subhadeep Maji",
      link: "https://www.youtube.com/watch?v=el1cROZX6no",
    },
    {
      id: "21",
      name: "Recommendation of High Quality Representative Reviews in e-commerce - Prajit Nadkarni, Debanjan Paul",
      link: "https://www.youtube.com/watch?v=7Am3ZLUrFWM",
    },
    {
      id: "22",
      name: "Eliciting Topics from large corpora using Admixtures - Chiranjib Bhattacharyya (IISc)",
      link: "https://www.youtube.com/watch?v=NTWWPm-RjgA",
    },
    {
      id: "23",
      name: "Resource optimisation for Search at Scale in Flipkart - Monish Gandhi",
      link: "https://www.youtube.com/watch?v=PCFJ7iZ1Uvs",
    },
    {
      id: "24",
      name: "Maintenance of Large Data Centers - Monica Movva, Aftab Ahmad Ansari",
      link: "https://www.youtube.com/watch?v=oQUtHuY8FII",
    },
    {
      id: "25",
      name: "Vorta (Rapid as a Service) - Sivakumar Tangudu (Myntra)",
      link: "https://www.youtube.com/watch?v=ViGA_4CS52Y",
    },
    {
      id: "26",
      name: "Automatic Speech Recognition of Code-switched Speech - Sunayna Sitaram (Microsoft Research)",
      link: "https://www.youtube.com/watch?v=Ly7WTusBoTU",
    },
    {
      id: "27",
      name: "Evolution of AI Thinking for building Products of the Next Decade - Shailesh Kumar",
      link: "https://www.youtube.com/watch?v=dWXFNRiEPA8",
    },
    {
      id: "28",
      name: "Powering Product Discovery - Suvodip Dey, Akash Khandelwal",
      link: "https://www.youtube.com/watch?v=OVPgx7AtRmM",
    },
    {
      id: "29",
      name: "Lego Building blocks to model Supply Chain Workflows - Indranil Banerjee, Sushrut Joshi",
      link: "https://www.youtube.com/watch?v=LFF_jyPLpKc",
    },
  ];

  const playvideo = (link, name, id) => {
    setYlink(link);
    setYname(name);
    setIdty(id);
  };

  return (
    <div
      className="grid col-span-1 md:grid-cols-3 gap-x-10 h-full divide-y md:divide-y-0 md:divide-x divide-gray-800"
      // style={{ border: "1px solid white" }}
    >
      <div
        className="md:col-span-2 text-white h-full"
        // style={{ border: "1px solid red" }}
      >
        <h1 className="text-white pb-2 md:pb-4">2018</h1>
        <div className="h-full pb-12 md:pb-0">
          <ReactPlayer
            url={ylink}
            controls={true}
            playing={true}
            width="100%"
            height="80%"
          />
          <h1 className="pt-3 md:pt-6 text-sm md:text-lg">{yname}</h1>
        </div>
      </div>
      <div
        className="col-span-1 p-2 divide-y divide-gray-800"
        style={{
          // border: "1px solid green",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        {playlist?.map((list) => {
          return (
            <div
              className="text-white grid grid-cols-5 py-2 cursor-pointer rounded-sm"
              style={{
                backgroundColor:
                  idty === list.id ? "rgba(46, 49, 146,0.4)" : null,
              }}
              onClick={() => playvideo(list.link, list.name, list.id)}
            >
              <div className="col-span-2">
                <div
                  className="w-full h-20 md:h-36 rounded-lg flex items-center justify-center"
                  style={{
                    // background:
                    //   "linear-gradient(90deg, #2E3192 -0.01%, #662D91 99.99%)",
                    backgroundImage: `url('/assets/thumbnails/2018/${list.id}.jpg')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <img
                    src="/assets/play.png"
                    alt="play"
                    className="h-14 md:h-auto"
                  />
                </div>
              </div>
              <div className="col-span-3 pl-2 flex items-center text-xs md:text-md">
                {list.name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Video2018;
