import React, { useState } from "react";
import ReactPlayer from "react-player";

function Video2016() {
  const [ylink, setYlink] = useState(
    "https://www.youtube.com/watch?v=OsfuOUPB1CA"
  );
  const [yname, setYname] = useState(
    "Opening + Introduction to Slash N - Ravi G"
  );
  const [idty, setIdty] = useState("1");

  const playlist = [
    {
      id: "1",
      name: "Opening + Introduction to Slash N - Ravi G",
      link: "https://www.youtube.com/watch?v=OsfuOUPB1CA",
    },
    {
      id: "2",
      name: "Keynote - Sachin Bansal",
      link: "https://www.youtube.com/watch?v=sHNtWgAHLLQ",
    },
    {
      id: "3",
      name: "Leadership From Constraint: How India is Leading on the Client - Alex Russel",
      link: "https://www.youtube.com/watch?v=ahWowazd1mg",
    },
    {
      id: "4",
      name: "FLinked - Stitching Data Together - Vaibhav Srivastava, Anish Bansal",
      link: "https://www.youtube.com/watch?v=DZG6RnXc61c",
    },
    {
      id: "5",
      name: "Machine Learning Platform at Flipkart: Agility with Scale - Nikhil Ketkar, Sudhir Kumar Reddy",
      link: "https://www.youtube.com/watch?v=6sLsIse2avo",
    },
    {
      id: "6",
      name: "Precision Medicine: Using Computation in our Fight Against Disease - Pranav Tiwari",
      link: "https://www.youtube.com/watch?v=ZlFD7qYtk0k",
    },
    {
      id: "7",
      name: "PEQ: An Explainable, Specification-based, Aspect-oriented Product Comparator - Samik Datta",
      link: "https://www.youtube.com/watch?v=VclnQ2b_C0U",
    },
    {
      id: "8",
      name: "Machine Learning Models for Product Classification - Dr. Ravindra Babu, Ashendra Bansal, Manas Kriti",
      link: "https://www.youtube.com/watch?v=qEgzbL0xBqg",
    },
    {
      id: "9",
      name: "Go, Write Your Own Datastore - Tahir Hashmi & Hari A V",
      link: "https://www.youtube.com/watch?v=BTyCwWoI3NU",
    },
    {
      id: "10",
      name: "Visual Enrichment and Visual Interactions to find your Favorite Fashion - Vijay Gabale",
      link: "https://www.youtube.com/watch?v=ZB6bP9OSaRc",
    },
    {
      id: "11",
      name: "Architecting for Innovation - India Stack Experience - Pramod K Varma",
      link: "https://www.youtube.com/watch?v=aFhKLu7mL_w",
    },
    {
      id: "12",
      name: "Decoding Fashion Contexts Using Word Embeddings - Deepak Warrier, Sagar Arora",
      link: "https://www.youtube.com/watch?v=KYN3nznYii4",
    },
    {
      id: "13",
      name: "Journey Platform, Event Processing based on Apache State charts - Sandeep Kohli, Kinshuk Bairagi",
      link: "https://www.youtube.com/watch?v=3qrZFwPtErA",
    },
    {
      id: "14",
      name: "Kafka @ scale: Engineering Scenarios and Performance Optimizations - Achanta Vamsi & Sharath Gururaj",
      link: "https://www.youtube.com/watch?v=h8riHBDn3FQ",
    },
    {
      id: "15",
      name: "Offline - First Progressive Web Apps - Aditya Punjani",
      link: "https://www.youtube.com/watch?v=y6GaRMjNCUQ",
    },
    {
      id: "16",
      name: "Hierarchical Distributed Controller - Kaustubh Priye, Shashwat",
      link: "https://www.youtube.com/watch?v=kpucm3_XajQ",
    },
    {
      id: "17",
      name: "AI and Big Data Applications in Service of Society - Dr Raj Reddy",
      link: "https://www.youtube.com/watch?v=ZRzNDdgE32s",
    },
    {
      id: "18",
      name: "Fire-side Chat: Sanjay Swamy & Ravi G",
      link: "https://www.youtube.com/watch?v=lVmjrcdrLT8",
    },
    {
      id: "19",
      name: "Relevancy of Functional Programming Paradigms in Real World (Ads selection) - Sathish Kumar",
      link: "https://www.youtube.com/watch?v=weDuahV8P9A",
    },
    {
      id: "20",
      name: "Natural Language Address Search - Challenges and Solutions - Keshav Prawasi",
      link: "https://www.youtube.com/watch?v=rMex8H8lnH4",
    },
    {
      id: "21",
      name: "Ratings and Reviews Fraud Detection - Abhishek Saxena, Mohit Kumar, Disha Makhija",
      link: "https://www.youtube.com/watch?v=AexT8IW6yuY",
    },
    {
      id: "22",
      name: "Real Time Fulfilment Planning - Jagadeesh Huliyar",
      link: "https://www.youtube.com/watch?v=OOSHZT5u7pM",
    },
    {
      id: "23",
      name: "Artificial Intelligence to Solve eCommerce Logistics “Last Mile” Problem - Dr Kabir Rustogi",
      link: "https://www.youtube.com/watch?v=OpIiCSibZdw",
    },
    {
      id: "24",
      name: "BlinxJS: Managing the Evolution in Javascript Ecosystem - Durgesh Priyaranjan & Shivani Tiwari",
      link: "https://www.youtube.com/watch?v=vY1tv2ZugGA",
    },
    {
      id: "25",
      name: "Powering Fashion e-Commerce with Computer Vision and Deep Learning - Vishnu Makkapati",
      link: "https://www.youtube.com/watch?v=-LXAHdIBDL4",
    },
    {
      id: "26",
      name: "Tiered Data Store - Sumit Pilankar, Ashudeep Sharma & Harshit Gangal",
      link: "https://www.youtube.com/watch?v=r5GOgq6FpjI",
    },
    {
      id: "27",
      name: "Yoda: Helping Customers Find The Right Answers - Subhadeep Maji, Mukesh Kumar & Ashish Chaturvedi",
      link: "https://www.youtube.com/watch?v=BYSkkJ37m_s",
    },
    {
      id: "28",
      name: "SAGEL: Smart Address Geocoding Engine for Supply-Chain Logistics - Abhranil Chatterjee",
      link: "https://www.youtube.com/watch?v=mrwjePSaf6s",
    },
    {
      id: "29",
      name: "Recommending Repeat Purchases using Product Segment Statistics - Kratika Gupta & Akash Khandelwal",
      link: "https://www.youtube.com/watch?v=21KIXXldCGk",
    },
  ];

  const playvideo = (link, name, id) => {
    setYlink(link);
    setYname(name);
    setIdty(id);
  };

  return (
    <div
      className="grid col-span-1 md:grid-cols-3 gap-x-10 h-full divide-y md:divide-y-0 md:divide-x divide-gray-800"
      // style={{ border: "1px solid white" }}
    >
      <div
        className="md:col-span-2 text-white h-full"
        // style={{ border: "1px solid red" }}
      >
        <h1 className="text-white pb-2 md:pb-4">2016</h1>
        <div className="h-full pb-12 md:pb-0">
          <ReactPlayer
            url={ylink}
            controls={true}
            playing={true}
            width="100%"
            height="80%"
          />
          <h1 className="pt-3 md:pt-6 text-sm md:text-lg">{yname}</h1>
        </div>
      </div>
      <div
        className="col-span-1 p-2 divide-y divide-gray-800"
        style={{
          // border: "1px solid green",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        {playlist?.map((list) => {
          return (
            <div
              className="text-white grid grid-cols-5 py-2 cursor-pointer rounded-sm"
              style={{
                backgroundColor:
                  idty === list.id ? "rgba(46, 49, 146,0.4)" : null,
              }}
              onClick={() => playvideo(list.link, list.name, list.id)}
            >
              <div className="col-span-2">
                <div
                  className="w-full h-20 md:h-36 rounded-lg flex items-center justify-center"
                  style={{
                    // background:
                    //   "linear-gradient(90deg, #2E3192 -0.01%, #662D91 99.99%)",
                    backgroundImage: `url('/assets/thumbnails/2016/${list.id}.jpg')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <img
                    src="/assets/play.png"
                    alt="play"
                    className="h-14 md:h-auto"
                  />
                </div>
              </div>
              <div className="col-span-3 pl-2 flex items-center text-xs md:text-md">
                {list.name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Video2016;
