import logo from "./logo.svg";
import "./App.css";
import HomePage from "./pages/homepage";
import Routes from "./Routes";

function App() {
  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
