import React, { useEffect } from "react";

function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* <Layout> */}
      <nav className="fixed top-0 w-screen left-0 z-50">
        <div className="bg-black/30 py-2">
          <div className="flex justify-between container mx-auto px-10 py-2">
            <img
              src="./assets/flipkart.png"
              alt="Flipkart logo"
              className="h-8 self-end"
            />
            <div className="inline-flex hidden items-center uppercase text-white">
              <div className="px-3 hover:text-orange-400 cursor-pointer">
                Home
              </div>
              <div className="h-3 bg-white border" />
              <div className="px-3">Home</div>
              <div className="h-3 bg-white border" />
              <div className="px-3">Home</div>
            </div>
          </div>
        </div>
      </nav>

      {/* ---------------------------------------------------------------------------------- */}

      <div className="container mx-auto md:px-10 py-16 pb-24 relative">
        <div className="relative">
          <div className="text-left text-3xl md:text-5xl font-bold text-white md:px-32 px-16 pt-6 md:pt-10">
            Privacy Policy
          </div>
          <br />
          <img
            className
            style={{ marginTop: "-60px" }}
            src="./assets/underline.svg"
            alt="underline"
          />
          <div className=" text-light text-white px-10 md:px-32 relative">
            We value the trust you place in us and recognize the importance of
            information privacy. This privacy policy describes how Flipkart
            Internet Private Limited and its affiliates (collectively “Flipkart,
            we, our, us”) collect, use, share or otherwise process your personal
            information using the Website (https://slashn.flipkart.net/)
            designed for events organised by Flipkart (hereinafter referred to
            as “Platform”).
            <br />
            <br />
            While you may be able to browse certain sections of the Platform
            without registering with us, however, please note that Platform is
            designed and developed to conduct Tech Events in India. Your
            personal information may primarily be stored and processed in India
            and may have data protection laws that are different from those that
            apply in the country in which you are located. By accessing our
            Platform, providing any information or availing our services, or
            participating in any of the events organised by Flipkart in this
            Platform, you expressly agree to be bound by the terms and
            conditions of this privacy policy, the Terms of Use and the
            applicable terms and conditions of the event(s), and agree to be
            governed by the laws of India including but not limited to the laws
            applicable to data protection and privacy. If you do not agree to
            the Terms of Use or terms of this Privacy Policy, please refrain
            from accessing the Platform or using our Services.
            <br />
            <br />
            <strong className="">Collection of Your Information</strong>
            <br />
            <br />
            We collect your information directly from you at the time of
            registration/signup via web-forms”, or information provided by you
            from time to time. You can browse the Platform without telling us
            who you are or revealing any personal information about yourself.
            Once you give us your personal information, you are not anonymous to
            us. Where possible, we indicate which fields are required and which
            fields are optional at the time of registration/sign-up.
            <br />
            <br />
            We may track your participation in various events, and other
            information that you choose to provide on our Platform. We use this
            information to do internal research on our users' demographics,
            interests, and behaviour to better understand, protect and serve our
            users. This information may include the URL that you just came from
            (whether this URL is on our Platform or not), which URL you next go
            to (whether this URL is on our Platform or not), your computer
            browser information, and your IP address.
            <br />
            <br />
            We may collect personal information (such as email address, address,
            name, phone number) from you when you participate in any event or
            contest. While you can browse some sections of our Platform without
            being a registered member, certain activities (such as participating
            in an event, etc.) do require registration. We use your contact
            information to send you notifications around similar events based on
            your previous participation in the events and your interests.
            <br />
            <br />
            If you send us personal correspondence, such as emails or letters,
            or if other users or third parties send us correspondence about your
            activities or postings on the Platform, we may collect such
            information into a file specific to you.
            <br />
            <br />
            <strong>Use / Purpose of Collection</strong>
            <br />
            <br />
            We use your information to communicate to you about the event and to
            enable registration for the slash N events. If you engage with our
            platform on social media (for instance liking a post, reposting,
            commenting, retweeting, mentioning, or following us), we'll have
            access to your interactions and profile information. We will retain
            information even if you remove it from the social media site. We may
            collect certain information posted on these social media platforms
            such as comments, direct messages, media comments, profile image,
            order details, and other information that you choose to provide or
            when you use our platform. <br />
            <br /> We use your personal information to provide you information
            about the event through calls and messages. We might process your
            information to check your eligibility for the event you show your
            interest towards. The event shall be conducted in a virtual mode
            that will be streamed through a third-party platform. The recording
            of the same requires your due consent. We might process your
            information to recommend your participation towards any event. OTP
            shall be collected from the participants to enable their log in the
            virtual platform created for the event. Upon login in, the attendees
            can view different stages where multiple sessions are conducted. We
            will collect additional information from you in case you win any of
            the events conducted to acknowledge your successful participation.{" "}
            <br />
            <br /> We use your personal information for the purpose of this
            event and any transactions arising out of it, to enhance your
            experience; detect and protect us against error, to authenticate
            your identity; enforce our terms and conditions; and as otherwise
            described to you at the time of collection of information. You can
            decline sharing with us certain information by not providing the
            information. <br />
            <br /> We may ask you to participate in an optional survey asking
            for your feedback on the event conducted. During this survey we may
            collect personal information such as your name, email id, contact
            number, organisation, designation in the organisation. We shall
            store this information for a limited period for enhancing your
            experience of the event and to resolve any matter that you would
            like us to address. We may use the information for contacting you
            for future events. None of the information asked for in the survey
            will be mandatory and will depend on your voluntary participation
            <br />
            <br />
            <strong>Sharing of personal information</strong>
            <br />
            <br />
            We may share personal information internally within Flipkart group
            companies, affiliates, related companies and with other third
            parties such as business partners for purposes identified in this
            privacy policy. This disclosure may be required for us to provide
            you access to our products and services; for participation in the
            event or any such purpose; to respond to your queries; for resolving
            your complaints; for enhancing your experience; for providing
            feedback of event or services; to provide offers and awards; to
            inform you on future events, to comply with our legal obligations;
            to conduct market research or surveys; to enforce our terms of use;
            to facilitate our marketing and advertising activities; to analyse
            data; for customer service assistance; to prevent, detect, mitigate,
            and investigate fraudulent or illegal activities related to our
            event and services.
            <br />
            <br />
            We and our affiliates will share some or all of your personal
            information with another business entity should we (or our assets)
            plan to merge with, or be acquired by that business entity, or
            reorganization, amalgamation, restructuring of business. Should such
            a transaction occur, other business entity (or the new combined
            entity) will be required to follow this privacy policy with respect
            to your personal information.
            <br />
            <br />
            We may disclose personal information if required to do so by law or
            in the good faith belief that such disclosure is reasonably
            necessary to respond to subpoenas, court orders, or other legal
            process. We may disclose personal information to law enforcement
            agencies, third party rights owners, or others in the good faith
            belief that such disclosure is reasonably necessary to: enforce our
            terms of use or privacy policy; respond to claims that an
            advertisement, posting or other content violates the rights of a
            third party; or protect the rights, property or personal safety of
            our users or the general public.
            <br />
            <br />
            <strong>Security Precautions</strong>
            <br />
            <br />
            We maintain reasonable physical, electronic and procedural
            safeguards to protect your information. Whenever you access your
            account information, we offer the use of a secure server. Once your
            information is in our possession we adhere to our security
            guidelines to protect it against unauthorized access. However, the
            inherent security implications of data transmission over the
            internet and the World Wide Web cannot always be guaranteed as
            completely secure, and therefore, there would always remain certain
            inherent risks.
            <br />
            <br />
            <strong>Children Information</strong>
            <br />
            <br />
            Use of our Platform is available only to persons who can form a
            legally binding contract under the Indian Contract Act, 1872. We do
            not knowingly solicit or collect personal information from children
            under the age of 18 years. If you have shared any personal
            information of children under the age of 18 years, you represent
            that you have the authority to do so and permit us to use the
            information in accordance with this privacy policy.
            <br />
            <br />
            <strong>Data Retention</strong>
            <br />
            <br />
            We retain your personal information in accordance with applicable
            laws, for a period no longer than is required for the purpose for
            which it was collected or as required under any applicable law.
            However, we may retain data related to you if we believe it may be
            necessary to prevent fraud or future abuse, to enable us to exercise
            its legal rights and/or defend against legal claims or if required
            by law or for other legitimate purposes. We may continue to retain
            your data in anonymised form for analytical and research purposes.
            <br />
            <br />
            <strong>Your Rights</strong>
            <br />
            <br />
            We take every reasonable step to ensure that your personal
            information that we process is accurate and, where necessary, kept
            up to date, and any of your personal information that we process
            that you inform us is inaccurate (having regard to the purposes for
            which they are processed) is rectified. You may access, correct,
            withdraw your consent, and update your personal information directly
            through the functionalities provided on the platform . You can also
            write to us at the contact details provided below to assist you with
            these requests. Please mention “Data Subject Privacy Request” in the
            subject line of your communication. We will verify such requests
            before acting upon your request. In the event you withdraw consent
            or delete certain mandatory information, it may hamper your access,
            or restrict provision of our services to you for which we consider
            that information to be necessary.
            <br />
            <br />
            <strong>Consent</strong>
            <br />
            <br />
            By visiting our Platform or by providing your information, you
            consent to the collection, use, storage, disclosure and otherwise
            processing of your information (including sensitive personal
            information) on the Platform in accordance with this Privacy Policy.
            If you disclose to us any personal information relating to other
            people, you represent that you have the authority to do so and to
            permit us to use the information in accordance with this Privacy
            Policy. You further understand that the Platform will be used for
            Tech events such as Slash N. By sharing your information for
            participating in the event, you consent that the information will be
            used for organizing the event virtually and the sessions may be
            recorded for internal purposes.
            <br />
            <br />
            You, while providing your personal information over the Platform or
            any partner platforms or establishments, consent to us (including
            our other corporate entities, affiliates, lending partners,
            technology partners, marketing channels, business partners and other
            third parties) to contact you through SMS, instant messaging apps,
            call and/or e-mail for the purposes specified in this Privacy
            Policy.
            <br />
            <br />
            <strong>Changes to this Privacy Policy</strong>
            <br />
            <br />
            Please check our privacy policy periodically for changes. We may
            update this privacy policy to reflect changes to our information
            practices. We will alert you to significant changes by posting the
            date our policy got last updated or placing a notice on our
            Platform.
            <br />
            <br />
            <strong>Grievance Officer</strong>
            <br />
            <br />
            In accordance with Information Technology Act 2000 and rules made
            there under, the name and contact details of the Grievance Officer
            are provided below:
            <br />
            <br />
            Mr. Shremanth M<br />
            <br />
            Designation: Senior Manager
            <br />
            <br />
            Address: Flipkart Internet Pvt Ltd.
            <br />
            <br />
            Embassy tech village
            <br />
            <br />
            8th floor Block 'B' Devarabeesanahalli Village,
            <br />
            <br />
            Varthur Hobli, Bengaluru East Taluk,
            <br />
            <br />
            Bengaluru District,
            <br />
            <br />
            Karnataka, India, 560103.
            <br />
            <br />
            Phone: 1800 202 9898
            <br />
            <br />
            Email: grievance.officer@flipkart.com
            <br />
            <br />
            Time: Mon - Sat (9:00 - 18:00) <br />
            Last Updated: 4th January 2022
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
