import React, { useEffect } from "react";

function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <nav className="fixed top-0 w-screen left-0 z-50">
        <div className="bg-black/30 py-2">
          <div className="flex justify-between container mx-auto px-10 py-2">
            <img
              src="./assets/flipkart.png"
              alt="Flipkart logo"
              className="h-8 self-end"
            />
            <div className="inline-flex hidden items-center uppercase text-white">
              <div className="px-3 hover:text-orange-400 cursor-pointer">
                Home
              </div>
              <div className="h-3 bg-white border" />
              <div className="px-3">Home</div>
              <div className="h-3 bg-white border" />
              <div className="px-3">Home</div>
            </div>
          </div>
        </div>
      </nav>

      {/* ---------------------------------------------------------------------------------- */}

      <div className="container mx-auto md:px-10 py-16 pb-24 relative">
        <div className="relative">
          <div className="text-left text-3xl md:text-5xl font-bold text-white md:px-32 px-16 pt-10">
            Terms of Use
          </div>
          <br />
          <img
            className
            style={{ marginTop: "-60px" }}
            src="./assets/underline.svg"
            alt="underline"
          />
          <div className=" text-light text-white px-10 md:px-32 relative">
            <br />
            <br />
            This document is an electronic record in terms of Information
            Technology Act, 2000 and rules there under as applicable and the
            amended provisions pertaining to electronic records in various
            statutes as amended by the Information Technology Act, 2000. This
            electronic record is generated by a computer system and does not
            require any physical or digital signatures.
            <br />
            <br />
            This document is published in accordance with the provisions of Rule
            3 of the Information Technology (Intermediary Guidelines and Digital
            Media Ethics Code) Rules, 2021 that require publishing the rules and
            regulations, privacy policy and Terms of Use for access or usage of
            domain name “https://slashn.flipkart.net/”, including the related
            mobile site (hereinafter referred to as “Platform”)
            <br />
            <br />
            The Platform is owned by Flipkart Internet Private Limited a company
            incorporated under the Companies Act, 1956 with its registered
            office at Buildings Alyssa, Begonia & Clover, Embassy Tech Village,
            Outer Ring Road, Devarabeesanahalli Village, Bengaluru – 560103,
            Karnataka, India (hereinafter referred to as "Flipkart").
            <br />
            <br />
            Your use of the Platform and services and tools are governed by the
            following terms and conditions ("Terms of Use") as applicable to the
            Platform including the applicable policies which are incorporated
            herein by way of reference. If You transact on the Platform, You
            shall be subject to the policies that are applicable to the Platform
            for such transaction. By mere use of the Platform, You shall be
            contracting with Flipkart Internet Private Limited and these terms
            and conditions including the policies constitute Your binding
            obligations, with Flipkart.
            <br />
            <br />
            For the purpose of these Terms of Use, wherever the context so
            requires "You" or "User" shall mean any natural or legal person who
            has agreed to interact on the Platform by providing Registration
            Data while registering on the Platform as Registered User using the
            computer systems. The term "We", "Us", "Our" shall mean Flipkart
            Internet Private Limited.
            <br />
            <br />
            When You use any of the services provided by Us through the
            Platform, You will be subject to the rules, guidelines, policies,
            terms, and conditions applicable to such service, and they shall be
            deemed to be incorporated into this Terms of Use and shall be
            considered as part and parcel of this Terms of Use. We reserve the
            right, at Our sole discretion, to change, modify, add or remove
            portions of these Terms of Use, at any time without any prior
            written notice to You. It is Your responsibility to review these
            Terms of Use periodically for updates / changes. Your continued use
            of the Platform following the posting of changes will mean that You
            accept and agree to the revisions. As long as You comply with these
            Terms of Use, We grant You a personal, non-exclusive,
            non-transferable, limited privilege to enter and use the Platform.
            <br />
            <br />
            ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR
            AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE,
            SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING. By
            impliedly or expressly accepting these Terms of Use, You also accept
            and agree to be bound by Flipkart Policies ((including but not
            limited to Privacy Policy available at Privacy) as amended from time
            to time.
            <br />
            <br />
            <strong>Eligibility </strong>
            <br />
            <br />
            Accessing and using services on the Platform is available only to
            persons who can form legally binding contracts under Indian Contract
            Act, 1872. Persons who are "incompetent to contract" within the
            meaning of the Indian Contract Act, 1872 including un-discharged
            insolvents etc. are not eligible to use the Platform. If you are a
            minor i.e. under the age of 18 years, you may use the Platform or
            access content on the Platform only under the supervision and prior
            consent/ permission of a parent or legal guardian. Flipkart reserves
            the right to terminate your membership and / or refuse to provide
            you with access to the Platform if it is brought to Flipkart's
            notice or if it is discovered that You are under the age of 18 years
            and transacting on the Platform.
            <br />
            <br />
            <strong>Your Account and Registration Obligations</strong>
            <br />
            <br />
            If You use the Platform, You shall be responsible for maintaining
            the confidentiality of your Display Name and Password and You shall
            be responsible for all activities that occur under your Display Name
            and Password. You agree that if You provide any information that is
            untrue, inaccurate, not current or incomplete or We have reasonable
            grounds to suspect that such information is untrue, inaccurate, not
            current or incomplete, or not in accordance with the this Terms of
            Use, We shall have the right to indefinitely suspend or terminate or
            block access of your membership on the Platform and refuse to
            provide You with access to the Platform.
            <br />
            <br />
            Your mobile phone number and/or e-mail address is treated as Your
            primary identifier on the Platform. It is your responsibility to
            ensure that Your mobile phone number and your email address is up to
            date on the Platform at all times. You agree to notify Us promptly
            if your mobile phone number or e-mail address changes by updating
            the same on the Platform.
            <br />
            <br />
            You agree that Flipkart shall not be liable or responsible for the
            activities or consequences of use or misuse of any information that
            occurs under your Account in cases, including, where You have failed
            to update Your revised mobile phone number and/or e-mail address on
            the Website Platform. If You share or allow others to have access to
            Your account on the Platform (“Account”), by creating separate
            profiles under Your Account, or otherwise, they will be able to view
            and access Your Account information. You shall be solely liable and
            responsible for all the activities undertaken under Your Account,
            and any consequences therefrom.
            <br />
            <br />
            <strong>Communications </strong>
            <br />
            <br />
            When You use the Platform or send emails or other data, information
            or communication to us, You agree and understand that You are
            communicating with Us through electronic records and You consent to
            receive communications via electronic records from Us periodically
            and as and when required. We may communicate with you by email or by
            such other mode of communication, electronic or otherwise.
            <br />
            <br />
            <strong>Platform </strong>
            <br />
            <br />
            The Platform is a platform that Users utilize for entertainment or
            informational purposes. Flipkart is not and cannot be a party to or
            control in any manner any transaction between the Platform's Users.
            Henceforward:
            <ol>
              <li>
                Flipkart does not make any representation or warranty as to
                specifics of the products or services proposed to be offered on
                the Platform. Flipkart accepts no liability for any errors or
                omissions, whether on behalf of itself or third parties.
              </li>
              <li>
                Flipkart does not make any representation or warranty as to the
                item-specifics (such as legal title, identity, etc) of any of
                its Users. You are advised to independently verify the bona
                fides of any particular User that You choose to deal with on the
                Platform and use Your best judgment in that behalf.
              </li>
              <li>
                You shall be solely liable for all compliances required under
                applicable laws.{" "}
              </li>
              <li>
                {" "}
                You release and indemnify Flipkart and/or any of its officers
                and representatives from any cost, damage, liability or other
                consequence of any of the actions of the Users of the Platform
                and specifically waive any claims that you may have in this
                behalf under any applicable law. Notwithstanding its reasonable
                efforts in that behalf, Flipkart cannot take responsibility or
                control the information provided by other Users which is made
                available on the Platform. You may find other User's information
                to be offensive, harmful, inconsistent, inaccurate, or
                deceptive. Please use caution and practice safe trading when
                using the Platform.{" "}
              </li>
              <li>
                Please note that there could be risks in dealing with underage
                persons or people acting under false pretence.{" "}
              </li>
              <li>
                {" "}
                Membership on the Platform is free. Flipkart does not charge any
                fee for browsing and accessing services on the Platform.{" "}
              </li>
              <li>
                Flipkart may at its sole discretion introduce new services and
                modify some or all of the existing services offered on the
                Platform.{" "}
              </li>
            </ol>
            <br />
            <br />
            <strong>Use of the Platform </strong>
            <br />
            <br />
            You agree, undertake and confirm that Your use of Platform shall be
            strictly governed by the following binding principles:
            <ul>
              <li>
                You shall not host, display, upload, modify, publish, transmit,
                update or share any information which:
                <br />
                (a) belongs to another person and to which You does not have any
                right to;
                <br />
                (b) is grossly harmful, harassing, blasphemous, defamatory,
                obscene, pornographic, paedophilic, libellous, invasive of
                another's privacy, hateful, or racially, ethnically
                objectionable, disparaging, relating or encouraging money
                laundering or gambling, or otherwise unlawful in any manner
                whatever; or unlawfully threatening or unlawfully harassing
                including but not limited to "indecent representation of women"
                within the meaning of the Indecent Representation of Women
                (Prohibition) Act, 1986;
                <br />
                (c) is misleading in any way;
                <br />
                (d) is patently offensive to the online community, such as
                sexually explicit content, or content that promotes obscenity,
                paedophilia, racism, bigotry, hatred or physical harm of any
                kind against any group or individual;
                <br />
                (e) harasses or advocates harassment of another person;
                <br />
                (f) involves the transmission of "junk mail", "chain letters",
                or unsolicited mass mailing or "spamming"; (g) promotes illegal
                activities or conduct that is abusive, threatening, obscene,
                defamatory or libellous; (h) infringes upon or violates any
                third party's rights including, but not limited to, intellectual
                property rights, rights of privacy (including without limitation
                unauthorized disclosure of a person's name, email address,
                physical address or phone number) or rights of publicity;
                <br />
                (i) promotes an illegal or unauthorized copy of another person's
                copyrighted work, such as providing pirated computer programs or
                links to them, providing information to circumvent
                manufacture-installed copy-protect devices, or providing pirated
                music or links to pirated music files;
                <br />
                (j) contains restricted or password-only access pages, or hidden
                pages or images (those not linked to or from another accessible
                page);
                <br />
                (k) provides material that exploits people in a sexual, violent
                or otherwise inappropriate manner or solicits personal
                information from anyone;
                <br />
                (l) provides instructional information about illegal activities
                such as making or buying illegal weapons, violating someone's
                privacy, or providing or creating computer viruses;
                <br />
                (m) contains video, photographs, or images of another person
                (with a minor or an adult). (n) tries to gain unauthorized
                access or exceeds the scope of authorized access to the Platform
                or to profiles, blogs, communities, account information,
                bulletins, friend request, or other areas of the Platform or
                solicits passwords or personal identifying information for
                commercial or unlawful purposes from other users;
                <br />
                (o) engages in commercial activities without Our prior written
                consent such as contests, sweepstakes, barter, advertising and
                pyramid schemes, or the buying or selling of "virtual" products
                related to the Platform. Throughout this Terms of Use,
                Flipkart's prior written consent means a communication coming
                from Flipkart's Legal Department, specifically in response to
                Your request, and specifically addressing the activity or
                conduct for which You seek authorization;
                <br />
                (p) solicits gambling or engages in any gambling activity which
                We, in Our sole discretion, believes is or could be construed as
                being illegal;
                <br />
                (q) interferes with another USER's use and enjoyment of the
                Platform or any other individual's User and enjoyment of similar
                services;
                <br />
                (r) refers to any Platform or URL that, in Our sole discretion,
                contains material that is inappropriate for the Platform or any
                other Platform, contains content that would be prohibited or
                violates the letter or spirit of these Terms of Use.
                <br />
                (s) harm minors in any way;
                <br />
                (t) infringes any patent, trademark, copyright or other
                proprietary rights or third party's trade secrets or rights of
                publicity or privacy or shall not be fraudulent or involve the
                sale of counterfeit or stolen products;
                <br />
                (u) violates any law for the time being in force;
                <br />
                (v) deceives or misleads the addressee/ users about the origin
                of such messages or communicates any information which is
                grossly offensive or menacing in nature;
                <br />
                (w) impersonate another person;
                <br />
                (x) contains software viruses or any other computer code, files
                or programs designed to interrupt, destroy or limit the
                functionality of any computer resource; or contains any trojan
                horses, worms, time bombs, cancelbots, easter eggs or other
                computer programming routines that may damage, detrimentally
                interfere with, diminish value of, surreptitiously intercept or
                expropriate any system, data or personal information;
                <br />
                (y) threatens the unity, integrity, defence, security or
                sovereignty of India, friendly relations with foreign states, or
                public order or causes incitement to the commission of any
                cognizable offence or prevents investigation of any offence or
                is insulting any other nation.
                <br />
                (z) shall not be false, inaccurate or misleading;
                <br />
                (aa) shall not, directly or indirectly, offer, attempt to offer,
                trade or attempt to trade in any item, the dealing of which is
                prohibited or restricted in any manner under the provisions of
                any applicable law, rule, regulation or guideline for the time
                being in force.
                <br />
                (ab) shall not create liability for Us or cause Us to lose (in
                whole or in part) the services of Our internet service provider
                ("ISPs") or other suppliers;
              </li>
              <li>
                You shall not use any "deep-link", "page-scrape", "robot",
                "spider" or other automatic device, program, algorithm or
                methodology, or any similar or equivalent manual process, to
                access, acquire, copy or monitor any portion of the Platform or
                any Content, or in any way reproduce or circumvent the
                navigational structure or presentation of the Platform or any
                Content, to obtain or attempt to obtain any materials, documents
                or information through any means not purposely made available
                through the Platform. We reserve Our right to bar any such
                activity.
              </li>
              <li>
                You shall not attempt to gain unauthorized access to any portion
                or feature of the Platform, or any other systems or networks
                connected to the Platform or to any server, computer, network,
                or to any of the services offered on or through the Platform, by
                hacking, password "mining" or any other illegitimate means.
              </li>
              <li>
                You shall not probe, scan or test the vulnerability of the
                Platform or any network connected to the Platform nor breach the
                security or authentication measures on the Platform or any
                network connected to the Platform. You may not reverse look-up,
                trace or seek to trace any information on any other User of or
                visitor to Platform, including any account on the Platform not
                owned by You, to its source, or exploit the Platform or any
                service or information made available or offered by or through
                the Platform, in any way where the purpose is to reveal any
                information, including but not limited to personal
                identification or information, other than Your own information,
                as provided for by the Platform.
              </li>
              <li>
                You shall not make any negative, denigrating or defamatory
                statement(s) or comment(s) about Us or the brand name or domain
                name used by Us including the terms Flipkart, Flyte, Digiflip,
                Flipcart, flipkart.com, or otherwise engage in any conduct or
                action that might tarnish the image or reputation, of Flipkart
                or sellers on platform or otherwise tarnish or dilute any
                Flipkart's trade or service marks, trade name and/or goodwill
                associated with such trade or service marks, trade name as may
                be owned or used by us. You agree that You will not take any
                action that imposes an unreasonable or disproportionately large
                load on the infrastructure of the Platform or Flipkart's systems
                or networks, or any systems or networks connected to Flipkart.
              </li>
              <li>
                You agree not to use any device, software or routine to
                interfere or attempt to interfere with the proper working of the
                Platform or any services being offered on the Platform, or with
                any other person's use of the Platform.
              </li>
              <li>
                You may not forge headers or otherwise manipulate identifiers in
                order to disguise the origin of any message or transmittal You
                send to Us on or through the Platform or any service offered on
                or through the Platform. You may not pretend that You are, or
                that You represent, someone else, or impersonate any other
                individual or entity.
              </li>
              <li>
                You may not use the Platform or any content for any purpose that
                is unlawful or prohibited by these Terms of Use, or to solicit
                the performance of any illegal activity or other activity which
                infringes the rights of Flipkart and / or others.
              </li>
              <li>
                You shall at all times ensure full compliance with the
                applicable provisions of the Information Technology Act, 2000
                and rules thereunder as applicable and as amended from time to
                time and also all applicable Domestic laws, rules and
                regulations (including the provisions of any applicable Exchange
                Control Laws or Regulations in Force) and International Laws,
                Foreign Exchange Laws, Statutes, Ordinances and Regulations
                (including, but not limited to Sales Tax/VAT, Income Tax,
                Octroi, Service Tax, Central Excise, Custom Duty, Local Levies)
                regarding Your use of Our service.{" "}
              </li>
              <li>
                Solely to enable Us to use the information You supply Us with,
                so that we are not violating any rights You might have in Your
                Information, You agree to grant Us a non-exclusive, worldwide,
                perpetual, irrevocable, royalty-free, sub-licensable (through
                multiple tiers) right to exercise the copyright, publicity,
                database rights or any other rights You have in Your
                Information, in any media now known or not currently known, with
                respect to Your Information. We will only use Your information
                in accordance with the Terms of Use and Privacy Policy
                applicable to use of the Platform.{" "}
              </li>
              <li>
                You may not transmit any chain letters or unsolicited commercial
                or junk email to other Users via the Platform. It shall be a
                violation of these Terms of Use to use any information obtained
                from the Platform in order to harass, abuse, or harm another
                person, or in order to contact, advertise to, solicit, or sell
                to another person other than Us without Our prior explicit
                consent. In order to protect Our Users from such advertising or
                solicitation, We reserve the right to restrict the number of
                messages or emails which a user may send to other Users in any
                24-hour period which We deems appropriate in its sole
                discretion. You understand that We have the right at all times
                to disclose any information (including the identity of the
                persons providing information or materials on the Platform) as
                necessary to satisfy any law, regulation or valid governmental
                request. This may include, without limitation, disclosure of the
                information in connection with investigation of alleged illegal
                activity or solicitation of illegal activity or in response to a
                lawful court order or subpoena. In addition, We can (and You
                hereby expressly authorize Us to) disclose any information about
                You to law enforcement or other government officials, as we, in
                Our sole discretion, believe necessary or appropriate in
                connection with the investigation and/or resolution of possible
                crimes, especially those that may involve personal injury.
              </li>
              <li>
                {" "}
                We reserve the right, but have no obligation, to monitor the
                materials posted on the Platform. Flipkart shall have the right
                to remove or edit any content that in its sole discretion
                violates, or is alleged to violate, any applicable law or either
                the spirit or letter of these Terms of Use. Notwithstanding this
                right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE
                MATERIALS YOU POST ON THE PLATFORM. Please be advised that such
                Content posted does not necessarily reflect Flipkart views. In
                no event shall Flipkart assume or have any responsibility or
                liability for any Content posted or for any claims, damages or
                losses resulting from use of Content and/or appearance of
                Content on the Platform. You hereby represent and warrant that
                You have all necessary rights in and to all Content which You
                provide and all information it contains and that such Content
                shall not infringe any proprietary or other rights of third
                parties or contain any libellous, tortious, or otherwise
                unlawful information.{" "}
              </li>
              <li>
                {" "}
                It is possible that other users (including unauthorized users or
                "hackers") may post or transmit offensive or obscene materials
                on the Platform and that You may be involuntarily exposed to
                such offensive and obscene materials. It also is possible for
                others to obtain personal information about You due to your use
                of the Platform, and that the recipient may use such information
                to harass or injure You. We does not approve of such
                unauthorized uses, but by using the Platform You acknowledge and
                agree that We are not responsible for the use of any personal
                information that You publicly disclose or share with others on
                the Platform. Please carefully select the type of information
                that You publicly disclose or share with others on the Platform.{" "}
              </li>
              <li>
                {" "}
                Flipkart shall have all the rights to take necessary action and
                claim damages that may occur due to your
                involvement/participation in any way on your own or through
                group/s of people, intentionally or unintentionally in DoS/DDoS
                (Distributed Denial of Services).{" "}
              </li>
              <li>
                {" "}
                You acknowledge and agree that the Hindi, Telugu, Tamil, Kannada
                and Marathi, Bengali, Gujarati , Odia, Malayalam, Punjabi &
                Assamese translation features are made available on the Platform
                on a reasonable effort basis for convenience only, without any
                representation or warranties by Flipkart, express or implied,
                including the translations being error free, their accuracy,
                completeness or reliability. Some features and sections on the
                Platform may not be translated in the language selected by You
                (Hindi, Telugu, Tamil, Kannada, Marathi, Bengali, Gujarati,
                Odia, Malayalam, Punjabi & Assamese language, as applicable),
                and the English version of the same will be the definitive
                version. In the event of any conflict or inconsistency between
                the translated terms and the English version available on the
                Platform, the English version on the Platform shall prevail.
                This feature may not be accessible on certain devices. Flipkart
                reserves the right to change, enhance, suspend, or discontinue
                this feature, or any part of it, at any time without notice to
                You.{" "}
              </li>
            </ul>
            <br />
            <br />
            <strong>Contents Posted on Platform </strong>
            <br />
            <br />
            All text, graphics, user interfaces, visual interfaces, photographs,
            trademarks, logos, sounds, music, videos and artwork (collectively,
            "Content"), is a third party user generated content and Flipkart has
            no control over such third party user generated content as Flipkart
            is merely an intermediary for the purposes of this Terms of Use.
            <br />
            <br />
            Except as expressly provided in these Terms of Use, no part of the
            Platform and no Content may be copied, reproduced, republished,
            uploaded, posted, publicly displayed, encoded, translated,
            transmitted or distributed in any way (including "mirroring") to any
            other computer, server, Platform or other medium for publication or
            distribution or for any commercial enterprise, without Flipkart's
            express prior written consent.
            <br />
            <br />
            You may use information on the services purposely made available on
            the Platform for downloading, provided that You (1) do not remove
            any proprietary notice language in all copies of such documents, (2)
            use such information only for your personal, non-commercial
            informational purpose and do not copy or post such information on
            any networked computer or broadcast it in any media, (3) make no
            modifications to any such information, and (4) do not make any
            additional representations or warranties relating to such documents.
            <br />
            <br />
            You shall be responsible for any notes, messages, emails, photos,
            drawings, profiles, opinions, ideas, images, videos, audio files or
            other materials or information posted or transmitted by You to the
            Platform (collectively, "Content"). Such Content will become Our
            property and You grant Us the worldwide, perpetual and transferable
            rights in such Content. We shall be entitled to, consistent with Our
            Privacy Policy as adopted in accordance with applicable law, use the
            Content or any of its elements for any type of use forever,
            including but not limited to promotional and advertising purposes
            and in any media whether now known or hereafter devised, including
            the creation of derivative works that may include the Content You
            provide. You agree that any Content You post may be used by us,
            consistent with Our Privacy Policy and Rules of Conduct on Site as
            mentioned herein, and You are not entitled to any payment or other
            compensation for such use.
            <br />
            <br />
            The Content provided on the Platform is for entertainment purposes
            only and Flipkart is hosting the Content generated by third party
            users (“Content Owner”), who own all the rights including the
            copyrights in the contents, within the Platform for convenience of
            Users, and as such, Flipkart has no ownership or control whatsoever
            in the Contents displayed. The Contents posted by the Content Owners
            are of personal views wherein Flipkart plays no role in
            creating/developing the Content. You are responsible for your use of
            the Platform for viewing the Content. You agree that we shall have
            no liability to You for the Content and services on the Platform if
            You find the same to be offensive, indecent or objectionable and
            expressions through the Content and the services on the Platform are
            not subscribed by us.
            <br />
            <br />
            By hosting the Content on the Platform, Flipkart is neither
            guaranteeing nor giving any warranty nor making any representation
            with respect to the Contents. Flipkart is neither endorsing the
            views, or the services, nor is it responsible for quality or
            features of the Content displayed on the Platform. Any information
            on the Platform, whether the Content or any other information, have
            not been verified by Flipkart, therefore, Flipkart shall not be
            responsible for any such Content or information. Flipkart will not
            bear any obligation or liability if You rely on the Contents. Any
            use or reliance on any Content or materials obtained by you through
            the Content is at your own risk. If You choose to view or rely,
            Flipkart will neither be a party to nor in any manner concerned with
            the same nor be liable or responsible for any act or omission of the
            Content Owner.
            <br />
            <br />
            We do not endorse, support, represent or guarantee the completeness,
            truthfulness, accuracy, or reliability of any Content or
            communications posted on the Platform or endorse any opinions
            expressed via the Contents. You understand that by using the
            Platform, you may be exposed to Content that might be offensive,
            harmful, inaccurate or otherwise inappropriate, or in some cases,
            postings that have been mislabeled or are otherwise deceptive. All
            Content is the sole responsibility of the Content Owner. Flipkart
            may not monitor or control the Content posted on the Platform and,
            we do not take responsibility for such Content.
            <br />
            <br />
            We reserve the right to remove Content that violates the Terms of
            Use, including for example, copyright or trademark violations,
            impersonation, unlawful conduct, or harassment. If you see an
            offensive, abusive, or any unlawful content on the Platform, you can
            report it to [.]@flipkart.com You understand and agree that the
            services are provided to you on an “AS IS” and “AS AVAILABLE” basis.
            Flipkart makes no warranty or representation and disclaim all
            responsibility and liability for: (i) the completeness, accuracy,
            availability, timeliness, security or reliability of any Content;
            (ii) any harm to your computer system, loss of data, or other harm
            that results from your access to or use of any Content;
            <br />
            <br />
            (iii) the deletion of, or the failure to store or to transmit, any
            Content and other communications maintained by the Services; and
            (iv) whether the services will meet your requirements or be
            available on an uninterrupted, secure, or error-free basis. No
            advice or information, whether oral or written, obtained through the
            Content, will create any warranty or representation not expressly
            made herein.
            <br />
            <br />
            During the term of [.] we grant you a limited, non-exclusive,
            non-transferable, revocable right to access the Platform and view
            the Content. Except for the foregoing, no right, title or interest
            shall be transferred to you in any Content or the Platform.
            <br />
            <br />
            Flipkart's role is strictly limited to providing a uniform resource
            locator (URL) or a web-address to access such Content. For avoidance
            of doubt, it is clarified that Flipkart will not be hosting,
            uploading or streaming any content on its servers or IT systems.
            Flipkart will not be exercising any control – physical, editorial or
            digital - over the Content being viewed by or provided to the User
            on the Platform. Flipkart will only be providing the user with an
            option and/or a medium to access Content through the Platform.
            Flipkart's role is that of an 'intermediary' as defined under the
            Information Technology Act, 2000 and the rules thereunder, with
            regard to the Content. Being an intermediary, Flipkart has no
            responsibility and/or liability in respect of any news Content on
            the Platform, including for intellectual property rights
            infringement, defamation, obscenity or any other violation under
            applicable law.
            <br />
            <br />
            Unless expressly stated otherwise, all characters, themes and events
            depicted in Content are entirely fictitious and any similarity to
            actual events or persons, living or dead, is purely coincidental,
            unintentional or accidental.
            <br />
            <br />
            Please note that the availability of, and your ability to access,
            the Content or some part of the services, (a) is subject to
            applicable law; and (b) may be dependent upon your geographical
            location, your Internet/data connectivity or your system
            capabilities or compatibility with the Platform. Not all Content or
            services will be available to all users. On account of the nature of
            the Internet/data connectivity and system compatibility, the
            Platform and the services may also be accessed in various
            geographical locations. You, therefore, hereby agree and acknowledge
            that you are accessing the Platform and availing of the Services, at
            your own risk, choice and initiative, and you agree and undertake to
            ensure that your use of the Platform and the services complies with
            all applicable laws including the local laws in your jurisdiction.
            <br />
            <br />
            YOU MAY FIND CONTENT THAT OFFENDS YOU OR THAT YOU SIMPLY DON’T LIKE,
            BUT THAT DOESN’T VIOLATE THE CONTENT GUIDELINES. YOUR ACCESS TO AND
            USE OF THE SERVICES OR ANY CONTENT ARE AT YOUR OWN RISK. SOME
            CONTENT OFFERED ON THE PLATFORM MAY NOT BE SUITABLE FOR SOME VIEWERS
            AND THEREFORE VIEWER DISCRETION IS ADVISED. ALSO, SOME CONTENT
            OFFERED ON THE PLATFORM MAY NOT BE APPROPRIATE FOR VIEWERSHIP BY
            CHILDREN. PARENTS AND/OR LEGAL GUARDIANS ARE ADVISED TO EXERCISE
            DISCRETION BEFORE ALLOWING THEIR CHILDREN AND/OR WARDS TO ACCESS
            CONTENT ON THE PLATFORM.
            <br />
            <br />
            <strong>Privacy </strong>
            <br />
            <br />
            <strong>Disclaimer of Warranties and Liability </strong>
            <br />
            <br />
            This Platform, all the products (including but not limited to
            software) and services, included on or otherwise made available to
            You through this site are provided on "as is" and "as available"
            basis without any representation or warranties, express or implied
            except otherwise specified in writing. Without prejudice to the
            forgoing paragraph, Flipkart does not warrant that:
            <ul>
              <li>
                {" "}
                This Platform will be constantly available, or available at all;
                or{" "}
              </li>
              <li>
                The information on this Platform is complete, true, accurate or
                non-misleading.{" "}
              </li>
              <li>
                Flipkart will not be liable to You in any way or in relation to
                the Contents of, or use of, or otherwise in connection with, the
                Platform. Flipkart does not warrant that this site; information,
                Content, materials, product (including software) or services
                included on or otherwise made available to You through the
                Platform; their servers; or electronic communication sent from
                Us are free of viruses or other harmful components.{" "}
              </li>
              <li>
                Nothing on Platform constitutes, or is meant to constitute,
                advice of any kind.
              </li>
              <li>
                You will be required to enter a valid phone number/email id
                while accessing the Platform. By registering Your phone
                number/email id with us, You consent to be contacted by Us via
                phone calls, SMS notifications, mobile applications, email
                and/or any other electronic mode of communication in case of any
                updates.{" "}
              </li>
            </ul>
            <br />
            <br />
            <strong>Compliance with Laws </strong>
            <br />
            <br />
            You shall comply with all the applicable laws (including without
            limitation Foreign Exchange Management Act, 1999 and the rules made
            and notifications issued there under and the Exchange Control Manual
            as may be issued by Reserve Bank of India from time to time, Customs
            Act, Information and Technology Act, 2000 as amended by the
            Information Technology (Amendment) Act 2008, Prevention of Money
            Laundering Act, 2002 and the rules made there under, Foreign
            Contribution Regulation Act, 1976 and the rules made there under,
            Income Tax Act, 1961 and the rules made there under, Export Import
            Policy of government of India) applicable to them respectively for
            using the Platform.
            <br />
            <br />
            <strong>Intellectual Property Rights </strong>
            <br />
            <br />
            It is hereby specifically recorded that the copyright and other
            Intellectual Property in the music available on the Platform is the
            sole and exclusive property of third parties. Seller represents and
            warrants that Sellers are authorized by such third parties to upload
            the music on the Platform for license of use to the end customers.
            Intellectual Property Rights for the purpose of this Terms of Use
            shall always mean and include copyrights whether registered or not,
            patents including rights of filing patents, trademarks, trade names,
            trade dresses, house marks, collective marks, associate marks and
            the right to register them, designs both industrial and layout,
            geographical indicators, moral rights, broadcasting rights,
            displaying rights, distribution rights, selling rights, abridged
            rights, translating rights, reproducing rights, performing rights,
            communicating rights, adapting rights, circulating rights, protected
            rights, joint rights, reciprocating rights, infringement rights. All
            those Intellectual Property rights arising as a result of domain
            names, internet or any other right available under applicable law
            shall vest in the domain of Flipkart as the owner of such domain
            name. The Parties hereto agree and confirm that no part of any
            Intellectual Property rights mentioned hereinabove is transferred in
            the name of User and any intellectual property rights arising as a
            result of these presents shall also be in the absolute ownership,
            possession and Our control or control of its licensors, as the case
            may be.
            <br />
            <br />
            <strong>Force Majeure </strong>
            <br />
            <br />
            You agree that Flipkart shall be under no liability whatsoever to
            you in the event of non-availability of the Platform and/or any of
            the Services or any portion thereof, occasioned by an Act of God,
            war, disease, pandemic, lockdown, revolution, riot, civil commotion,
            strike, lockout, flood, fire, satellite failure, failure of any
            public utility, man-made disaster, satellite failure or any other
            cause whatsoever beyond the control of Flipkart (including any event
            which is caused by the failure or non-performance on the part of the
            Content Owners).
            <br />
            <br />
            <strong>Governing laws </strong>
            <br />
            <br />
            These Terms shall be governed, interpreted, and construed in
            accordance with the laws of India, without regard to the conflict of
            law provisions and for resolution of any dispute arising out of your
            use of the services or in relation to these Terms. Notwithstanding
            the foregoing, you agree that (i) Flipkart has the right to bring
            any proceedings before any court/forum of competent jurisdiction and
            you irrevocably submit to the jurisdiction of such courts or forum;
            and (ii) any proceeding brought by you shall be exclusively before
            the courts in Bengaluru, India.
            <br />
            <br />
            <strong>Severability </strong>
            <br />
            <br />
            If any provision of these Terms is held invalid, void, or
            unenforceable, then that provision shall be considered severable
            from the remaining provisions, and the remaining provisions shall be
            given full force and effect.
            <br />
            <br />
            <strong>Amendments </strong>
            <br />
            <br />
            We may revise these Terms from time to time, without prior notice to
            you, to update, revise, supplement, and otherwise modify these Terms
            and to impose new or additional rules, policies, terms, or
            conditions on your use of the Service. Any updates, revisions,
            supplements, modifications, and additional rules, policies, terms,
            and conditions will be posted on the Platform and will be effective
            immediately after such posting and we recommend that you
            periodically check these Terms on the Platform for such revised
            terms. Your continued use of the services, Content and/or the
            Platform will be deemed to constitute your acceptance of any and all
            such revised terms.
            <br />
            <br />
            <strong>Entire agreement </strong>
            <br />
            <br />
            These Terms, the Privacy Policy and any other terms or policies as
            may be prescribed by Flipkart from time to time, constitute the
            entire agreement between you and Flipkart, which will govern your
            use of or access to the services, Content and/or the Platform,
            superseding any prior agreements between you and Flipkart regarding
            such use or access.
            <br />
            <br />
            <strong>Survival </strong>
            <br />
            <br />
            You acknowledge that your representations, undertakings, and
            warranties and the clauses relating to indemnities, limitation of
            liability, grant of license, governing law shall survive the efflux
            of time and the termination of these Terms.
            <br />
            <br />
            <strong>Indemnity </strong>
            <br />
            <br />
            You shall indemnify and hold harmless Flipkart, its owner, licensee,
            affiliates, subsidiaries, group companies (as applicable) and their
            respective officers, directors, agents, and employees, from any
            claim or demand, or actions including reasonable attorneys' fees,
            made by any third party or penalty imposed due to or arising out of
            Your breach of this Terms of Use, Privacy Policy and other Policies,
            or Your violation of any law, rules or regulations or the rights
            (including infringement of intellectual property rights) of a third
            party.
            <br />
            <br />
            <stron>Jurisdictional Issues </stron>
            <br />
            <br />
            Unless otherwise specified, the services and Content on the Platform
            is presented solely for the purpose of being used in India. Flipkart
            make no representation that the services and Content on the Platform
            are appropriate or available for use in other locations/Countries
            other than India. Those who choose to access this site from other
            locations/Countries other than India do so on their own initiative
            and Flipkart is not responsible for such other locations/Countries
            other than India, compliance with local laws, if and to the extent
            local laws are applicable.
            <br />
            <br />
            <strong>Trademark, Copyright and Restriction </strong>
            <br />
            <br />
            All material on this site, including images, illustrations, audio
            clips, and video clips, are protected by copyrights, trademarks, and
            other intellectual property rights. Material on Platform is solely
            for Your personal, non-commercial use. You must not copy, reproduce,
            republish, upload, post, transmit or distribute such material in any
            way, including by email or other electronic means and whether
            directly or indirectly and You must not assist any other person to
            do so. Without the prior written consent of the owner, modification
            of the materials, use of the materials on any other Platform or
            networked computer environment or use of the materials for any
            purpose other than personal, non-commercial use is a violation of
            the copyrights, trademarks and other proprietary rights, and is
            prohibited. Any use for which You receive any remuneration, whether
            in money or otherwise, is a commercial use for the purposes of this
            clause.
            <br />
            <br />
            <strong>Trademark complaint </strong>
            <br />
            <br />
            Flipkart respects the intellectual property of others. In case You
            feel that Your Trademark has been infringed, You can write to
            Flipkart at infringement@flipkart.com or ss@flipkart.com.
            <br />
            <br />
            <strong>Limitation of Liability </strong>
            <br />
            <br />
            By accessing the Platform, you agree that the liability of Flipkart,
            its parents, affiliates, related companies, officers, directors,
            employees, agents, representatives, partners and licensors, shall be
            limited to the maximum extent permissible by applicable law. In no
            case shall Flipkart, its directors, officers, employees, affiliates,
            agents, contractors, principals, or licensors be liable for any
            direct, indirect, incidental, punitive, special, or consequential
            damages arising from your use of the services or any Content or for
            any other claim related in any way to your access to the Platform
            and/or use of the services, including, but not limited to, any
            errors or omissions in any Content or information on the Platform,
            or any loss or damage of any kind incurred as a result of the use of
            the services and/or reliance on any Content or information on the
            Platform.
            <br />
            <br />
            <strong>Contact Us </strong>
            <br />
            <br />
            Please contact us for any questions or comments (including all
            inquiries unrelated to copyright infringement) regarding this
            platform through the link: https://slashn.flipkart.net/
            <br />
            <br />
            In accordance with Information Technology Act 2000 and rules made
            there under the name and contact details of the Grievance Officer
            are provided below: <br />
            Mr. Shremanth M<br />
            Designation: Senior Manager
            <br />
            Address: Flipkart Internet Pvt Ltd.
            <br />
            Embassy tech village
            <br />
            8th floor Block 'B' Devarabeesanahalli Village,
            <br />
            Varthur Hobli, Bengaluru East Taluk,
            <br />
            Bengaluru District,
            <br />
            Karnataka, India, 560103.
            <br />
            Phone: 1800 202 9898
            <br />
            Email: grievance.officer@flipkart.com
            <br />
            Time: Mon - Sat (9:00 - 18:00)
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
