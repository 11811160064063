import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

function VideoSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <img src="/assets/arrow_right.png" alt="arrow" />,
    prevArrow: <img src="/assets/arrow_left.png" alt="arrow" />,
    responsive: [
      // {
      //   breakpoint: 1024,
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 3,
      //     infinite: true,
      //     dots: true
      //   }
      // },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
        },
      },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1
      //   }
      // }
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <div>
          <div
            className="px-6 sm:px-8 lg:px-14"
            // href="https://www.youtube.com/playlist?list=PLNrODmzoJwwpPQclVvIz8c7bGsMwwA0yG"
          >
            <Link to="/videos/2019">
              <div
                className="w-full h-36 rounded-lg flex items-center justify-center"
                style={{
                  backgroundImage: "url(./assets/2019.jpg)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <img src="./assets/play.png" alt="play" />
              </div>
              <div className="text-center text-white text-lg py-4">2019</div>
            </Link>
          </div>
        </div>
        <div>
          <div
            className="px-6 sm:px-8 lg:px-14"
            // href="https://www.youtube.com/watch?v=oOrPeIpV6wk&list=PLNrODmzoJwwrVrcKsqcENLsV4S7aCp9KJ"
            // target="_blank"
          >
            <Link to="/videos/2018">
              <div
                className="w-full h-36 rounded-lg flex items-center justify-center"
                style={{
                  backgroundImage: "url(./assets/2018.jpg)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <img src="./assets/play.png" alt="play" />
              </div>
              <div className="text-center text-white text-lg py-4">2018</div>
            </Link>
          </div>
        </div>
        <div>
          <div
            className="px-6 sm:px-8 lg:px-14"
            // href="https://www.youtube.com/watch?v=OsfuOUPB1CA&list=PLNrODmzoJwwquif5reHC6VtLbECaIZxWO"
            // target="_blank"
          >
            <Link to="/videos/2016">
              <div
                className="w-full h-36 rounded-lg flex items-center justify-center"
                style={{
                  backgroundImage: "url(./assets/2016.jpg)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <img src="./assets/play.png" alt="play" />
              </div>
              <div className="text-center text-white text-lg py-4">2016</div>
            </Link>
          </div>
        </div>
        <div>
          <div
            className="px-6 sm:px-8 lg:px-14"
            // href="https://www.youtube.com/playlist?list=PLNrODmzoJwwr2wCwxRtpscuoFxAwuGGff"
            // target="_blank"
          >
            <Link to="/videos/2014">
              <div
                className="w-full h-36 rounded-lg flex items-center justify-center"
                style={{
                  backgroundImage: "url(./assets/2014.jpg)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <img src="./assets/play.png" alt="play" />
              </div>
              <div className="text-center text-white text-lg py-4">2014</div>
            </Link>
          </div>
        </div>
        <div>
          <div
            className="px-6 sm:px-8 lg:px-14"
            // href="https://www.youtube.com/watch?v=REhRrmjvl9Q&list=PLNrODmzoJwwrgPtbHfeyEYiZqguSTk5ws"
            // target="_blank"
          >
            <Link to="/videos/2013">
              <div
                className="w-full h-36 rounded-lg flex items-center justify-center"
                style={{
                  backgroundImage: "url(./assets/2013.jpg)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <img src="./assets/play.png" alt="play" />
              </div>
              <div className="text-center text-white text-lg py-4">2013</div>
            </Link>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default VideoSlider;
