import React, { useState } from "react";
import ReactPlayer from "react-player";

function Video2013() {
  const [ylink, setYlink] = useState(
    "https://www.youtube.com/watch?v=REhRrmjvl9Q"
  );
  const [yname, setYname] = useState("Slash n - Opening address");
  const [idty, setIdty] = useState("1");

  const playlist = [
    {
      id: "1",
      name: "Slash n - Opening address",
      link: "https://www.youtube.com/watch?v=REhRrmjvl9Q",
    },
    {
      id: "2",
      name: "Data: Stores, Processing and Trends (Panel Discussion)",
      link: "https://www.youtube.com/watch?v=7oDGcpevhCM",
    },
    {
      id: "3",
      name: "Website Architecture: Mistakes and Learnings",
      link: "https://www.youtube.com/watch?v=ic_iQYYCDxY",
    },
    {
      id: "4",
      name: "Distributed Transactions in SOA",
      link: "https://www.youtube.com/watch?v=iVz0oMQ6i64",
    },
    {
      id: "5",
      name: "Session Management: Scheme For Scalable & Safe Guest Checkout",
      link: "https://www.youtube.com/watch?v=AWRkulRUmiY",
    },
    {
      id: "6",
      name: "Experimentation Platform",
      link: "https://www.youtube.com/watch?v=nndmKyUc3sk",
    },
    {
      id: "7",
      name: "Building A Private Cloud",
      link: "https://www.youtube.com/watch?v=9RkGVyGrbxg",
    },
    {
      id: "8",
      name: "Keeping A Commercial Site Secure",
      link: "https://www.youtube.com/watch?v=bJD3H6-FrpY",
    },
    {
      id: "9",
      name: "Fraudsters Are Smart, Frank Is Smarter",
      link: "https://www.youtube.com/watch?v=zb98EWAUBxc",
    },
    {
      id: "10",
      name: "The Art and Science of Cataloguing",
      link: "https://www.youtube.com/watch?v=HprjxOVaQfQ",
    },
    {
      id: "11",
      name: "Driving User Growth Through Online Marketing",
      link: "https://www.youtube.com/watch?v=tXnAI4EfrrU",
    },
    {
      id: "12",
      name: "Driving User Growth Through SEM",
      link: "https://www.youtube.com/watch?v=sEwjK6VewNE",
    },
    {
      id: "13",
      name: "Making Time: Minute By Minute",
      link: "https://www.youtube.com/watch?v=Xf-zM59PfXc",
    },
    {
      id: "14",
      name: "Messaging As A Platform",
      link: "https://www.youtube.com/watch?v=W3-xRvuRBZQ",
    },
    {
      id: "15",
      name: "Storage@Scale: Quest For The Mythical Silver Bullet",
      link: "https://www.youtube.com/watch?v=WYBEsQJqUMU",
    },
  ];

  const playvideo = (link, name, id) => {
    setYlink(link);
    setYname(name);
    setIdty(id);
  };

  return (
    <div
      className="grid col-span-1 md:grid-cols-3 gap-x-10 h-full divide-y md:divide-x md:divide-y-0 divide-gray-800"
      // style={{ border: "1px solid white" }}
    >
      <div
        className="md:col-span-2 text-white h-full"
        // style={{ border: "1px solid red" }}
      >
        <h1 className="text-white pb-2 md:pb-4">2013</h1>
        <div className="h-full pb-12 md:pb-0">
          <ReactPlayer
            url={ylink}
            controls={true}
            playing={true}
            width="100%"
            height="80%"
          />
          <h1 className="pt-3 md:pt-6 text-sm md:text-lg">{yname}</h1>
        </div>
      </div>
      <div
        className="col-span-1 p-2 divide-y divide-gray-800"
        style={{
          // border: "1px solid green",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        {playlist?.map((list) => {
          return (
            <div
              className="text-white grid grid-cols-5 py-2 cursor-pointer rounded-sm"
              style={{
                backgroundColor:
                  idty === list.id ? "rgba(46, 49, 146,0.4)" : null,
              }}
              onClick={() => playvideo(list.link, list.name, list.id)}
            >
              <div className="col-span-2">
                <div
                  className="w-full h-20 md:h-36 rounded-lg flex items-center justify-center"
                  style={{
                    // background:
                    //   "linear-gradient(90deg, #2E3192 -0.01%, #662D91 99.99%)",
                    backgroundImage: `url('/assets/thumbnails/2013/${list.id}.jpg')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <img
                    src="/assets/play.png"
                    alt="play"
                    className="h-14 md:h-auto"
                  />
                </div>
              </div>
              <div className="col-span-3 pl-2 flex items-center text-xs md:text-md">
                {list.name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Video2013;
