import React, { useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import ReactPlayer from "react-player";

function VideoModal({ videolink, started, setStarted, closeVideo }) {
  return (
    <div>
      <div
        className="fixed top-0 left-0 h-full w-full"
        style={{ background: "rgba(0,0,0,0.6)", zIndex: "40" }}
      />
      {/* <Spin
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: "42",
          transform: "translate(-50%, -50%)",
        }}
        spinning={spinning}
        indicator={antIcon}
        size="large"
      /> */}
      <div className="videoContainer">
        <ReactPlayer
          url={videolink}
          playing={true}
          controls={true}
          height="100%"
          width="100%"
          //   onStart={() => setStarted(true)}
          onReady={() => setStarted(true)}
          //   style={{ display: started ? "block" : "none" }}
          //   onBuffer={this.readyfn}
        />

        {started && (
          <AiFillCloseCircle className="closeButton" onClick={closeVideo} />
        )}
      </div>
    </div>
  );
}

export default VideoModal;
