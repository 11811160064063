import React, { useState } from "react";
import ReactPlayer from "react-player";

function Video2014() {
  const [ylink, setYlink] = useState(
    "https://www.youtube.com/watch?v=y4pPLDEdtjI"
  );
  const [yname, setYname] = useState(
    "Introduction to slash n by Amod Malviya (slash n 2014)"
  );
  const [idty, setIdty] = useState("1");

  const playlist = [
    {
      id: "1",
      name: "Introduction to slash n by Amod Malviya (slash n 2014)",
      link: "https://www.youtube.com/watch?v=y4pPLDEdtjI",
    },
    {
      id: "2",
      name: "Keynote by Sachin Bansal (Slash n 2014)",
      link: "https://www.youtube.com/watch?v=3nllCsK7qeA",
    },
    {
      id: "3",
      name: "Machine intelligence meets Molecular Biology to Tackle Genetic Disease by Ramesh Hariharan",
      link: "https://www.youtube.com/watch?v=6uT7g0ccTQY",
    },
    {
      id: "4",
      name: "Mining data : A Mobile Perspective by Rajiv Bhat (slash n 2014)",
      link: "https://www.youtube.com/watch?v=ePKt9lfQkxw",
    },
    {
      id: "5",
      name: "The Fishbowl of Existence by Dinkar Sitaram & Ashok Raman",
      link: "https://www.youtube.com/watch?v=V7zBbPXUJVY",
    },
    {
      id: "6",
      name: "The Art of Promising with Vikas Bansal and Yogi Kulkarni (Slash N 2014)",
      link: "https://www.youtube.com/watch?v=aXu0-vVHNSs",
    },
    {
      id: "7",
      name: "Job Scheduling in Hadoop - an expose by Joydeep Sensarma",
      link: "https://www.youtube.com/watch?v=fH7SYCpF-dQ",
    },
    {
      id: "8",
      name: "Soothsayer @ Flipkart : Internals of forecasting and modelling by Ananda & Mohit",
      link: "https://www.youtube.com/watch?v=VgXm7ep9uFU",
    },
    {
      id: "9",
      name: "Instrumenting any android app to get detailed HTTP stats by Gaurav Lochan (slash n 2014)",
      link: "https://www.youtube.com/watch?v=XI25v5dXAgg",
    },
    {
      id: "10",
      name: "Co-occurrence analysis on Product Clusters in Recommendations by Srikar Appalaraju (slash n 2014)",
      link: "https://www.youtube.com/watch?v=0q33EtC5vC4",
    },
    {
      id: "11",
      name: "Swipe to buy : Mobile retail @ scale by Pratyush Verma & Chetan Kalyan (slash n 2014)",
      link: "https://www.youtube.com/watch?v=VEprpjWeJJo",
    },
    {
      id: "12",
      name: "Know Your Customer : Deriving User Insights by Devashish Shankar, Bhavdeep Sethi & Dyuti Barma",
      link: "https://www.youtube.com/watch?v=sLDXm_53-mM",
    },
    {
      id: "13",
      name: "The Great Convergence by Phaneesh Nagaraja, Amar Nagaram & Kiran Kumar",
      link: "https://www.youtube.com/watch?v=TFDcFl2iLU4",
    },
    {
      id: "14",
      name: "Serving user intent : Facebook style notifications by Dileep Vaka & Rajat Mathur",
      link: "https://www.youtube.com/watch?v=27IrHBXrCBw",
    },
    {
      id: "15",
      name: "Cataloging : Creating a structured, semantically-rich product catalog by Abhishek Sharan & Utkarsh B",
      link: "https://www.youtube.com/watch?v=2dfpUO6KazY",
    },
    {
      id: "16",
      name: "Leveraging Scale to Improve Human Learning by Sridhar Sundaram",
      link: "https://www.youtube.com/watch?v=PmCJPeXe2LY",
    },
    {
      id: "17",
      name: "Visualising Big Data by Anand S",
      link: "https://www.youtube.com/watch?v=ZisvLtTO9fA",
    },
    {
      id: "18",
      name: "Semantic Search at Flipkart by Thejus V M , Bipul Jain & Siddhartha Reddy",
      link: "https://www.youtube.com/watch?v=6T13BErzdw8",
    },
    {
      id: "19",
      name: "Panel Discussion : Hope, hype and reality of Big Data Analytics (slash n 2014)",
      link: "https://www.youtube.com/watch?v=AkagkaeUAuw",
    },
  ];

  const playvideo = (link, name, id) => {
    setYlink(link);
    setYname(name);
    setIdty(id);
  };

  return (
    <div
      className="grid col-span-1 md:grid-cols-3 gap-x-10 h-full divide-y md:divide-y-0 md:divide-x divide-gray-800"
      // style={{ border: "1px solid white" }}
    >
      <div
        className="md:col-span-2 text-white h-full"
        // style={{ border: "1px solid red" }}
      >
        <h1 className="text-white pb-2 md:pb-4">2014</h1>
        <div className="h-full pb-12 md:pb-0">
          <ReactPlayer
            url={ylink}
            controls={true}
            playing={true}
            width="100%"
            height="80%"
          />
          <h1
            className="pt-3 md:pt-6 text-sm md:text-lg line-clamp-2 overflow-hidden"
            style={{
              maxHeight: "3.6rem",
              lineHeight: "1.4rem",
              //   display: "block",
              //   textOverflow: "ellipsis",
              //   wordWrap: "break-word",
              //   overflow: "hidden",
            }}
          >
            {yname}
          </h1>
        </div>
      </div>
      <div
        className="col-span-1 p-2 divide-y divide-gray-800"
        style={{
          // border: "1px solid green",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        {playlist?.map((list) => {
          return (
            <div
              className="text-white grid grid-cols-5 py-2 cursor-pointer rounded-sm"
              style={{
                backgroundColor:
                  idty === list.id ? "rgba(46, 49, 146,0.4)" : null,
              }}
              onClick={() => playvideo(list.link, list.name, list.id)}
            >
              <div className="col-span-2">
                <div
                  className="w-full h-20 md:h-36 rounded-lg flex items-center justify-center"
                  style={{
                    // background:
                    //   "linear-gradient(90deg, #2E3192 -0.01%, #662D91 99.99%)",
                    backgroundImage: `url('/assets/thumbnails/2014/${list.id}.jpg')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <img
                    src="/assets/play.png"
                    alt="play"
                    className="h-14 md:h-auto"
                  />
                </div>
              </div>
              <div className="col-span-3 pl-2 flex items-center text-xs md:text-md">
                {list.name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Video2014;
