import React from "react";
import { Routes as RoutesContainer, Route } from "react-router-dom";
import HomePage from "./pages/homepage";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import VideoPlayer from "./pages/videoPlayer";
import Videos from "./pages/videos";

function Routes() {
  return (
    <div>
      <RoutesContainer>
        <Route path="/" element={<HomePage />} />
        <Route path="/videos">
          <Route index element={<Videos />} />
          <Route path=":id" element={<VideoPlayer />} />
        </Route>
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-of-use" element={<Terms />} />
      </RoutesContainer>
    </div>
  );
}

export default Routes;
