import React, { useState } from "react";
import SleekButton from "../button/SleekButton";
import VideoModal from "../modal/VideoModal";

function Agenda() {
  const [videolink, setVideolink] = useState("");
  const [started, setStarted] = useState(false);

  const readyfn = () => {};

  const closeVideo = () => {
    setVideolink("");
    setStarted("");
  };
  return (
    <div className="md:px-20">
      <div
      //   style={{ border: "2px solid #2E3192" }}
      >
        {/* ----------------------row 1------------------------- */}
        <div className="grid grid-cols-10 divide-x-2 divide-gray-900">
          <div
            className="col-span-3 md:col-span-2 text-center text-lg md:text-xl py-2"
            style={{ backgroundColor: "#2E3192" }}
          >
            TIME
          </div>
          <div
            className="col-span-7 md:col-span-8 text-center text-lg md:text-xl py-2"
            style={{
              background:
                "linear-gradient(90deg, #2E3192 -0.01%, #662D91 99.99%)",
            }}
          >
            SESSION DETAILS
          </div>
        </div>
        {/* ----------------------row 2------------------------- */}
        <div className="grid grid-cols-10 divide-x-2 divide-blue-900">
          <div
            className="col-span-3 md:col-span-2 text-center text-xs md:text-sm py-2 flex justify-center items-center"
            style={{
              borderBottom: "2px solid #2E3192",
              borderLeft: "2px solid #2E3192",
            }}
          >
            10:00 - 10:10 AM
          </div>
          <div
            className="col-span-7 md:col-span-8 text-center text-xs md:text-sm py-2"
            style={{
              borderBottom: "2px solid #2E3192",
              borderRight: "2px solid #662D91",
            }}
          >
            <div className="flex justify-center items-center space-x-4">
              <div>
                <div>Opening Note</div>
                <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                  Utkash B
                </div>
              </div>
              <div className="flex justify-center">
                <SleekButton
                  link="https://www.youtube.com/watch?v=TyUb5nL20Ak"
                  setVideolink={setVideolink}
                />
              </div>
            </div>
          </div>
        </div>
        {/* ----------------------row 3------------------------- */}
        <div className="grid grid-cols-10 divide-x-2 divide-blue-900">
          <div
            className="col-span-3 md:col-span-2 text-center text-xs md:text-sm py-2 flex justify-center items-center"
            style={{
              borderBottom: "2px solid #2E3192",
              borderLeft: "2px solid #2E3192",
            }}
          >
            10:10 - 10:30 AM
          </div>
          <div
            className="col-span-7 md:col-span-8 text-center text-sm"
            style={{
              borderBottom: "2px solid #2E3192",
              borderRight: "2px solid #662D91",
            }}
          >
            <div
              className="py-2 px-1"
              style={{
                borderBottom: "2px solid #2E3192",
              }}
            >
              <div className="flex justify-center items-center space-x-4">
                <div>Techade - Technology shaping the decade ahead</div>
                <SleekButton
                  link="https://www.youtube.com/watch?v=j_BszoFgWEI"
                  setVideolink={setVideolink}
                />
              </div>
            </div>
            <div className="px-1 md:px-6 py-2">
              <div className="flex justify-center items-center space-x-4">
                <div>
                  <div>Debjani Ghosh & Jeyendran Venugopal</div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    (President, National Association of Software & Services
                    Companies (NASSCOM)) <br /> in converstion with Jeyendran
                    Venugopal(CPTO, Flipkart)
                  </div>
                </div>
                {/* <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=DH5OFmxfsFo&list=PLgkm0izTmXcuDjb-G1Npj-NRo3HTFXVHE&index=7"
                    setVideolink={setVideolink}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* ----------------------row 4------------------------- */}
        <div className="grid grid-cols-10 divide-x-2 divide-blue-900">
          <div
            className="col-span-3 md:col-span-2 text-center text-xs md:text-sm py-2 flex justify-center items-center"
            style={{
              borderBottom: "2px solid #2E3192",
              borderLeft: "2px solid #2E3192",
            }}
          >
            10:30 - 11:10 AM
          </div>
          <div
            className="col-span-7 md:col-span-8 text-center text-sm"
            style={{
              borderBottom: "1px solid #2E3192",
              borderRight: "2px solid #662D91",
            }}
          >
            <div
              className="py-2 px-1 md:px-6"
              style={{
                borderBottom: "2px solid #2E3192",
              }}
            >
              <div className="flex justify-center items-center space-x-4">
                <div>
                  Expert Panel: How emerging tech trends are unlocking value -
                  to
                  <br className="hidden md:block" />
                  customers, to business and to Bharat.
                </div>
                <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=8GURunCOU0I"
                    setVideolink={setVideolink}
                  />
                </div>
              </div>
            </div>
            <div
              className="grid grid-cols-1 md:grid-cols-2 divide-y-2 md:divide-x-2 divide-blue-900"
              style={{
                borderBottom: "2px solid #2E3192",
              }}
            >
              <div className="py-2 px-1 md:pl-6 md:text-left flex items-center space-x-4">
                <div>
                  <div>Lalitesh Katragadda</div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    (Founder, Indihood and Google Map Maker)
                  </div>
                </div>
                {/* <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=DH5OFmxfsFo&list=PLgkm0izTmXcuDjb-G1Npj-NRo3HTFXVHE&index=7"
                    setVideolink={setVideolink}
                  />
                </div> */}
              </div>
              <div className="py-2 px-1 md:pl-6 md:text-left flex items-center space-x-4">
                <div>
                  <div>Pranav Tiwari</div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    (CTO, nurture.farm)
                  </div>
                </div>
                {/* <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=DH5OFmxfsFo&list=PLgkm0izTmXcuDjb-G1Npj-NRo3HTFXVHE&index=7"
                    setVideolink={setVideolink}
                  />
                </div> */}
              </div>
            </div>

            <div
              className="grid grid-cols-1 md:grid-cols-2 divide-y-2 md:divide-x-2 divide-blue-900"
              style={{
                borderBottom: "2px solid #2E3192",
              }}
            >
              <div className="py-2 px-1 md:pl-6 md:text-left flex items-center space-x-4">
                <div>
                  <div>Umakant Soni</div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    (Co-Founder and CEO, ArtPark)
                  </div>
                </div>
                {/* <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=DH5OFmxfsFo&list=PLgkm0izTmXcuDjb-G1Npj-NRo3HTFXVHE&index=7"
                    setVideolink={setVideolink}
                  />
                </div> */}
              </div>
              <div className="py-2 px-1 md:pl-6 md:text-left flex items-center space-x-4">
                <div>
                  <div>Rhimjhim Ray</div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    (Co-Founder, Spotle.ai)
                  </div>
                </div>
                {/* <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=DH5OFmxfsFo&list=PLgkm0izTmXcuDjb-G1Npj-NRo3HTFXVHE&index=7"
                    setVideolink={setVideolink}
                  />
                </div> */}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 divide-y-2 md:divide-x-2 divide-blue-900">
              <div className="py-2 px-1 md:pl-6 md:text-left flex items-center space-x-4">
                <div>
                  {" "}
                  <div>Bharath Ram</div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    (Product Head, Growth & Retention, Flipkart)
                  </div>
                </div>
                {/* <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=DH5OFmxfsFo&list=PLgkm0izTmXcuDjb-G1Npj-NRo3HTFXVHE&index=7"
                    setVideolink={setVideolink}
                  />
                </div> */}
              </div>
              <div className="py-2 px-1 md:px-6 md:text-left flex items-center space-x-4">
                <div>
                  <div>Moderator - Jeyandran Venugopal</div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    (Chief Product & Technology Officer, Flipkart)
                  </div>
                </div>
                {/* <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=DH5OFmxfsFo&list=PLgkm0izTmXcuDjb-G1Npj-NRo3HTFXVHE&index=7"
                    setVideolink={setVideolink}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* ----------------------row 5------------------------- */}
        <div className="grid grid-cols-10 divide-x-2 divide-gray-900">
          <div
            className="col-span-3 md:col-span-2 text-center text-xs md:text-sm py-1"
            style={{
              borderBottom: "2px solid #2E3192",
              borderLeft: "2px solid #2E3192",
            }}
          >
            11:10 - 11:15 AM
          </div>
          <div
            className="col-span-7 md:col-span-8 text-center text-sm py-1"
            style={{
              background:
                "linear-gradient(90deg, #2E3192 -0.01%, #662D91 99.99%)",
            }}
          >
            BREAK
          </div>
        </div>
        {/* ----------------------row 6------------------------- */}
        <div className="grid grid-cols-10 divide-x-2 divide-blue-900">
          <div
            className="col-span-3 md:col-span-2 text-center text-xs md:text-sm py-2 flex justify-center items-center"
            style={{
              borderBottom: "2px solid #2E3192",
              borderLeft: "2px solid #2E3192",
            }}
          >
            11:15 - 11:50 AM
          </div>
          <div
            className="col-span-7 md:col-span-8 text-center text-sm"
            style={{
              borderBottom: "2px solid #2E3192",
              borderRight: "2px solid #662D91",
            }}
          >
            <div className="grid grid-cols-1 md:grid-cols-3 divide-y-2 md:divide-x-2 divide-blue-900">
              <div className="py-2 px-1 md:pl-6 md:text-left flex items-center space-x-4">
                <div>
                  <div>
                    Search Engines: Past,
                    <br /> Present, and Future
                  </div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    Sriram Sankar <br />
                    Chief Architect,
                    <br /> Anyscale, Inc.
                  </div>
                </div>
                <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=6_by-LlrFy4"
                    setVideolink={setVideolink}
                  />
                </div>
              </div>
              <div className="flex justify-center items-center">-</div>
              <div className="py-2 px-1 md:px-6 md:text-left flex justify-center items-center space-x-4">
                <div>
                  <div>
                    Immersive Commerce - The Future of Shopping with 3D & AR
                  </div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    Ajay Venkatesha
                  </div>
                </div>
                <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=LzcFdqinC-g"
                    setVideolink={setVideolink}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ----------------------row 7------------------------- */}
        <div className="grid grid-cols-10 divide-x-2 divide-blue-900">
          <div
            className="col-span-3 md:col-span-2 text-center text-xs md:text-sm py-2 flex justify-center items-center"
            style={{
              borderBottom: "2px solid #2E3192",
              borderLeft: "2px solid #2E3192",
            }}
          >
            11:50 - 12:30 PM
          </div>
          <div
            className="col-span-7 md:col-span-8 text-center text-sm"
            style={{
              borderBottom: "2px solid #2E3192",
              borderRight: "2px solid #662D91",
            }}
          >
            <div className="grid grid-cols-1 md:grid-cols-3 divide-y-2 md:divide-x-2 divide-blue-900">
              <div className="py-2 px-1 md:pl-6 md:text-left flex items-center space-x-4">
                <div>
                  <div>
                    Building a Cost Effective and Highly Reliable Private Cloud
                    Infrastructure
                  </div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    Sudhir Reddy <br />
                    Jain Johny
                  </div>
                </div>
                <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=QOMcWwePj5Y"
                    setVideolink={setVideolink}
                  />
                </div>
              </div>
              <div className="py-2 px-1 md:pl-6 md:text-left flex items-center space-x-4">
                <div>
                  <div>
                    Less is More! Learnings from optimising and storing data
                  </div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    Anantharam Vanchiprakash
                  </div>
                </div>
                <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=TtXPuIuywAo"
                    setVideolink={setVideolink}
                  />
                </div>
              </div>
              <div className="py-2 px-1 md:px-6 md:text-left  flex items-center space-x-4">
                <div>
                  <div>
                    One codebase to rule them all, One codebase to test them all
                  </div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    Varsha Saha
                  </div>
                </div>
                <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=QrnWwxajCqw"
                    setVideolink={setVideolink}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ----------------------row 8------------------------- */}
        <div className="grid grid-cols-10 divide-x-2 divide-gray-900">
          <div
            className="col-span-3 md:col-span-2 text-center text-xs md:text-sm py-1"
            style={{
              borderBottom: "2px solid #2E3192",
              borderLeft: "2px solid #2E3192",
            }}
          >
            12:30 - 12:35 PM
          </div>
          <div
            className="col-span-7 md:col-span-8 text-center text-sm py-1"
            style={{
              background:
                "linear-gradient(90deg, #2E3192 -0.01%, #662D91 99.99%)",
            }}
          >
            BREAK
          </div>
        </div>
        {/* ----------------------row 9------------------------- */}
        <div className="grid grid-cols-10 divide-x-2 divide-blue-900">
          <div
            className="col-span-3 md:col-span-2 text-center text-xs md:text-sm py-2 flex justify-center items-center"
            style={{
              borderBottom: "2px solid #2E3192",
              borderLeft: "2px solid #2E3192",
            }}
          >
            12:35 - 1:15 PM
          </div>
          <div
            className="col-span-7 md:col-span-8 text-center text-sm"
            style={{
              borderBottom: "2px solid #2E3192",
              borderRight: "2px solid #662D91",
            }}
          >
            <div className="grid grid-cols-1 md:grid-cols-3 divide-y-2 md:divide-x-2 divide-blue-900">
              <div className="py-2 px-1 md:pl-6 md:text-left flex items-center space-x-4">
                <div>
                  <div>
                    Challenges, Journey and Future of Managing Hundreds of
                    Petabyte of Data @Flipkart's Data Platform
                  </div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    Anuj Mittal
                    <br /> Sunil Kumar
                  </div>
                </div>
                <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=hJXiP9AFmmk"
                    setVideolink={setVideolink}
                  />
                </div>
              </div>
              <div className="py-2 px-1 md:pl-6 md:text-left flex items-center space-x-4">
                <div>
                  <div>
                    Remodelling Flipkart's Serviceability data: An optimization
                    journey from 300 GB to 150 MB
                  </div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    Shyam Kumar Akirala
                    <br /> Abhishek Tyagi
                  </div>
                </div>
                <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=ua3UoARHAmY"
                    setVideolink={setVideolink}
                  />
                </div>
              </div>
              <div className="py-2 px-1 md:px-6 md:text-left flex items-center space-x-4">
                <div>
                  <div>Journey: Multi Tenancy in Apache HBase</div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    Mallikarjun
                  </div>
                </div>
                <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=ttGI9Ma7Xos"
                    setVideolink={setVideolink}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ----------------------row 10------------------------- */}
        <div className="grid grid-cols-10 divide-x-2 divide-gray-900">
          <div
            className="col-span-3 md:col-span-2 text-center text-xs md:text-sm py-1"
            style={{
              borderBottom: "2px solid #2E3192",
              borderLeft: "2px solid #2E3192",
            }}
          >
            1:15 - 2:00 PM
          </div>
          <div
            className="col-span-7 md:col-span-8 text-center text-sm py-1"
            style={{
              background:
                "linear-gradient(90deg, #2E3192 -0.01%, #662D91 99.99%)",
            }}
          >
            LUNCH
          </div>
        </div>
        {/* ----------------------row 11------------------------- */}
        <div className="grid grid-cols-10 divide-x-2 divide-blue-900">
          <div
            className="col-span-3 md:col-span-2 text-center text-xs md:text-sm py-2 flex justify-center items-center"
            style={{
              borderBottom: "2px solid #2E3192",
              borderLeft: "2px solid #2E3192",
            }}
          >
            2:00 - 2:30 PM
          </div>
          <div
            className="col-span-7 md:col-span-8 text-center text-sm"
            style={{
              borderBottom: "2px solid #2E3192",
              borderRight: "2px solid #662D91",
            }}
          >
            <div className="grid grid-cols-1 md:grid-cols-3 divide-y-2 md:divide-x-2 divide-blue-900">
              <div className="py-2 px-1 md:pl-6 md:text-left flex items-center space-x-4">
                <div>
                  <div>Rolling with the Patches</div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    Janmejay Singh
                  </div>
                </div>
                <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=vK59QaKl3r0"
                    setVideolink={setVideolink}
                  />
                </div>
              </div>
              <div className="py-2 px-1 md:pl-6 md:text-left flex items-center space-x-4">
                <div>
                  <div>
                    Building Evolutionary Architecture Case Study: Building
                    e-Tail Platform
                  </div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    Anand Immannavar
                  </div>
                </div>
                <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=VSfLyHUoEaI"
                    setVideolink={setVideolink}
                  />
                </div>
              </div>
              <div className="py-2 px-1 md:px-6 md:text-left flex items-center space-x-4">
                <div>
                  <div>OPUS: User Response Modelling using Online Learning</div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    Govil Khatri <br /> Sarthak Agarwal
                  </div>
                </div>
                <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=oHaE_OLjDnM"
                    setVideolink={setVideolink}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ----------------------row 12------------------------- */}
        <div className="grid grid-cols-10 divide-x-2 divide-blue-900">
          <div
            className="col-span-3 md:col-span-2 text-center text-xs md:text-sm py-2 flex justify-center items-center"
            style={{
              borderBottom: "2px solid #2E3192",
              borderLeft: "2px solid #2E3192",
            }}
          >
            2:30 - 3:00 PM
          </div>
          <div
            className="col-span-7 md:col-span-8 text-center text-sm"
            style={{
              borderBottom: "2px solid #2E3192",
              borderRight: "2px solid #662D91",
            }}
          >
            <div className="grid grid-cols-1 md:grid-cols-3 divide-y-2 md:divide-x-2 divide-blue-900">
              <div className="py-2 px-1 md:pl-6 md:text-left flex items-center space-x-4">
                <div>
                  <div>Scaling & Debugging Mysql for &gt; 300k qps</div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    Rahul Agrawal
                    <br /> Rajesh Kannan
                  </div>
                </div>
                <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=iWzVVkvmEqE"
                    setVideolink={setVideolink}
                  />
                </div>
              </div>
              <div className="py-2 px-1 md:pl-6 md:text-left flex items-center space-x-4">
                <div>
                  <div>
                    Many worlds, One Listing : Designing a Multifaceted Listings
                    Platform
                  </div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    Amrit Shroff <br /> Pradeep Nain
                  </div>
                </div>
                <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=SvoicHATSlY"
                    setVideolink={setVideolink}
                  />
                </div>
              </div>
              <div className="py-2 px-1 md:px-6 md:text-left flex items-center space-x-4">
                <div>
                  <div>
                    Morpheus: Dive into the Flipkart Auto Content Platform
                  </div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    Rahul Pradeep <br /> Ruchika Kundu
                  </div>
                </div>
                <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=vI7aZr5qYk8"
                    setVideolink={setVideolink}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ----------------------row 13------------------------- */}
        <div className="grid grid-cols-10 divide-x-2 divide-gray-900">
          <div
            className="col-span-3 md:col-span-2 text-center text-xs md:text-sm py-1"
            style={{
              borderBottom: "2px solid #2E3192",
              borderLeft: "2px solid #2E3192",
            }}
          >
            3:00 - 3:05 PM
          </div>
          <div
            className="col-span-7 md:col-span-8 text-center text-sm py-1"
            style={{
              background:
                "linear-gradient(90deg, #2E3192 -0.01%, #662D91 99.99%)",
            }}
          >
            BREAK
          </div>
        </div>
        {/* ----------------------row 14------------------------- */}
        <div className="grid grid-cols-10 divide-x-2 divide-blue-900">
          <div
            className="col-span-3 md:col-span-2 text-center text-xs md:text-sm py-2 flex justify-center items-center"
            style={{
              borderBottom: "2px solid #2E3192",
              borderLeft: "2px solid #2E3192",
            }}
          >
            3:05 - 3:35 PM
          </div>
          <div
            className="col-span-7 md:col-span-8 text-center text-sm"
            style={{
              borderBottom: "2px solid #2E3192",
              borderRight: "2px solid #662D91",
            }}
          >
            <div className="grid grid-cols-1 md:grid-cols-3 divide-y-2 md:divide-x-2 divide-blue-900">
              <div className="py-2 px-1 md:pl-6 md:text-left flex items-center space-x-4">
                <div>
                  <div>
                    DKV - FOSS Scalable MultiDC Distributed KeyValue Store
                  </div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    Kinshuk Bairagi <br /> Kalyan Akella
                  </div>
                </div>
                <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=xSjxdPdQ-uo"
                    setVideolink={setVideolink}
                  />
                </div>
              </div>
              <div className="py-2 px-1 md:pl-6 md:text-left flex items-center space-x-4">
                <div>
                  <div>
                    Distributed Transaction Manager in Workflow Orchestration
                  </div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    Gaurav Prasad <br /> Kaustav Das
                  </div>
                </div>
                <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=dBFae9ZqJeo"
                    setVideolink={setVideolink}
                  />
                </div>
              </div>
              <div className="py-2 px-1 md:px-6 md:text-left flex items-center space-x-4">
                <div>
                  <div>Machine Translation at Flipkart</div>
                  <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                    Nikesh Garera
                  </div>
                </div>
                <div>
                  <SleekButton
                    link="https://www.youtube.com/watch?v=7z8N_Pj9Htk"
                    setVideolink={setVideolink}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ----------------------row 15------------------------- */}
        <div className="grid grid-cols-10 divide-x-2 divide-blue-900">
          <div
            className="col-span-3 md:col-span-2 text-center text-xs md:text-sm py-2 flex justify-center items-center"
            style={{
              borderBottom: "2px solid #2E3192",
              borderLeft: "2px solid #2E3192",
            }}
          >
            3:35 - 4:30 PM
          </div>
          <div
            className="col-span-7 md:col-span-8 text-center text-sm"
            style={{
              borderBottom: "1px solid #2E3192",
              borderRight: "2px solid #662D91",
            }}
          >
            <div
              className="py-2 flex justify-center items-center space-x-4"
              style={{
                borderBottom: "2px solid #2E3192 ",
              }}
            >
              <div>Developer Lightning Talks</div>
              {/* <div>
                <SleekButton
                  link="https://www.youtube.com/watch?v=DH5OFmxfsFo&list=PLgkm0izTmXcuDjb-G1Npj-NRo3HTFXVHE&index=7"
                  setVideolink={setVideolink}
                />
              </div> */}
            </div>
            <div
              className="py-2 flex justify-center items-center space-x-4"
              style={{
                borderBottom: "2px solid #2E3192",
              }}
            >
              <div>
                <div>Interactive Videos - New Kid in The Town</div>
                <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                  Avinash Vundyal, Vikas Mishra
                </div>
              </div>
              <div>
                <SleekButton
                  link="https://www.youtube.com/watch?v=ZrSsfZZ_OD4"
                  setVideolink={setVideolink}
                />
              </div>
            </div>
            <div
              className="py-2 flex justify-center items-center space-x-4"
              style={{
                borderBottom: "2px solid #2E3192",
              }}
            >
              <div>
                <div>Un-skew It - Production Taming of Kafka</div>
                <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                  Md Mehrab Alam
                </div>
              </div>
              <div>
                <SleekButton
                  link="https://www.youtube.com/watch?v=GrKSGK__1ps"
                  setVideolink={setVideolink}
                />
              </div>
            </div>
            <div
              className="py-2 flex justify-center items-center space-x-4"
              style={{
                borderBottom: "2px solid #2E3192",
              }}
            >
              <div>
                <div>3D-fication - ElectronJS to 3D Models Rescue</div>
                <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                  Sachin Srinivasan
                </div>
              </div>
              <div>
                <SleekButton
                  link="https://www.youtube.com/watch?v=VNAOo3NjFV0"
                  setVideolink={setVideolink}
                />
              </div>
            </div>
            <div
              className="py-2 flex justify-center items-center space-x-4"
              style={{
                borderBottom: "2px solid #2E3192",
              }}
            >
              <div>
                <div>
                  Its a price drop, Its out of stock , It's a reminder -- No
                  it's a Journey
                </div>
                <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                  Ayush Agarwal
                </div>
              </div>
              <div>
                <SleekButton
                  link="https://www.youtube.com/watch?v=Lgg6y-RoGN0"
                  setVideolink={setVideolink}
                />
              </div>
            </div>
            <div className="py-2 flex justify-center items-center space-x-4">
              <div>
                <div>Resilience4all - championing resilience4j @FK</div>
                <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                  Robin KC
                </div>
              </div>
              <div>
                <SleekButton
                  link="https://www.youtube.com/watch?v=cUBO2gusKlo"
                  setVideolink={setVideolink}
                />
              </div>
            </div>
          </div>
        </div>
        {/* ----------------------row 16------------------------- */}
        <div className="grid grid-cols-10 divide-x-2 divide-gray-900">
          <div
            className="col-span-3 md:col-span-2 text-center text-xs md:text-sm py-1"
            style={{
              borderBottom: "2px solid #2E3192",
              borderLeft: "2px solid #2E3192",
            }}
          >
            4:30 - 4:40 PM
          </div>
          <div
            className="col-span-7 md:col-span-8 text-center text-sm py-1"
            style={{
              background:
                "linear-gradient(90deg, #2E3192 -0.01%, #662D91 99.99%)",
            }}
          >
            BREAK
          </div>
        </div>
        {/* ----------------------row 17------------------------- */}
        <div className="grid grid-cols-10 divide-x-2 divide-blue-900">
          <div
            className="col-span-3 md:col-span-2 text-center text-xs md:text-sm py-2 flex justify-center items-center"
            style={{
              borderBottom: "2px solid #2E3192",
              borderLeft: "2px solid #2E3192",
            }}
          >
            4:40 - 5:25 PM
          </div>
          <div
            className="col-span-7 md:col-span-8 text-center text-sm py-2 px-1 md:px-6  flex justify-center items-center space-x-4"
            style={{
              borderBottom: "2px solid #2E3192",
              borderRight: "2px solid #662D91",
            }}
          >
            <div className="space-y-4">
              <div>
                “Big picture of building a digital backbone for a billion
                people”
                <br />- A fireside chat with Pramod Varma
              </div>
              <div className="italic text-xs" style={{ color: "#13C3FB" }}>
                (CTO EkStep Foundation, Chief Architect Aadhaar & India Stack,
                Co-founder Beckn Foundation)
              </div>
              <div>
                <span>Chat Host: Utkarsh B</span>
                <span
                  className="italic text-xs ml-3"
                  style={{ color: "#13C3FB" }}
                >
                  (Chief Architect, Flipkart)
                </span>
              </div>

              <div>
                The Fireside Chat will cover architecture patterns that make
                solutions evolvable and adaptable, how even when building for
                the future, the focus is still on implementing today, and how
                platform first mindset is required to evolve into protocols and
                specifications.
              </div>
            </div>
            <div>
              <SleekButton
                link="https://www.youtube.com/watch?v=fQPwYEvANY4"
                setVideolink={setVideolink}
              />
            </div>
          </div>
        </div>
        {/* ----------------------row 18------------------------- */}
        <div className="grid grid-cols-10 divide-x-2 divide-blue-900">
          <div
            className="col-span-3 md:col-span-2 text-center text-xs md:text-sm py-2 flex justify-center items-center"
            style={{
              borderBottom: "2px solid #2E3192",
              borderLeft: "2px solid #2E3192",
            }}
          >
            5:25 - 5:30 PM
          </div>
          <div
            className="col-span-7 md:col-span-8 text-center text-sm py-2 flex justify-center items-center space-x-4"
            style={{
              borderBottom: "2px solid #2E3192",
              borderRight: "2px solid #662D91",
            }}
          >
            <div>Closing Note</div>
            <div>
              <SleekButton
                link="https://www.youtube.com/watch?v=y4rgsb_Q14A"
                setVideolink={setVideolink}
              />
            </div>
          </div>
        </div>
      </div>

      {/* {videolink !== "" && (
        <div
          className="absolute top-0 left-0 h-full w-full"
          style={{ background: "rgba(0,0,0,0.6)", zIndex: "40" }}
        />
      )} */}

      {videolink !== "" && (
        <VideoModal
          videolink={videolink}
          started={started}
          setStarted={setStarted}
          closeVideo={closeVideo}
        />
      )}
    </div>
  );
}

export default Agenda;
