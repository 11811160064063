import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import Video2013 from "../../components/Video2013";
import Video2014 from "../../components/Video2014";
import Video2016 from "../../components/Video2016";
import Video2018 from "../../components/Video2018";
import Video2019 from "../../components/Video2019";

function VideoPlayer() {
  // const [ylink, setYlink] = useState(
  //   "https://www.youtube.com/watch?v=Q4RNAg5OU7o"
  // );
  // const [yname, setYname] = useState("Opening Note by Utkarsh B");
  const [year, setYear] = useState("");
  // const [idty, setIdty] = useState("1");

  const { id } = useParams();

  useEffect(() => {
    console.log("id");
    console.log(id);
    setYear(id);
  }, [id]);

  // const playlist = [
  //   {
  //     id: "1",
  //     name: "Opening Note by Utkarsh B",
  //     link: "https://www.youtube.com/watch?v=Q4RNAg5OU7o",
  //   },
  //   {
  //     id: "2",
  //     name: "Actionable Insight Extraction From Reviews And Images - Shreyas Shetty M, Rohit Yadav",
  //     link: "https://www.youtube.com/watch?v=daFShb63BVw",
  //   },
  //   {
  //     id: "3",
  //     name: "Alternate Data Based Credit Underwriting - Rishabh, Kaustav",
  //     link: "https://www.youtube.com/watch?v=zbq3GqTd3j8",
  //   },
  //   {
  //     id: "4",
  //     name: "India's Digitisation Journey - Creating the levers of growth - Sanjay Jain",
  //     link: "https://www.youtube.com/watch?v=vqQGnZknqhU",
  //   },
  //   {
  //     id: "5",
  //     name: "Unified Cluster Federation - Varadaraja, Romit",
  //     link: "https://www.youtube.com/watch?v=EqWtrfF3a4U",
  //   },
  //   {
  //     id: "6",
  //     name: "Boosting Developer Productivity With A Reliable Test Infrastructure -Sreehari Mohan, Arun Krishnan",
  //     link: "https://www.youtube.com/watch?v=MrwMLCLLlZ0",
  //   },
  //   {
  //     id: "7",
  //     name: "Cross modal Retrieval - Prof. Soma Biswas (IISc)",
  //     link: "https://www.youtube.com/watch?v=3zNyz_xKlcw",
  //   },
  //   {
  //     id: "8",
  //     name: "fStream - Continuous Processing @ Scale - Naveen Kumar, Prashant Bhardwaj",
  //     link: "https://www.youtube.com/watch?v=PjUAW0oh6eI",
  //   },
  //   {
  //     id: "9",
  //     name: "Games - Tech Solutions For Driving Engagement - Talha, Amarjeet Singh",
  //     link: "https://www.youtube.com/watch?v=Czh0pIMwzNE",
  //   },
  //   {
  //     id: "10",
  //     name: "Meeseeks Service Graph - Gaurav Sharma & Dhruv Kumar",
  //     link: "https://www.youtube.com/watch?v=GqOE5K6909s",
  //   },
  //   {
  //     id: "11",
  //     name: "OneView - Rethinking The Way We Build Mobile Apps - Kiran Kumar",
  //     link: "https://www.youtube.com/watch?v=usNT2uOsmjU",
  //   },
  //   {
  //     id: "12",
  //     name: "Panel Discussion Data Sciences: The New Fuel For Business And How To Make It Work For You",
  //     link: "https://www.youtube.com/watch?v=VW04_U74ov0",
  //   },
  //   {
  //     id: "13",
  //     name: "Personalized Ranking @ Scale - Thejus and Tanuj",
  //     link: "https://www.youtube.com/watch?v=JhT09tKaVLY",
  //   },
  //   {
  //     id: "14",
  //     name: "Polestar - User's Behavioural Analysis At Scale - Rijo Joseph, Deepak Batra (Myntra)",
  //     link: "https://www.youtube.com/watch?v=rPrxtK6jh1A",
  //   },
  //   {
  //     id: "15",
  //     name: "Protecting Flipkart Customer and Building Trust on Platform - Vaibhav Srivastava, Vinodh Balasubrama",
  //     link: "https://www.youtube.com/watch?v=ado7lp4dNR8",
  //   },
  //   {
  //     id: "16",
  //     name: "Shaping The Future Of eCommerce In India Through Technology - Pranav Saxena, Regu B",
  //     link: "https://www.youtube.com/watch?v=aqArVlUojGo",
  //   },
  //   {
  //     id: "17",
  //     name: "Machine Serve CX - Abir Chakraborty, Anirban Majumder",
  //     link: "https://www.youtube.com/watch?v=EvJs8ojQjxw",
  //   },
  //   {
  //     id: "18",
  //     name: "Virtuous Cycles Building Feedback Loops Into Your Software So You Can Get More Sleep - Aaditya Tal",
  //     link: "https://www.youtube.com/watch?v=LqxB94Xhus0",
  //   },
  //   {
  //     id: "19",
  //     name: "Strengthening Credit Underwriting With Trust Network - Samik Datta",
  //     link: "https://www.youtube.com/watch?v=-iR-6PxP4dQ",
  //   },
  //   {
  //     id: "20",
  //     name: "Keynote by Dale Vaz - Decoding Hyperlocal - Lessons From The Front Line (Swiggy)",
  //     link: "https://www.youtube.com/watch?v=64iRoC0QVf4",
  //   },
  //   {
  //     id: "21",
  //     name: "Information And Opinion Dynamics In Online Social Networks - Prof Niloy Ganguly",
  //     link: "https://www.youtube.com/watch?v=qejlwZJ6nbc",
  //   },
  //   {
  //     id: "22",
  //     name: "Hop, Skip And A Jump - Raghdip Singh Panesar, Giridhar Yasa",
  //     link: "https://www.youtube.com/watch?v=SDaSoW72WF8",
  //   },
  //   {
  //     id: "23",
  //     name: "Gone In A Flash - Learnings & Solutions From Flipkart Flash Sales - Gokulvanan, Srinidhi V K",
  //     link: "https://www.youtube.com/watch?v=vxJ9_WbV7B4",
  //   },
  //   {
  //     id: "24",
  //     name: "Automated Beat Planner - Flipkart's Solution For Vehicle Routing Problem - Maheshkumar, Akshay Raj",
  //     link: "https://www.youtube.com/watch?v=WNN7km1yqOc",
  //   },
  //   {
  //     id: "25",
  //     name: "Query Rewrite Done Right - Rohan Kumar, Manish Bansal",
  //     link: "https://www.youtube.com/watch?v=FO8_3UBkxZA",
  //   },
  //   {
  //     id: "26",
  //     name: "Unified Product Embeddings - Loveperteek Singh",
  //     link: "https://www.youtube.com/watch?v=US4J-wRDmns",
  //   },
  //   {
  //     id: "27",
  //     name: "Scalable Real time And Batch Similarity Search For Clustering Of Model generated Embeddings - Ananda",
  //     link: "https://www.youtube.com/watch?v=zgDazCTb7fY",
  //   },
  //   {
  //     id: "28",
  //     name: "Customer Value Centric Pricing Platform - Anirudh, Anshul",
  //     link: "https://www.youtube.com/watch?v=Ea89xiQolP8",
  //   },
  //   {
  //     id: "29",
  //     name: "Detecting Anomalies In Flipkart's Fulfillment Network - Nitish Joshi, Vinay Ethiraj",
  //     link: "https://www.youtube.com/watch?v=avSYTEcvDhM",
  //   },
  //   {
  //     id: "30",
  //     name: "Kubernetes To Ease Spark Anaytics From Standalone To Serverless - Rachit Arora",
  //     link: "https://www.youtube.com/watch?v=jsVOCNB-30U",
  //   },
  //   {
  //     id: "31",
  //     name: "Personalized Similar Styles Recommendation - Pankaj Agarwal, Sreekanth Vempati",
  //     link: "https://www.youtube.com/watch?v=E4LtXjfjt1Y",
  //   },
  //   {
  //     id: "32",
  //     name: "Redis Footprint Reduction Journey - Kalyan Akella, Rohit Gautam",
  //     link: "https://www.youtube.com/watch?v=BTSJRGVvWgY",
  //   },
  //   {
  //     id: "33",
  //     name: "Deferred Inference - Procrastination Is Not All That Bad - Dr Gaurav Aggarwal (Ola)",
  //     link: "https://www.youtube.com/watch?v=peo1-EmXqVU",
  //   },
  // ];

  // const playvideo = (link, name, id) => {
  //   setYlink(link);
  //   setYname(name);
  //   setIdty(id);
  // };

  const playerContainer = () => {
    switch (id) {
      case "2019":
        return <Video2019 />;
      case "2018":
        return <Video2018 />;
      case "2016":
        return <Video2016 />;
      case "2014":
        return <Video2014 />;
      case "2013":
        return <Video2013 />;
      default:
        return <Video2019 />;
    }
  };

  return (
    <div>
      <nav className="fixed top-0 w-screen left-0 z-50">
        <div className="bg-black/30 py-2">
          <div className="flex justify-between container mx-auto px-10 py-2">
            <img
              src="/assets/flipkart.png"
              alt="Flipkart logo"
              className="h-8 self-end"
            />

            <div className="inline-flex hidden items-center uppercase text-white">
              <div className="px-3 hover:text-orange-400 cursor-pointer">
                Home
              </div>
              <div className="h-3 bg-white border" />
              <div className="px-3">Home</div>
              <div className="h-3 bg-white border" />
              <div className="px-3">Home</div>
            </div>
          </div>
        </div>
      </nav>

      <div
        className="container mx-auto px-6 md:px-10 pt-20 pb-6 md:pb-10 h-screen"
        style={{ overflow: "hidden" }}
      >
        {/* {console.log("year")}
        {console.log(id)} */}

        {/* <Video2019 /> */}
        {playerContainer()}
      </div>
    </div>
  );
}

export default VideoPlayer;
