import React from "react";

function Videos() {
  return (
    <div>
      <div className="container mx-auto px-10 py-10 md:pb-24 relative">
        <div className="flex items-center justify-center">
          <div
            className="text-center rounded-full inline-block text-white md:text-xl py-2 md:px-20 px-10"
            style={
              {
                //   background:
                //     "linear-gradient(90deg, #2E3192 -0.01%, #662D91 99.99%)",
              }
            }
          >
            Throwback Videos
          </div>
        </div>
        <div className="relative w-full pt-16 md:px-20">
          <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-14 gap-y-10 carousal-section snap-x box-inner ">
            <div className="md:px-10">
              {/* <a
                href="https://www.youtube.com/playlist?list=PLNrODmzoJwwpPQclVvIz8c7bGsMwwA0yG"
                target="_blank"
                className="p-6 md:px-16 flex-none flex flex-col items-center snap-center md:w-1/3"
              > */}
              <div
                className="w-full h-36 rounded-lg flex items-center justify-center"
                style={{
                  background:
                    "linear-gradient(90deg, #2E3192 -0.01%, #662D91 99.99%)",
                }}
              >
                <img src="./assets/play.png" alt="play" />
              </div>
              <div className="text-center text-white text-lg py-4">2019</div>
              {/* </a> */}
            </div>
            <div className="md:px-10">
              {/* <a
                href="https://www.youtube.com/watch?v=oOrPeIpV6wk&list=PLNrODmzoJwwrVrcKsqcENLsV4S7aCp9KJ"
                target="_blank"
                className="p-6 px-16 flex-none flex flex-col items-center snap-center md:w-1/3"
              > */}
              <div
                className="w-full h-36 rounded-lg flex items-center justify-center"
                style={{
                  background:
                    "linear-gradient(90deg, #2E3192 -0.01%, #662D91 99.99%)",
                }}
              >
                <img src="./assets/play.png" alt="play" />
              </div>
              <div className="text-center text-white text-lg py-4">2018</div>
              {/* </a> */}
            </div>
            <div className="md:px-10">
              {/* <a
                href="https://www.youtube.com/watch?v=OsfuOUPB1CA&list=PLNrODmzoJwwquif5reHC6VtLbECaIZxWO"
                target="_blank"
                className="p-6 px-16 flex-none flex flex-col items-center snap-center md:w-1/3"
              > */}
              <div
                className="w-full h-36 rounded-lg flex items-center justify-center"
                style={{
                  background:
                    "linear-gradient(90deg, #2E3192 -0.01%, #662D91 99.99%)",
                }}
              >
                <img src="./assets/play.png" alt="play" />
              </div>
              <div className="text-center text-white text-lg py-4">2016</div>
              {/* </a> */}
            </div>
            <div className="md:px-10">
              {/* <a
                href="https://www.youtube.com/playlist?list=PLNrODmzoJwwr2wCwxRtpscuoFxAwuGGff"
                target="_blank"
                className="p-6 px-16 flex-none flex flex-col items-center snap-center md:w-1/3"
              > */}
              <div
                className="w-full h-36 rounded-lg flex items-center justify-center"
                style={{
                  background:
                    "linear-gradient(90deg, #2E3192 -0.01%, #662D91 99.99%)",
                }}
              >
                <img src="./assets/play.png" alt="play" />
              </div>
              <div className="text-center text-white text-lg py-4">2014</div>
              {/* </a> */}
            </div>
            <div className="md:px-10">
              {/* <a
                href="https://www.youtube.com/watch?v=REhRrmjvl9Q&list=PLNrODmzoJwwrgPtbHfeyEYiZqguSTk5ws"
                target="_blank"
                className="p-6 px-16 flex-none flex flex-col items-center snap-center md:w-1/3"
              > */}
              <div
                className="w-full h-36 rounded-lg flex items-center justify-center"
                style={{
                  background:
                    "linear-gradient(90deg, #2E3192 -0.01%, #662D91 99.99%)",
                }}
              >
                <img src="./assets/play.png" alt="play" />
              </div>
              <div className="text-center text-white text-lg py-4">2013</div>
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Videos;
