import React, { useState } from "react";
import ReactPlayer from "react-player";

function Video2019() {
  const [ylink, setYlink] = useState(
    "https://www.youtube.com/watch?v=Q4RNAg5OU7o"
  );
  const [yname, setYname] = useState("Opening Note by Utkarsh B");
  const [idty, setIdty] = useState("1");

  const playlist = [
    {
      id: "1",
      name: "Opening Note by Utkarsh B",
      link: "https://www.youtube.com/watch?v=Q4RNAg5OU7o",
    },
    {
      id: "2",
      name: "Actionable Insight Extraction From Reviews And Images - Shreyas Shetty M, Rohit Yadav",
      link: "https://www.youtube.com/watch?v=daFShb63BVw",
    },
    {
      id: "3",
      name: "Alternate Data Based Credit Underwriting - Rishabh, Kaustav",
      link: "https://www.youtube.com/watch?v=zbq3GqTd3j8",
    },
    {
      id: "4",
      name: "India's Digitisation Journey - Creating the levers of growth - Sanjay Jain",
      link: "https://www.youtube.com/watch?v=vqQGnZknqhU",
    },
    {
      id: "5",
      name: "Unified Cluster Federation - Varadaraja, Romit",
      link: "https://www.youtube.com/watch?v=EqWtrfF3a4U",
    },
    {
      id: "6",
      name: "Boosting Developer Productivity With A Reliable Test Infrastructure -Sreehari Mohan, Arun Krishnan",
      link: "https://www.youtube.com/watch?v=MrwMLCLLlZ0",
    },
    {
      id: "7",
      name: "Cross modal Retrieval - Prof. Soma Biswas (IISc)",
      link: "https://www.youtube.com/watch?v=3zNyz_xKlcw",
    },
    {
      id: "8",
      name: "fStream - Continuous Processing @ Scale - Naveen Kumar, Prashant Bhardwaj",
      link: "https://www.youtube.com/watch?v=PjUAW0oh6eI",
    },
    {
      id: "9",
      name: "Games - Tech Solutions For Driving Engagement - Talha, Amarjeet Singh",
      link: "https://www.youtube.com/watch?v=Czh0pIMwzNE",
    },
    {
      id: "10",
      name: "Meeseeks Service Graph - Gaurav Sharma & Dhruv Kumar",
      link: "https://www.youtube.com/watch?v=GqOE5K6909s",
    },
    {
      id: "11",
      name: "OneView - Rethinking The Way We Build Mobile Apps - Kiran Kumar",
      link: "https://www.youtube.com/watch?v=usNT2uOsmjU",
    },
    {
      id: "12",
      name: "Panel Discussion Data Sciences: The New Fuel For Business And How To Make It Work For You",
      link: "https://www.youtube.com/watch?v=VW04_U74ov0",
    },
    {
      id: "13",
      name: "Personalized Ranking @ Scale - Thejus and Tanuj",
      link: "https://www.youtube.com/watch?v=JhT09tKaVLY",
    },
    {
      id: "14",
      name: "Polestar - User's Behavioural Analysis At Scale - Rijo Joseph, Deepak Batra (Myntra)",
      link: "https://www.youtube.com/watch?v=rPrxtK6jh1A",
    },
    {
      id: "15",
      name: "Protecting Flipkart Customer and Building Trust on Platform - Vaibhav Srivastava, Vinodh Balasubrama",
      link: "https://www.youtube.com/watch?v=ado7lp4dNR8",
    },
    {
      id: "16",
      name: "Shaping The Future Of eCommerce In India Through Technology - Pranav Saxena, Regu B",
      link: "https://www.youtube.com/watch?v=aqArVlUojGo",
    },
    {
      id: "17",
      name: "Machine Serve CX - Abir Chakraborty, Anirban Majumder",
      link: "https://www.youtube.com/watch?v=EvJs8ojQjxw",
    },
    {
      id: "18",
      name: "Virtuous Cycles Building Feedback Loops Into Your Software So You Can Get More Sleep - Aaditya Tal",
      link: "https://www.youtube.com/watch?v=LqxB94Xhus0",
    },
    {
      id: "19",
      name: "Strengthening Credit Underwriting With Trust Network - Samik Datta",
      link: "https://www.youtube.com/watch?v=-iR-6PxP4dQ",
    },
    {
      id: "20",
      name: "Keynote by Dale Vaz - Decoding Hyperlocal - Lessons From The Front Line (Swiggy)",
      link: "https://www.youtube.com/watch?v=64iRoC0QVf4",
    },
    {
      id: "21",
      name: "Information And Opinion Dynamics In Online Social Networks - Prof Niloy Ganguly",
      link: "https://www.youtube.com/watch?v=qejlwZJ6nbc",
    },
    {
      id: "22",
      name: "Hop, Skip And A Jump - Raghdip Singh Panesar, Giridhar Yasa",
      link: "https://www.youtube.com/watch?v=SDaSoW72WF8",
    },
    {
      id: "23",
      name: "Gone In A Flash - Learnings & Solutions From Flipkart Flash Sales - Gokulvanan, Srinidhi V K",
      link: "https://www.youtube.com/watch?v=vxJ9_WbV7B4",
    },
    {
      id: "24",
      name: "Automated Beat Planner - Flipkart's Solution For Vehicle Routing Problem - Maheshkumar, Akshay Raj",
      link: "https://www.youtube.com/watch?v=WNN7km1yqOc",
    },
    {
      id: "25",
      name: "Query Rewrite Done Right - Rohan Kumar, Manish Bansal",
      link: "https://www.youtube.com/watch?v=FO8_3UBkxZA",
    },
    {
      id: "26",
      name: "Unified Product Embeddings - Loveperteek Singh",
      link: "https://www.youtube.com/watch?v=US4J-wRDmns",
    },
    {
      id: "27",
      name: "Scalable Real time And Batch Similarity Search For Clustering Of Model generated Embeddings - Ananda",
      link: "https://www.youtube.com/watch?v=zgDazCTb7fY",
    },
    {
      id: "28",
      name: "Customer Value Centric Pricing Platform - Anirudh, Anshul",
      link: "https://www.youtube.com/watch?v=Ea89xiQolP8",
    },
    {
      id: "29",
      name: "Detecting Anomalies In Flipkart's Fulfillment Network - Nitish Joshi, Vinay Ethiraj",
      link: "https://www.youtube.com/watch?v=avSYTEcvDhM",
    },
    {
      id: "30",
      name: "Kubernetes To Ease Spark Anaytics From Standalone To Serverless - Rachit Arora",
      link: "https://www.youtube.com/watch?v=jsVOCNB-30U",
    },
    {
      id: "31",
      name: "Personalized Similar Styles Recommendation - Pankaj Agarwal, Sreekanth Vempati",
      link: "https://www.youtube.com/watch?v=E4LtXjfjt1Y",
    },
    {
      id: "32",
      name: "Redis Footprint Reduction Journey - Kalyan Akella, Rohit Gautam",
      link: "https://www.youtube.com/watch?v=BTSJRGVvWgY",
    },
    {
      id: "33",
      name: "Deferred Inference - Procrastination Is Not All That Bad - Dr Gaurav Aggarwal (Ola)",
      link: "https://www.youtube.com/watch?v=peo1-EmXqVU",
    },
  ];

  const playvideo = (link, name, id) => {
    setYlink(link);
    setYname(name);
    setIdty(id);
  };

  return (
    <div
      className="grid col-span-1 md:grid-cols-3 gap-x-10 h-full divide-y md:divide-y-0 md:divide-x divide-gray-800"
      // style={{ border: "1px solid white" }}
    >
      <div
        className="md:col-span-2 text-white h-full"
        // style={{ border: "1px solid red" }}
      >
        <h1 className="text-white pb-2 md:pb-4">2019</h1>
        <div className="h-full pb-12 md:pb-0">
          <ReactPlayer
            url={ylink}
            controls={true}
            playing={true}
            width="100%"
            height="80%"
          />
          <h1 className="pt-3 md:pt-6 text-sm md:text-lg">{yname}</h1>
        </div>
      </div>
      <div
        className="col-span-1 p-2 divide-y divide-gray-800"
        style={{
          // border: "1px solid green",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        {playlist?.map((list) => {
          return (
            <div
              className="text-white grid grid-cols-5 py-2 cursor-pointer rounded-sm"
              style={{
                backgroundColor:
                  idty === list.id ? "rgba(46, 49, 146,0.4)" : null,
              }}
              onClick={() => playvideo(list.link, list.name, list.id)}
            >
              <div className="col-span-2">
                <div
                  className="w-full h-20 md:h-36 rounded-lg flex items-center justify-center"
                  style={{
                    // background:
                    //   "linear-gradient(90deg, #2E3192 -0.01%, #662D91 99.99%)",

                    backgroundImage: `url('/assets/thumbnails/2019/${list.id}.jpg')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <img
                    src="/assets/play.png"
                    alt="play"
                    className="h-14 md:h-auto"
                  />
                </div>
              </div>
              <div className="col-span-3 pl-2 flex items-center text-xs md:text-md">
                {list.name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Video2019;
