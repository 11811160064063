import React, { useState } from "react";
import { Link } from "react-router-dom";
import Agenda from "../../components/agenda/Agenda";
import SleekButton from "../../components/button/SleekButton";
import VideoModal from "../../components/modal/VideoModal";
import VideoSlider from "../../components/VideoSlider";
import Layout from "../../layouts";

function HomePage() {
  const [modalOpen, setModalOpen] = useState(false);
  const [speakerModal, setSpeakerModal] = useState("");

  return (
    <div>
      {/* <Layout> */}
      <nav className="fixed top-0 w-screen left-0 z-50">
        <div className="bg-black/30 py-2">
          <div className="flex justify-between container mx-auto px-10 py-2">
            <img
              src="./assets/flipkart.png"
              alt="Flipkart logo"
              className="h-8 self-end"
            />
            <div className="inline-flex hidden items-center uppercase text-white">
              <div className="px-3 hover:text-orange-400 cursor-pointer">
                Home
              </div>
              <div className="h-3 bg-white border" />
              <div className="px-3">Home</div>
              <div className="h-3 bg-white border" />
              <div className="px-3">Home</div>
            </div>
          </div>
        </div>
      </nav>
      {/* --------------------------------Main Banner---------------------------------- */}
      <section
        className="w-screen h-screen"
        style={{
          backgroundImage: 'url("./assets/mainbanner_bg.png")',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor:
            "liner-gradiant(184.49deg, rgba(0, 0, 0, 0) 50.06%,rgba(0, 0, 0, 0.67) 163.02% )",
        }}
      >
        <div className="flex flex-col h-full items-center justify-center relative md:pt-6 xl:pt-8">
          <div className="absolute top-20 flex items-center justify-center">
            <div
              className="text-gray-300 text-center text-sm px-6 z-100"
              style={{ fontFamily: '"chalk", cursive' }}
            >
              function spin (){"{"} echo "|/-\\" | grep -o . | while read j ; do
              sleep 0.1; echo -ne "\b$j"; done {"}"}
            </div>
          </div>
          <img
            src="./assets/mainbanner_img2.png"
            alt="main image"
            className=" md:w-2/3 mix-blend-screen pt-1 slashn-img"
          />
          <div className="inline-flex text-white items-center">
            <div className="w-28 border bg-white white-border" />
            {/* <div className="text-lg text-center">is back </div> */}
            <div className="w-28 border bg-white white-border" />
          </div>
          {/* Elements  */}
          <img
            src="./assets/mainbanner_element_right.png"
            alt="banner mainbanner_element_right"
            className="absolute top-20 right-10 w-44 md:block hidden"
          />
          <img
            src="./assets/mainbanner_element_left.png"
            alt="banner mainbanner_element_left"
            className="absolute bottom-20 left-10 h-20 md:block hidden"
          />
          <div className="absolute md:bottom-10 bottom-20 flex flex-col items-center">
            <div className="text-white text-center coming-soon">
              {/* 16th February 2022 */}
              <img src="./assets/date.png" alt="date" className="coming-text" />
            </div>
            {/* <a href="https://slashn2022.flipkart.tech/">
              <div
                className=" flex justify-center button-bg"
                style={{
                  backgroundImage: "url(./assets/button-bg.png)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  width: 400,
                  // height: 90,
                  fontSize: 32,
                }}
                id="open"
                // onClick={() => setModalOpen(true)}
              >
                <div className="flex items-center justify-center text-white font-medium cursor-pointer rounded-full opacity-100 hover:opacity-70 ">
                  Login
                </div>
              </div>
            </a> */}

            <div
              className="text-gray-400 text-center text-sm"
              style={{ fontFamily: '"chalk", cursive' }}
            >
              echo "slashn" | grep -o. | while read i ; do spin; echo -ne "\b$i
              " ; done
            </div>
          </div>
        </div>

        {/* Modal container */}

        {modalOpen && (
          <div className="modal-container" id="modal_container">
            <div className="modal">
              <div
                className="absolute right-5 top-5 cursor-pointer rounded-full px-1.5 font-semibold"
                style={{ backgroundColor: "#2E3192", color: "#fff" }}
                id="close"
                onClick={() => setModalOpen(false)}
              >
                X
              </div>

              {/* <div className="grid grid-cols-2 gap-x-10 ">
                <div
                  className="text-white mx-auto w-40 text-center cursor-pointer"
                  style={{
                    backgroundColor: "#2E3192",
                    padding: "3px 15px",
                    borderRadius: 4,
                  }}
                >
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSeEa3Tlb72CO1-P8uitC47_9hmolDWoN9ct352V6tfaOPTWbw/viewform?usp=sf_link"
                    target="_blank"
                  >
                    Flipsters
                  </a>
                </div>
                <div
                  className="text-white mx-auto w-40 text-center cursor-pointer"
                  style={{
                    backgroundColor: "#2E3192",
                    padding: "3px 15px",
                    borderRadius: 4,
                  }}
                >
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLScXkWDsEinwgZjSqrZNpFSBt0b40PmC3k8O6I_vB3DpFXGxig/viewform?usp=sf_link"
                    target="_blank"
                  >
                    Non-Flipsters
                  </a>
                </div>
              </div> */}

              <div className="text-white text-center">
                <h1 className="text-2xl font-bold">Registrations Closed.</h1>
                <p className="text-sm mt-6">
                  Missed blocking a seat? Write to{" "}
                  <a
                    href="mailto:support@flipkartslashn.com"
                    className="hover:underline"
                  >
                    support@flipkartslashn.com
                  </a>{" "}
                  and join the waitlist. We will reach out to you.
                </p>
              </div>
            </div>
          </div>
        )}
      </section>
      {/* ------------------------------section two------------------------------------------- */}
      <section className="relative">
        <div
          className="absolute opacity-5 top-0 left-0 w-full h-full "
          style={{ backgroundImage: 'url("./assets/bg-pattern.png")' }}
        />
        <div className="container mx-auto md:px-10 py-16 pb-24 relative">
          <div className="relative flex flex-col items-center">
            <img
              className="md:w-2/3 lg:w-full"
              src="./assets/section2.svg"
              alt="section2 image vector"
            />
            <div
              className="absolute text-right md:text-2xl  text-sm text-white font-bold md:block xl:hidden"
              style={{ top: "30%", right: "23%" }}
            >
              Are you ready for slash n <br />
              Coz’ we sure are waiting for you!
            </div>
            <div
              className="absolute text-right md:text-xl text-xs text-white md:block xl:hidden"
              style={{ bottom: "10%", left: "20%", color: "#FEFD03" }}
            >
              Join us at the 2022 edition of slash n
            </div>
            <div
              className="absolute text-right md:text-2xl xl:text-6xl text-sm text-white font-bold xl:block hidden"
              style={{ top: "30%", right: "8%" }}
            >
              Are you ready for slash n <br />
              Coz’ we sure are waiting for you!
            </div>
            <div
              className="absolute text-right md:text-xl xl:text-3xl text-xs text-white xl:block hidden"
              style={{ bottom: "5%", left: "10%", color: "#FEFD03" }}
            >
              Join us at the 2022 edition of slash n
            </div>
          </div>
        </div>
        {/* About Section */}
        <div className="mx-auto  relative md:block hidden">
          <div className="container mx-auto px-10 py-16 pb-16">
            <div className="relative">
              <div className="text-right text-5xl font-bold text-white md:px-32 px-16">
                About Slash n
              </div>
              <img
                className
                style={{ marginTop: "-60px" }}
                src="./assets/underline.svg"
                alt="underline"
              />
              <div className=" text-light text-white pt-24 px-44 xl:w-4/5 pb-16">
                <strong>
                  Welcome to the 7th edition of Flipkart’s flagship tech
                  conference
                </strong>
                <br /> <br />
                In a coder’s parlance, slash n denotes a new line of code.
                <br />
                For Flipkart, slash n is the perfect platform for new thoughts
                and ideas to come alive and for us to live out our core belief
                that knowledge is meant to be shared, always!
                <br />
                <br />
                In its 6 prior editions, slash n has played host to a number of
                inspiring technology leaders and noted names from engineering
                and academia, along with the brightest minds at Flipkart to
                discuss, deliberate, and debate on all things tech in the realm
                of e-commerce.
                <br />
                <br />
                This year, slash n is being planned as a day-long event,
                dedicated to the theme
                <strong>“Intelligence @ scale”</strong>
                <br />
                <br />
                The vision is to have dynamic and powerful discussions centered
                around technology, draw insights from and see how they can be
                applied to solve real-world problems, foster learning and
                inspiration, and initiate conversations that matter.
                <br />
                <br />
                Catch a preview into prior editions of slashn n here below
              </div>
              <div className="flex items-center justify-center">
                <div
                  className="w-2/3"
                  style={{
                    border: "1px solid #5E2E92",
                    background: "#5E2E92",
                  }}
                />
              </div>
            </div>
          </div>
          <img
            src="./assets/section2_particle_left.svg"
            alt="banner mainbanner_element_left"
            className="absolute top-0 left-40 h-40"
          />
          <img
            src="./assets/about_particle_left.png"
            alt="banner mainbanner_element_left"
            className="absolute  w-32"
            style={{ top: "30%" }}
          />
          <img
            src="./assets/about_particle_right.png"
            alt="banner mainbanner_element_left"
            className="absolute right-20 md:w-44 xl:w-72"
            style={{ top: "30%" }}
          />
        </div>
        <div className="md:hidden container mx-auto">
          <div className="relative">
            <div className="text-right text-3xl font-medium text-white md:px-32 px-16">
              About Slash n
            </div>
            <img
              className
              style={{ marginTop: "-10px" }}
              src="./assets/underline.svg"
              alt="underline"
            />
            <img
              src="./assets/about_particle_left.png"
              alt="banner mainbanner_element_left"
              className="absolute  w-16"
              style={{ top: "30%" }}
            />
            <img
              src="./assets/about_particle_right.png"
              alt="banner mainbanner_element_left"
              className="absolute right-0 w-20"
              style={{ top: "70%" }}
            />
            <div className=" text-light text-white  px-10 py-16 relative">
              <strong>
                Welcome to the 7th edition of Flipkart’s flagship tech
                conference
              </strong>{" "}
              <br />
              <br />
              In a coder’s parlance, slash n denotes a new line of code.
              <br />
              For Flipkart, slash n is the perfect platform for new thoughts and
              ideas to come alive and for us to live out our core belief that
              knowledge is meant to be shared, always!
              <br />
              <br />
              In its 6 prior editions, slash n has played host to a number of
              inspiring technology leaders and noted names from engineering and
              academia, along with the brightest minds at Flipkart to discuss,
              deliberate, and debate on all things tech in the realm of
              e-commerce.
              <br />
              <br />
              This year, slash n is being planned as a day-long event, dedicated
              to the theme
              <strong>“Intelligence @ scale”</strong>
              <br />
              <br />
              The vision is to have dynamic and powerful discussions centered
              around technology, draw insights from and see how they can be
              applied to solve real-world problems, foster learning and
              inspiration, and initiate conversations that matter.
              <br />
              <br />
              Catch a preview into prior editions of slashn n here below
            </div>
            <div className="flex items-center justify-center">
              <div
                className="w-2/3"
                style={{ border: "1px solid #5E2E92", background: "#5E2E92" }}
              />
            </div>
            <img
              src="./assets/section2_particle_left.svg"
              alt="banner mainbanner_element_left"
              className="absolute top-0 left-0 h-28"
            />
          </div>
        </div>
        {/* Throughback */};
        <div className="container mx-auto px-10 py-10 md:pb-24 relative">
          <div className="flex items-center justify-center">
            <div
              className="text-center rounded-full inline-block text-white md:text-xl py-2 md:px-20 px-10"
              style={{
                background:
                  "linear-gradient(90deg, #2E3192 -0.01%, #662D91 99.99%)",
              }}
            >
              Throwback Videos
            </div>
          </div>
          <div className="relative w-full pt-16 md:px-20">
            <VideoSlider />
          </div>
        </div>
        {/* agenta */}
        <div>
          <div className="relative md:block hidden">
            <div className="container mx-auto px-10 py-10 pb-0 relative">
              <div className="relative">
                <div className="text-left text-5xl font-bold text-white md:px-32 px-16">
                  Agenda for slash n 2022
                </div>
                <br />
                <img
                  className
                  style={{ marginTop: "-60px" }}
                  src="./assets/underline.svg"
                  alt="underline"
                />
                <img
                  src="./assets/agentasearch.svg"
                  alt="search"
                  className="absolute top-0 left-per w-16"
                />
                <div className="text-lg md:px-32 px-16 text-white">
                  {/* Here is a preview of the action packed agenda for slash n
                  2022: */}
                  Here is a preview of the action packed agenda for slash n
                  2022. Click on the video icon beside each talk to view the
                  recording.
                  <br />
                  <br />
                  {/*   Stay tuned for the agenda for the 7th edition of slash n!
                  <br />
                  <br />
                  And while you are here, take a look at the action-packed
                  agenda we had last year.
                  <br />
                  <br />*/}
                </div>
                <div className="text-white md:px-32 px-16 ">
                  {/* <img src="./assets/agenta-day-1.png" alt="agenta-day-1" /> */}
                  {/* <img src="./assets/agenta-day-2.png" alt="agenta-day-2" /> */}
                  <Agenda />
                  {/* <SleekButton /> */}
                  {/* <VideoModal /> */}
                </div>
              </div>
            </div>
            <img
              src="./assets/agenta_particle_right.png"
              alt="search"
              className="absolute right-0 md:w-36 xl:w-56"
              style={{ top: 400 }}
            />
            <img
              src="./assets/agenta_notepad.png"
              alt="search"
              className="absolute right-10 md:w-20 xl:w-36"
              style={{ top: 700 }}
            />
            <img
              src="./assets/agenta_plus.png"
              alt="search"
              className="absolute right-10 md:w-20 xl:w-36"
              style={{ top: "50%" }}
            />
          </div>
          <div className="relative md:hidden">
            <img
              src="./assets/agenta_particle_right.png"
              alt="search"
              className="absolute right-0 w-24"
              style={{ top: "30%" }}
            />
            <img
              src="./assets/agenta_notepad.png"
              alt="search"
              className="absolute right-0 w-12"
              style={{ top: "50%" }}
            />
            <img
              src="./assets/agenta_plus.png"
              alt="search"
              className="absolute right-10 w-12"
              style={{ top: "80%" }}
            />
            <div className="container mx-auto px-10 py-10 relative">
              <div className="relative">
                <div className="text-left text-3xl font-medium text-white md:px-32 px-16">
                  Agenda for slash n 2022
                </div>
                <img
                  className
                  style={{ marginTop: "-10px" }}
                  src="./assets/underline.svg"
                  alt="underline"
                />
                <img
                  src="./assets/agentasearch.svg"
                  alt="search"
                  className="absolute top-0 left-60 w-12"
                />
                <div className="text-lg text-white  px-4">
                  {/* Here is a preview of the action packed agenda for slash n
                  2022: */}
                  Here is a preview of the action packed agenda for slash n
                  2022. Click on the video icon beside each talk to view the
                  recording.
                  <br />
                  <br />
                  {/* Stay tuned for the agenda for the 7th edition of slash n!
                  <br />
                  <br />
                  And while you are here, take a look at the action-packed
                  agenda we had last year.*/}
                </div>
                <div className="text-white">
                  {/* <img src="./assets/agenta-day-1m.png" alt="agenta-day-1" /> */}
                  <Agenda />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ------------------------------speakerSection-------------------------------------- */}
      <section className="pt-20">
        <div>
          <div className="relative md:block hidden ">
            <img
              src="./assets/speaker_particle_left.png"
              alt="search"
              className="absolute left-5"
              style={{ top: "0%", width: 370 }}
            />
            <div className="container mx-auto px-10 py-10  relative">
              <div className="relative">
                <div className="text-right md:text-5xl text-3xl font-bold text-white md:px-32 px-16">
                  Speakers for slash n 2022
                </div>
                <img
                  className="absolute"
                  style={{ top: "-10%" }}
                  src="./assets/underline.svg"
                  alt="underline"
                />
              </div>
            </div>
            <div className="container text-right xl:text-xl text-lg md:px-40 px-16 text-white pt-16">
              Click on the speaker profiles to learn more.
            </div>
            <img
              src="./assets/speakers.png"
              alt="search"
              className="absolute md:left-16 xl:left-16 md:w-80 xl:w-96"
              style={{ top: "0%" }}
            />
            <div className="container mx-auto px-10 py-10 pb-28 relative">
              <div className="grid lg:grid-cols-4 grid-cols-2 gap-6 pt-10">
                {/* ---------------new------- */}
                <div
                  data-modal="#speaker1001"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker1001")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/debjani.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Debjani Ghosh</div>
                  <div className="text-center">
                    President, National Association of Software & Services
                    Companies (NASSCOM)
                  </div>
                </div>
                {/* -------------------- 100 -------------------- */}

                <div
                  data-modal="#speaker100"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker100")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Pramod_Varma.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Pramod Verma</div>
                  <div className="text-center">
                    CTO, EkStep Foundation, <br /> Chief Architect Aadhaar &
                    India Stack, <br />
                    Co-founder Beckn Foundation
                  </div>
                </div>

                {/* -------------------- 101 -------------------- */}

                <div
                  data-modal="#speaker101"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker101")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Pranav_Tiwari.jpeg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Pranav Tiwari</div>
                  <div className>CTO, nurture.farm</div>
                </div>

                {/* -------------------- 102 -------------------- */}

                <div
                  data-modal="#speaker102"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker102")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Lalitesh-Katragadda.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Lalitesh Katragadda</div>
                  <div className>Founder, Indihood & Google Map Maker</div>
                </div>

                {/* -------------------- 103 -------------------- */}

                <div
                  data-modal="#speaker103"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker103")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Umakant_Soni.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Umakanth Soni</div>
                  <div className>Co-Founder & CEO, ArtPark</div>
                </div>

                {/* -------------------- 104 -------------------- */}

                <div
                  data-modal="#speaker104"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker104")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/rimjhim.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Rhimjhim Ray</div>
                  <div className>Co-Founder, Spotle.ai</div>
                </div>

                {/* -------------------- 105 -------------------- */}

                <div
                  data-modal="#speaker105"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker105")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Bharath_Ram.jfif"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Bharath Ram</div>
                  <div className>
                    Product Head - Growth & Retention, Flipkart
                  </div>
                </div>
                {/* ----------------------------1005---------------------------------- */}
                <div
                  data-modal="#speaker1005"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker1005")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/sriram.png"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Sriram Sankar</div>
                  <div className>Chief Architect, Anyscale, Inc.</div>
                </div>

                {/* -------------------- 0 -------------------- */}

                <div
                  data-modal="#speaker0"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker0")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Jey.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Jeyandran Venugopal</div>
                  <div className>CPTO, Flipkart</div>
                </div>
                {/* -------------------- 1 -------------------- */}

                <div
                  data-modal="#speaker1"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker1")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/speaker1.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Utkarsh B</div>
                  <div className>Chief Architect, Flipkart</div>
                </div>

                <div
                  data-modal="#speaker1006"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker1006")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/kalyan.png"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Kalyan Akella</div>
                  <div className>Staff Software Engineer, Rubrik India</div>
                </div>

                <div
                  data-modal="#speaker1007"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker1007")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/janmejay.png"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Janmejay Singh</div>
                  <div className>Member Of Technical Staff at Rubrik, Inc.</div>
                </div>

                {/* -------------------- 2 -------------------- */}

                <div
                  data-modal="#speaker2"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker2")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Nikesh_Garera.png"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Nikesh Garera</div>
                  <div className>Princpal Data Scientist, Flipkart</div>
                </div>

                {/* -------------------- 3 -------------------- */}

                <div
                  data-modal="#speaker3"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker3")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Anantharam_Vanchiprakash.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">
                    Anantharam Vanchiprakash
                  </div>
                  <div className>Senior Architect, Flipkart</div>
                </div>

                {/* -------------------- 4 -------------------- */}

                <div
                  data-modal="#speaker4"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker4")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Gaurav_Prasad.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Gaurav Prasad</div>
                  <div className>Architect, Flipkart</div>
                </div>

                {/* -------------------- 5 -------------------- */}

                <div
                  data-modal="#speaker5"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker5")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Sudhir-Reddy-Flipkart.png"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Sudhir Reddy</div>
                  <div className>Senior Principal Architect, Flipkart</div>
                </div>

                {/* -------------------- 6 -------------------- */}

                <div
                  data-modal="#speaker6"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker6")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Rahul_Agrawal.jpeg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Rahul Agrawal</div>
                  <div className>Architect, Flipkart</div>
                </div>

                {/* -------------------- 7 -------------------- */}

                <div
                  data-modal="#speaker7"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker7")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Shyam_Akirala.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Shyam Akirala</div>
                  <div className>Software Developer, Flipkart</div>
                </div>

                {/* -------------------- 8 -------------------- */}

                <div
                  data-modal="#speaker8"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker8")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Rajesh_Kannan.jpeg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Rajesh Kannan S</div>
                  <div className>Software Developer, Flipkart</div>
                </div>

                {/* -------------------- 9 -------------------- */}

                <div
                  data-modal="#speaker9"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker9")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Amrith_Shroff.jpeg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Amrith Shroff</div>
                  <div className>Software Developer, Flipkart</div>
                </div>

                {/* -------------------- 10 -------------------- */}

                <div
                  data-modal="#speaker10"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker10")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Pradeep_Nain.jpeg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Pradeep Nain</div>
                  <div className>Software Developer, Flipkart</div>
                </div>

                {/* -------------------- 11 -------------------- */}

                <div
                  data-modal="#speaker11"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker11")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Mallikarjun.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Mallikarjun V</div>
                  <div className>Software Developer, Flipkart</div>
                </div>

                {/* -------------------- 12 -------------------- */}

                <div
                  data-modal="#speaker12"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker12")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Anand - PP.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Anand Immannavar</div>
                  <div className>Senior Architect, Flipkart</div>
                </div>

                {/* -------------------- 13 -------------------- */}

                <div
                  data-modal="#speaker13"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker13")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Kinshuk_Bairagi.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Kinshuk Bairagi</div>
                  <div className>Architect, Flipkart</div>
                </div>

                {/* -------------------- 14 -------------------- */}

                <div
                  data-modal="#speaker14"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker14")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Kaustav _Das.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Kaustav Das</div>
                  <div className>Software Developer, Flipkart</div>
                </div>

                {/* -------------------- 15 -------------------- */}

                <div
                  data-modal="#speaker15"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker15")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Varsha_Saha.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Varsha Saha</div>
                  <div className>Tech Lead - Software Engineer, Flipkart</div>
                </div>

                {/* -------------------- 16 -------------------- */}

                <div
                  data-modal="#speaker16"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker16")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Govil_Khatri.png"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Govil Khatri</div>
                  <div className>Software Developer, Flipkart</div>
                </div>

                {/* -------------------- 17 -------------------- */}

                <div
                  data-modal="#speaker17"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker17")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/IMG-0181.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Jain Johny</div>
                  <div className>Architect, Flipkart</div>
                </div>

                {/* -------------------- 18 -------------------- */}

                <div
                  data-modal="#speaker18"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker18")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Abhishek_Tyagi.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Abhishek Tyagi</div>
                  <div className>Architect, Flipkart</div>
                </div>

                {/* -------------------- 19 -------------------- */}

                <div
                  data-modal="#speaker19"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker19")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Rahul_Pradeep.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Rahul Pradeep</div>
                  <div className>Software Developer, Flipkart</div>
                </div>

                {/* -------------------- 20 -------------------- */}

                <div
                  data-modal="#speaker20"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker20")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Ruchika_Kundu.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Ruchika Kundu</div>
                  <div className>Software Developer, Flipkart</div>
                </div>

                {/* -------------------- 21 -------------------- */}

                <div
                  data-modal="#speaker21"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker21")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Sarthak_Agarwal.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Sarthak Agarwal</div>
                  <div className>Software Developer, Flipkart</div>
                </div>

                {/* -------------------- 22 -------------------- */}

                <div
                  data-modal="#speaker22"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker22")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Anuj_Mittal.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Anuj Mittal</div>
                  <div className>Senior Architect, Flipkart</div>
                </div>

                {/* -------------------- 23 -------------------- */}

                <div
                  data-modal="#speaker23"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker23")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Sunil_Kumar.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Sunil Kumar</div>
                  <div className>Director Engineering, Flipkart</div>
                </div>

                {/* -------------------- 24 -------------------- */}

                <div
                  data-modal="#speaker24"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker24")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Robin.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Robin KC</div>
                  <div className>Senior Architect, Flipkart</div>
                </div>

                {/* 25 */}
                <div
                  data-modal="#speaker25"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker25")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/md-mehrab.jpeg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Md Mehrab Alam</div>
                  <div className>Software Developer, Flipkart</div>
                </div>
                {/*  */}
                {/* 26 */}
                <div
                  data-modal="#speaker26"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker26")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/sachin-sinivasan.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Sachin Srinivasan</div>
                  <div className>Software Developer, Flipkart Camera</div>
                </div>
                {/*  */}
                {/* 27 */}
                <div
                  data-modal="#speaker27"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker27")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/ajay-venketsha.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Ajay Venkatesha</div>
                  <div className>
                    Senior Engineering Manager, Flipkart Camera,
                    <br /> (previously Co-Founder, Scapic)
                  </div>
                </div>
                {/*  */}
                {/* 28 */}
                <div
                  data-modal="#speaker28"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker28")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/ayush-agarwal.jpeg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Ayush Agarwal</div>
                  <div className>Software Developer, Flipkart</div>
                </div>
                {/*  */}
                {/* 29 */}
                <div
                  data-modal="#speaker29"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker29")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/avinash-vundyala.jpeg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Avinash Vundyala</div>
                  <div className>Software Developer, Flipkart</div>
                </div>
                {/*  */}
                {/* 30 */}
                <div
                  data-modal="#speaker30"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker30")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/vikasmishra.png"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Vikas Mishra</div>
                  <div className>Software Developer, Flipkart</div>
                </div>
                {/*  */}

                {/* -------------------------------------------- */}
              </div>
              <img
                src="./assets/mainbanner_element_right.png"
                alt="banner mainbanner_element_right"
                className="absolute bottom-0 right-20 w-44"
              />
            </div>
          </div>
          <div className="relative md:hidden ">
            <img
              src="./assets/speaker_particle_left.png"
              alt="search"
              className="absolute left-0"
              style={{ top: "0%", width: 300 }}
            />
            <div className="container mx-auto px-10 py-10  relative">
              <div className="relative">
                <div className="text-right text-3xl font-medium text-white md:px-32 px-10">
                  Speakers for slash n 2022
                </div>
                <img
                  className
                  style={{ marginTop: "-10px" }}
                  src="./assets/underline.svg"
                  alt="underline"
                />
              </div>
            </div>
            <div className="container text-center xl:text-xl text-lg md:px-40 px-16 text-white pt-16 relative">
              Click on the speaker profiles to learn more.
            </div>
            <img
              src="./assets/speakers.png"
              alt="search"
              className="absolute left-0 w-48"
              style={{ top: "-2%" }}
            />
            <div className="container mx-auto px-10 py-10 pb-28 relative">
              <div className="grid lg:grid-cols-4 grid-cols-2 gap-6 pt-10">
                {/* ------------------new--------------- */}
                <div
                  data-modal="#speaker1001"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker1001")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/debjani.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Debjani Ghosh</div>
                  <div className="text-center">
                    President, National Association of Software & Services
                    Companies (NASSCOM)
                  </div>
                </div>

                {/* ------------------ 100 -------------------- */}

                <div
                  data-modal="#speaker100"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker100")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Pramod_Varma.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Pramod Verma</div>
                  <div className="text-center">
                    CTO, EkStep Foundation, <br /> Chief Architect Aadhaar &
                    India Stack, <br />
                    Co-founder Beckn Foundation
                  </div>
                </div>

                {/* ------------------ 101 -------------------- */}

                <div
                  data-modal="#speaker101"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker101")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Pranav_Tiwari.jpeg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Pranav Tiwari</div>
                  <div className>CTO, nurture.farm</div>
                </div>
                {/* ------------------ 102 -------------------- */}

                <div
                  data-modal="#speaker102"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker102")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Lalitesh-Katragadda.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Lalitesh Katragadda</div>
                  <div className>Founder, Indihood & Google Map Maker</div>
                </div>
                {/* ------------------ 103 -------------------- */}

                <div
                  data-modal="#speaker103"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker103")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Umakant_Soni.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Umakanth Soni</div>
                  <div className>Co-Founder & CEO, ArtPark</div>
                </div>
                {/* ------------------ 104-------------------- */}

                <div
                  data-modal="#speaker104"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker104")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/rimjhim.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Rhimjhim Ray</div>
                  <div className>Co-Founder, Spotle.ai</div>
                </div>
                {/* ------------------ 105 -------------------- */}

                <div
                  data-modal="#speaker105"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker105")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Bharath_Ram.jfif"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Bharath Ram</div>
                  <div className>
                    Product Head - Growth & Retention, Flipkart
                  </div>
                </div>
                {/* ----------------------1005--------------- */}
                <div
                  data-modal="#speaker1005"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker1005")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/sriram.png"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Sriram Sankar</div>
                  <div className>Chief Architect, Anyscale, Inc.</div>
                </div>
                {/* ------------------ 0 -------------------- */}

                <div
                  data-modal="#speaker0"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker0")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Jey.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Jeyandran Venugopal </div>
                  <div className>CPTO, Flipkart</div>
                </div>

                {/* ------------------ 1 -------------------- */}

                <div
                  data-modal="#speaker1"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker1")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/speaker1.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Utkarsh B</div>
                  <div className>Chief Architect, Flipkart</div>
                </div>

                <div
                  data-modal="#speaker1006"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker1006")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/kalyan.png"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Kalyan Akella</div>
                  <div className>Staff Software Engineer, Rubrik India</div>
                </div>

                <div
                  data-modal="#speaker1007"
                  className="flex flex-col items-center space-y-2 w-full text-white cursor-pointer"
                  onClick={() => setSpeakerModal("speaker1007")}
                >
                  <div className="w-40 h-40 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/janmejay.png"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Janmejay Singh</div>
                  <div className>Member Of Technical Staff at Rubrik, Inc.</div>
                </div>

                {/* ------------------ 2 -------------------- */}

                <div
                  data-modal="#speaker2"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker2")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Nikesh_Garera.png"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Nikesh Garera</div>
                  <div className>Princpal Data Scientist, Flipkart</div>
                </div>

                {/* ------------------ 3 -------------------- */}

                <div
                  data-modal="#speaker3"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker3")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Anantharam_Vanchiprakash.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">
                    Anantharam Vanchiprakash
                  </div>
                  <div className>Senior Architect, Flipkart</div>
                </div>

                {/* ------------------ 4 -------------------- */}

                <div
                  data-modal="#speaker4"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker4")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Gaurav_Prasad.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Gaurav Prasad</div>
                  <div className>Architect, Flipkart</div>
                </div>

                {/* ------------------ 5 -------------------- */}

                <div
                  data-modal="#speaker5"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker5")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Sudhir-Reddy-Flipkart.png"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Sudhir Reddy</div>
                  <div className>Senior Principal Architect, Flipkart</div>
                </div>

                {/* ------------------ 6 -------------------- */}

                <div
                  data-modal="#speaker6"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker6")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Rahul_Agrawal.jpeg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Rahul Agrawal</div>
                  <div className>Architect, Flipkart</div>
                </div>

                {/* ------------------ 7 -------------------- */}

                <div
                  data-modal="#speaker7"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker7")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Shyam_Akirala.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Shyam Akirala</div>
                  <div className>Software Developer, Flipkart</div>
                </div>

                {/* ------------------ 8 -------------------- */}

                <div
                  data-modal="#speaker8"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker8")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Rajesh_Kannan.jpeg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Rajesh Kannan S</div>
                  <div className>Software Developer, Flipkart</div>
                </div>

                {/* ------------------ 9 -------------------- */}

                <div
                  data-modal="#speaker9"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker9")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Amrith_Shroff.jpeg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Amrith Shroff</div>
                  <div className>Software Developer, Flipkart</div>
                </div>

                {/* ------------------ 10 -------------------- */}

                <div
                  data-modal="#speaker10"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker10")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Pradeep_Nain.jpeg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Pradeep Nain</div>
                  <div className>Software Developer, Flipkart</div>
                </div>

                {/* ------------------ 11 -------------------- */}

                <div
                  data-modal="#speaker11"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker11")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Mallikarjun.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Mallikarjun V</div>
                  <div className>Software Developer, Flipkart</div>
                </div>

                {/* ------------------ 12 -------------------- */}

                <div
                  data-modal="#speaker12"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker12")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Anand - PP.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Anand Immannavar</div>
                  <div className>Senior Architect, Flipkart</div>
                </div>

                {/* ------------------ 13 -------------------- */}

                <div
                  data-modal="#speaker13"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker13")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Kinshuk_Bairagi.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Kinshuk Bairagi</div>
                  <div className>Architect, Flipkart</div>
                </div>

                {/* ------------------ 14 -------------------- */}

                <div
                  data-modal="#speaker14"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker14")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Kaustav _Das.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Kaustav Das</div>
                  <div className>Software Developer, Flipkart</div>
                </div>

                {/* ------------------ 15 -------------------- */}

                <div
                  data-modal="#speaker15"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker15")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Varsha_Saha.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Varsha Saha</div>
                  <div className>Tech Lead - Software Engineer, Flipkart</div>
                </div>

                {/* ------------------ 16 -------------------- */}

                <div
                  data-modal="#speaker16"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker16")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Govil_Khatri.png"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Govil Khatri</div>
                  <div className>Software Developer, Flipkart</div>
                </div>

                {/* ------------------ 17 -------------------- */}

                <div
                  data-modal="#speaker17"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker17")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/IMG-0181.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Jain Johny</div>
                  <div className>Architect, Flipkart</div>
                </div>

                {/* ------------------ 18 -------------------- */}

                <div
                  data-modal="#speaker18"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker18")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Abhishek_Tyagi.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Abhishek Tyagi</div>
                  <div className>Architect, Flipkart</div>
                </div>

                {/* ------------------ 19 -------------------- */}

                <div
                  data-modal="#speaker19"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker19")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Rahul_Pradeep.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Rahul Pradeep</div>
                  <div className>Software Developer, Flipkart</div>
                </div>

                {/* ------------------ 20 -------------------- */}

                <div
                  data-modal="#speaker20"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker20")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Ruchika_Kundu.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Ruchika Kundu</div>
                  <div className>Software Developer, Flipkart</div>
                </div>

                {/* ------------------ 21 -------------------- */}

                <div
                  data-modal="#speaker21"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker21")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Sarthak_Agarwal.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Sarthak Agarwal</div>
                  <div className>Software Developer, Flipkart</div>
                </div>

                {/* ------------------ 22 -------------------- */}

                <div
                  data-modal="#speaker22"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker22")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Anuj_Mittal.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Anuj Mittal</div>
                  <div className>Senior Architect, Flipkart</div>
                </div>

                {/* ------------------ 23 -------------------- */}

                <div
                  data-modal="#speaker23"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker23")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Sunil_Kumar.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Sunil Kumar</div>
                  <div className>Director Engineering, Flipkart</div>
                </div>

                {/* ------------------ 24 -------------------- */}

                <div
                  data-modal="#speaker24"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker24")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/Robin.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Robin KC</div>
                  <div className>Senior Architect, Flipkart</div>
                </div>

                {/* --------------------------------------------- */}
                {/* 25 */}
                <div
                  data-modal="#speaker25"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker25")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/md-mehrab.jpeg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Md Mehrab Alam</div>
                  <div className>Software Developer, Flipkart</div>
                </div>
                {/*  */}
                {/* 26 */}
                <div
                  data-modal="#speaker26"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker26")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/sachin-sinivasan.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Sachin Srinivasan</div>
                  <div className>Software Developer, Flipkart Camera</div>
                </div>
                {/*  */}
                {/* 27 */}
                <div
                  data-modal="#speaker27"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker27")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/ajay-venketsha.jpg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Ajay Venkatesha</div>
                  <div className>
                    Senior Engineering Manager, Flipkart Camera,
                    <br /> (previously Co-Founder, Scapic)
                  </div>
                </div>
                {/*  */}
                {/* 28 */}
                <div
                  data-modal="#speaker28"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker28")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/ayush-agarwal.jpeg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Ayush Agarwal</div>
                  <div className>Software Developer, Flipkart</div>
                </div>
                {/*  */}
                {/* 29 */}
                <div
                  data-modal="#speaker29"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker29")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/avinash-vundyala.jpeg"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Avinash Vundyala</div>
                  <div className>Software Developer, Flipkart</div>
                </div>
                {/*  */}
                {/* 30 */}
                <div
                  data-modal="#speaker30"
                  className="flex flex-col items-center space-y-2 w-full text-white text-center cursor-pointer"
                  onClick={() => setSpeakerModal("speaker30")}
                >
                  <div className="w-36 h-36 rounded-full border border-4 border-indigo-500">
                    <img
                      className="w-full h-full object-cover rounded-full"
                      src="./assets/speakers/vikasmishra.png"
                      alt="avatar"
                    />
                  </div>
                  <div className="font-bold text-lg">Vikas Mishra</div>
                  <div className>Software Developer, Flipkart</div>
                </div>
                {/*  */}
              </div>
              <img
                src="./assets/mainbanner_element_right.png"
                alt="banner mainbanner_element_right"
                className="absolute bottom-0 right-20 w-44"
              />
            </div>
          </div>

          {/* speaker modal */}

          {/* -----------------------------------------100------------------------------------------ */}

          {speakerModal === "speaker100" && (
            <div
              id="speaker100"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal  overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Pramod_Varma.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Pramod Verma
                    </div>
                    <div className="text-md text-center w-full">
                      CTO, EkStep Foundation, <br /> Chief Architect Aadhaar &
                      India Stack, <br />
                      Co-founder Beckn Foundation
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="http://linkedin.com/in/pramodkvarma"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Dr. Pramod Varma is the CTO of EkStep Foundation, a
                    not-for-profit creating learner centric, open source,
                    digital public goods under Project Sunbird (sunbird.org).
                    These digital building blocks are used to provide learning
                    opportunities to 200 million children in India and also in
                    other learning, skilling, and capacity building efforts in
                    India and other countries. In addition, he continues to be
                    the Chief Architect of Aadhaar, India's digital identity
                    program that has successfully covered more than 1.2 billion
                    people in a short span of 7 years. He also helped architect
                    various India Stack layers such as eSign, Digital Locker,
                    and Unified Payment Interface (UPI), and Data Empowerment
                    and Protection Architecture (DEPA), all of which are now
                    working at population scale in India. He is also the
                    co-founder of Beckn Foundation, the genesis author and angel
                    donor of the open source beckn protocol (becknprotocol.io)
                    for creation of decentralized open discovery, fulfillment,
                    and commerce networks.
                    <br /> <br /> He is an advisor to Unique Identification
                    Authority of India (UIDAI), National Payment Corporation
                    (NPCI), Goods and Services Tax Network (GSTN), National
                    Health Authority (NHA), Securities and Exchange Board of
                    India (SEBI), Turing Institute Identity Initiative in the
                    UK, and helps with many digital public infrastructure
                    initiatives in India and across the globe. Pramod holds a
                    Master’s and Ph.D. degree in Computer Science along with a
                    second Master’s in Applied Mathematics. His interests
                    include Internet scale distributed architectures and
                    intelligent systems. He is passionate about technology,
                    science, society, and teaching.
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          )}

          {speakerModal === "speaker1001" && (
            <div
              id="speaker1001"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal  overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/debjani.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Debjani Ghosh
                    </div>
                    <div className="text-md text-center w-full">
                      President, National Association of Software & Services
                      Companies (NASSCOM)
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/debjani-ghosh-48298b1/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Debjani Ghosh is the President of the National Association
                    of Software & Services Companies (NASSCOM) since April 2018.
                    A veteran of the technology industry, she is the fifth
                    president of NASSCOM and the first women at the helm in
                    three-decade history. She is a firm believer in the power of
                    technology in enhancing lives and livelihoods, leveraging it
                    for societal good. She was instrumental in developing ‘Think
                    Digital, Think India’ strategy aimed at establishing India
                    as a hub for digital talent and innovation. Debjani strongly
                    believes technology will have the most impact on humankind
                    when it’s used as an enabler of inclusive growth and that
                    India must lead in the digital era with focus on the
                    responsible build and use of tech as an accelerator and
                    equalizer.
                    <br /> <br /> As the NASSCOM President, she actively works
                    with the industry and Government to accelerate the growth of
                    the Industry and strengthen India’s position as hub for
                    Innovation and Digital Talent. Her approach has been focused
                    on a three-point agenda - talent development and
                    re-skilling, strengthening the innovation culture in the
                    country and collaborating with governments across the world
                    to open new opportunities for the Indian IT industry
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------101------------------------------------------ */}

          {speakerModal === "speaker101" && (
            <div
              id="speaker101"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal  overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Pranav_Tiwari.jpeg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Pranav Tiwari
                    </div>
                    <div className="text-md text-center w-full">
                      CTO, nurture.farm
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/pranav-tiwari-3204085/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Pranav is the CTO of nurture.farm, founded in Jan 2020. He
                    leads the creation of a platform that digitises farming
                    lifecycle, bringing mechanization and technology led
                    solutions to all farmers. He past work has focussed on
                    building tech products in multiple domains for 25+ years. He
                    holds 40+ patents in Distributed Computing, Networking and
                    Information Processing. In his past roles, he was the head
                    of Technology at Ola, the CTO at Cellworks Group Inc and the
                    Head of Engineering at Google India. <br />
                    <br /> He holds a Masters degree in Computer Science from
                    North Carolina State University, Raleigh, and a Bachelors in
                    Technology (Computer Science) from the Indian Institute of
                    Technology, Delhi.
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------102------------------------------------------ */}

          {speakerModal === "speaker102" && (
            <div
              id="speaker102"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal  overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Lalitesh-Katragadda.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Lalitesh Katragadda
                    </div>
                    <div className="text-md text-center w-full">
                      Founder, Indihood and Google Map Maker
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/lalitesh-katragadda-aa37224/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    He is the Founder of Indihood, and creator of Google Map
                    Maker. <br />
                    <br /> Indihood is a crowdsourcing platform making social
                    good, by engineering information systems for the billion of
                    unserved Indians. Their vision is a world in which the
                    average Indian can use the Internet and find a solution to
                    their most basic livelihood problems. <br />
                    <br />
                    At Google, Lalitesh co-founded Google India and was its
                    founding Joint Center Head for two years.
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------103------------------------------------------ */}

          {speakerModal === "speaker103" && (
            <div
              id="speaker103"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal  overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Umakant_Soni.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Umakant Soni
                    </div>
                    <div className="text-md text-center w-full">
                      Co-Founder and CEO, ArtPark
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/soniumakant/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Co-founder & CEO ARTPARK(AI & Robotics Technology Park),
                    Building the AI mothership for billions of users in India;
                    India’s nominate expert for Innovation and Commercialization
                    working group, Global Partnership on AI (GPAI), Chairman, AI
                    Foundry, Co-founded India's first AI fund (pi Ventures,
                    $32mn) and backed some of best AI companies in
                    India(Sigtuple, Niramai & others)
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------104------------------------------------------ */}

          {speakerModal === "speaker104" && (
            <div
              id="speaker104"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal  overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/rimjhim.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Rhimjhim Ray
                    </div>
                    <div className="text-md text-center w-full">
                      Co-Founder, Spotle.ai
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/rimjhimray/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Rimjhim is the Co-founder at Spotle.ai, India’s largest AI
                    powered career platform for millenials. <br />
                    <br /> Rimjhim is also an executive director and investor at
                    social impact edtech startup Wise Owl Learning. She is a
                    serial entrepreneur who also co-founded, sold and exited
                    Unmarketeer - a digital-advisory firm. Rimjhim holds a
                    degree in Computer Science and studied MBA at Imperial
                    Business School, London and SPJIMR, Mumbai. <br />
                    <br /> Rimjhim has been awarded with the top 30 women tech
                    entrepreneur award in India She has been recognized as a
                    ‘Woman of influence’ and one of the top 50 technology voices
                    on Twitter India. Her debut book ‘7 Women 7 Secrets’
                    published in 2017 has been a Times Books best seller.`
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------105------------------------------------------ */}

          {speakerModal === "speaker105" && (
            <div
              id="speaker105"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal  overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Bharath_Ram.jfif"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Bharath Ram
                    </div>
                    <div className="text-md text-center w-full">
                      Product Head - Growth & Retention, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/rbharathram/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Bharath heads Lead Product, Engineering & Design at Flipkart
                    for the last 2 years. He primarily focuses on user Growth &
                    Retention (Plus program, Coins, Videos, Games,
                    Social/Conversational commerce) for Grocery/Travel category
                    products. He has also been one of the pioneers for
                    Storefront/App Engineering and heads the design for Flipkart
                    App
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          )}

          {speakerModal === "speaker1005" && (
            <div
              id="speaker1005"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal  overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/sriram.png"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Sriram Sankar
                    </div>
                    <div className="text-md text-center w-full">
                      Chief Architect, Anyscale, Inc.
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/sriramxsankar/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Sriram is currently the Chief Architect at Anyscale, a
                    company that supports the open-source Ray platform that
                    simplifies the development of distributed systems
                    applications.
                    <br />
                    He started his career in developer tools (compilers,
                    debuggers, IDEs, testing strategies, etc.). During this
                    phase of his career he built JavaCC, a parser generator for
                    Java, and founded Metamata where he built Java static
                    analysis tools and a Java debugger. He shifted focus to
                    search engines approximately 15 years ago, and built the
                    search engines in use at Meta (Facebook), LinkedIn, and
                    Uber.
                    <br />
                    Sriram was part of the first batch of BTech Computer Science
                    students at IIT Kanpur (graduating in 1983), and then got a
                    PhD in Computer Science from Stanford in 1989.
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------0------------------------------------------ */}

          {speakerModal === "speaker0" && (
            <div
              id="speaker0"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal  overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Jey.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Jeyandran Venugopal
                    </div>
                    <div className="text-md text-center w-full">
                      CPTO, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/jeyandran/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Jeyandran Venugopal is CPTO at Flipkart. In this role, he is
                    tasked to strengthen the team, tech investments and help
                    implement new technologies with a specific focus on building
                    future capabilities across product and engineering.
                    <br />
                    <br />
                    Prior to this, Jey was the CTO at Myntra. As CTO, he lead
                    the Technology, IT and Infrastructure teams at Myntra. He
                    focused on leading a highly talented engineering
                    organization to deliver exciting innovation, execution and
                    engineering excellence charter for Myntra. He was tasked
                    with finding ways to constantly delight Myntra customers
                    with break-through products. In addition to being the CTO,
                    he had a brief stint as a CPO.
                    <br />
                    <br />
                    Prior to this, Jey has donned many roles in the consumer
                    internet space as a technologist across both, startups and
                    big companies, and in both US and India markets, for 17+
                    years. Jey started his career at Amazon.com in Seattle as a
                    Software Engineer and built and managed several e-commerce
                    backend systems. Later, he moved to India as a founding
                    member of Amazon's India Development Center at Bangalore. As
                    part of the India leadership team, he helped setup and scale
                    the India center for Amazon and worked in Amazon's Web
                    Services division building API products at this time. After
                    this, Jey joined Yahoo's India R&D center and helped
                    establish the Search Engineering division and later led
                    several platform products for Yahoo such as the content
                    management platform, video platforms, Geolocation Services
                    and others. As a Global/Corporate VP of Engineering and as a
                    part of the core leadership team at Yahoo, Jey was part of
                    the administrative leadership team for the India center.
                    Post this, Jey was the Founder and CEO of a digital
                    healthcare startup (EClinic 24/7) and raised funding and
                    built a product that won industry accolades including as the
                    Nasscom Emerge 50 Award.
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------1------------------------------------------ */}

          {speakerModal === "speaker1" && (
            <div
              id="speaker1"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal  overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/speaker1.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Utkarsh B
                    </div>
                    <div className="text-md text-center w-full">
                      Chief Architect, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/utkarsh-b-b338821/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    An experienced technologist, architect and mentor in
                    applying technology relevantly to address critical business
                    challenges. Extensive experience of over 20+ years in
                    developing fault tolerant and scalable internet scale
                    systems across a range of technology and business domains.
                    <br />
                    <br />
                    Currently anchoring technology and architecture strategies
                    for the organization while enabling tech and product teams
                    to make aligned technology and architecture decisions.
                    Driving end to end architecture coherence, delivering
                    reusable technologies and investments in fault-tolerant and
                    resilient architectures - with technology leading the
                    business growth at the necessitated agility.
                    <br />
                    <br />
                    Passionate about building evolvable architectures and
                    nurturing highly motivated and performant engineering teams.
                    <br />
                    <br />
                    <strong className="text-xl">Specialties:</strong>
                    Internet scale distributed architectures and design,
                    Business Continuity and Synchronous Disaster Recovery,
                    Service Oriented Architecture, Distributed Computing,
                    Scalable data storage, Data and Information Security,
                    Systems optimisation, BigData, Site reliability,
                    Architectural Governance, Evolvable architectures
                  </div>
                </div>
              </div>
            </div>
          )}

          {speakerModal === "speaker1006" && (
            <div
              id="speaker1006"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal  overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/kalyan.png"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Kalyan Akella
                    </div>
                    <div className="text-md text-center w-full">
                      Staff Software Engineer, Rubrik India
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/kalyan-akella-14053283/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Kalyan has been engineering highly available and scalable
                    distributed systems for close to two decades. He is
                    currently working as Staff Software Engineer at Rubrik
                    India, working on turn key backup, recovery and data
                    security solutions for managed databases across AWS, Azure
                    and GCP. Previously, he was with Flipkart where he worked on
                    transitioning large footprints of their tech stack to be
                    multi-DC capable, tuned for high availability and
                    scalability. He is also one of the founding engineers of an
                    open source, distributed KV store known as DKV, which is
                    what Kalyan will be co-presenting in this edition of SlashN.
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          )}
          {speakerModal === "speaker1007" && (
            <div
              id="speaker1007"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal  overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/janmejay.png"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Janmejay Singh
                    </div>
                    <div className="text-md text-center w-full">
                      Member Of Technical Staff at Rubrik, Inc.
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/janmejay-singh"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Janmejay is a hacker at heart and is deeply interested in
                    distributed-systems, performance, scalability and all sorts
                    of hard technical problems in general. He has his head
                    buried in the metadata-store at Rubrik these days, wrangling
                    the problem of disruption-free upgrades in their backup and
                    data-management appliance cluster. Before Rubrik he was with
                    the Infrastructure Team (Shared Technologies Group) at
                    Flipkart and was responsible for solving some interesting
                    challenges to help many services scale to power Big Billion
                    Days.
                    {/* <br />
                    <br /> */}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------2------------------------------------------ */}

          {speakerModal === "speaker2" && (
            <div
              id="speaker2"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Nikesh_Garera.png"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Nikesh Garera
                    </div>
                    <div className="text-md text-center w-full">
                      Princpal Data Scientist, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://in.linkedin.com/in/ngarera"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    "Nikesh comes with a deep background in natural language
                    processing spanning 18 years of industry/research experience
                    along with 35 patents/publications. He did his M.S from
                    Carnegie Mellon and a Ph.D from Johns Hopkins and has worked
                    at AI tech companies in US/India such as Kosmix and
                    WalmartLabs. At Flipkart, Nikesh leads our data science
                    efforts for Machine Translation, NLP and conversational AI
                    technologies.
                    <br />
                    <br />
                    Nikesh will be speaking about the various challenges
                    encountered in building translation models for Indian
                    languages such as e-commerce domain adaptation, code-mixing,
                    colloquial phrases and handling noisy text across diverse
                    e-commerce content such as catalog data, search query data
                    and user generated content. It will also cover some of the
                    practical considerations in scaling up and productionising
                    translation models such as leveraging data augmentation and
                    knowledge distillation based approaches."
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------3------------------------------------------ */}

          {speakerModal === "speaker3" && (
            <div
              id="speaker3"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Anantharam_Vanchiprakash.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Anantharam Vanchiprakash
                    </div>
                    <div className="text-md text-center w-full">
                      Senior Architect, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/anantharam-vanchi-prakash"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    "Just another guy working on distributed systems for the
                    past few years, passionate about technology. <br />
                    Anantharam will be talking on optimising &amp; storing data.
                    Choosing the right ways to store data can reduce the storage
                    footprint by over 90% and improve the performance by over
                    5x. When it comes to building true distributed systems,
                    ""Less is More"". "
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------4------------------------------------------ */}

          {speakerModal === "speaker4" && (
            <div
              id="speaker4"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Gaurav_Prasad.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Gaurav Prasad
                    </div>
                    <div className="text-md text-center w-full">
                      Architect, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/gaurav-75851415/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Gaurav Prasad is Passionate about technology. He has been
                    designing and building next generation agile and extensible
                    platforms at scale. He comes with rich experience in solving
                    distributed transactions. He will be sharing his experience
                    about Distributed Transaction management in an orchestration
                    system.
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------5------------------------------------------ */}

          {speakerModal === "speaker5" && (
            <div
              id="speaker5"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Sudhir-Reddy-Flipkart.png"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Sudhir Reddy
                    </div>
                    <div className="text-md text-center w-full">
                      Architect, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/discoversudhir/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Sudhir has been designing and developing highly available,
                    scalable distributed systems for close to two decades at
                    Flipkart and Yahoo!. He is currently working as Senior
                    Principal Architect in Flipkart’s Central Platforms Group.
                    He is responsible for making Infrastructure as a Service
                    (IaaS) and Platform as a Service (PaaS) offerings at par
                    with the best in the world, while retaining significant cost
                    advantage. Prior to this, he has designed, built, and
                    evolved systems like the Flipkart Data Platform, Machine
                    Learning Platform, Near Real-Time Insights platform, Yahoo!
                    Answers, Yahoo! Groups, Yahoo! Sponsored Search, Advertisers
                    &amp; Publishers systems.
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------6------------------------------------------ */}

          {speakerModal === "speaker6" && (
            <div
              id="speaker6"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Rahul_Agrawal.jpeg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Rahul Agrawal
                    </div>
                    <div className="text-md text-center w-full">
                      Architect, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/rahulagrawal31/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Rahul is working as an Architect in Fulfilment Services
                    group. He has bootstrapped projects like Logistics Promise
                    Engine, Product listing Ads Ranking Component, Network Data
                    Management for supply chain, Real time planning for supply
                    chain etc in Flipkart in last 10 years. He loves to build
                    platforms from scratch and scale them to FK needs. He will
                    be talking about scaling Mysql vertically which is an
                    unconventional approach when it comes to scaling.
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------7------------------------------------------ */}

          {speakerModal === "speaker7" && (
            <div
              id="speaker7"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Shyam_Akirala.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Shyam Akirala
                    </div>
                    <div className="text-md text-center w-full">
                      Software Developer, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="http://linkedin.com/in/shyamakirala"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    A technology enthusiast who enjoys building systems that
                    solve complex problems at scale. Shyam has been working with
                    distributed and scalable systems for close to a decade now.
                    At Flipkart, he has built multiple platform services and
                    currently working on the Promise ecosystem that powers
                    buyability of items and delivery date at userpath scale. At
                    this Slash N, Shyam will talk about the journey of
                    Flipkart’s serviceability data remodeling, how various data
                    optimization techniques helped in reducing the data
                    footprint by over 2000x thus keeping the data in an app's
                    memory.
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------8------------------------------------------ */}

          {speakerModal === "speaker8" && (
            <div
              id="speaker8"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Rajesh_Kannan.jpeg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Rajesh Kannan S
                    </div>
                    <div className="text-md text-center w-full">
                      Software Developer, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/rajesh-kannan-s-7a504224/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Rajesh is working in Customer Delivery Platform &amp; Data
                    platforms in Flipkart. He has worked on batch processing,
                    replication pipelines &amp; large scale rearchitectures
                    initiatives. He is passionate about data intensive
                    applications, distributed systems &amp; their evolutions in
                    the recent decades. He also has mechanical sympathy and does
                    performance tunings to get the native hardware performance.
                    Here he will share his learnings in scaling MySQL DBs.
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------9------------------------------------------ */}

          {speakerModal === "speaker9" && (
            <div
              id="speaker9"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Amrith_Shroff.jpeg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Amrith Shroff
                    </div>
                    <div className="text-md text-center w-full">
                      Software Developer, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/amrithnayak/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Amrith is Lead Developer for Listings Platform at Flipkart.
                    He is a technology enthusiast who regulary contributes to
                    Open Source. He is passionate about building reusable
                    platforms, exploring and adopting cuting edge technoogies
                    and contributing back to Open Source. He will be sharing the
                    pragmatic approach that was adopted for re-architecting the
                    Listings Platform which is a mix of multiple approaches
                    including Domain Driven Design, Modualr Monolith, Plugins to
                    name a few.
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------10------------------------------------------ */}

          {speakerModal === "speaker10" && (
            <div
              id="speaker10"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Pradeep_Nain.jpeg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Pradeep Nain
                    </div>
                    <div className="text-md text-center w-full">
                      Software Developer, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/pradeepnain/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Pradeep is working in the Listings team in Flipkart, which
                    receives millions of updates from multiple tenants. While he
                    is enthusiastic about distributed systems and worked in
                    complex domains with multi-tenancy, reliable bulk processing
                    at scale, he gives equal importance to customer experience.
                    His short stint in testing profile gives him an edge while
                    building applications, debugging. In slashn, he'll be
                    talking about the decisions, challenges, opportunities while
                    re-architecting listings platform, to make listing/policy
                    management smooth for business, devs and devops.
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------11------------------------------------------ */}

          {speakerModal === "speaker11" && (
            <div
              id="speaker11"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal  overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg h-2/6"
                        src="./assets/speakers/Mallikarjun.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Mallikarjun V
                    </div>
                    <div className="text-md text-center w-full">
                      Software Developer, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/vmallikarjun/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Engineer by heart and profession. Mallikarjun works as a
                    senior engineer at Flipkart, building customisations on top
                    of hbase to support multi-tenancy and hbase committer. Takes
                    good care of very large cluster deployments of multi-tenant
                    hbase serving tenants like order management system, cart,
                    checkout, payments, games, user session, etc and are capable
                    of scaling to millions of qps with sub 100ms tail latencies.{" "}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------12------------------------------------------ */}

          {speakerModal === "speaker12" && (
            <div
              id="speaker12"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Anand - PP.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Anand Immannavar
                    </div>
                    <div className="text-md text-center w-full">
                      Senior Architect, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/anandimmanavar/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Anand is a true Flipkart veteran – having joined as a
                    fresher in 2010 when Flipkart’s technology team was just 16
                    members! He has 11+ years of experience working with complex
                    techno-functional domains related to supply-chain,
                    accounting &amp; finance, seller ecosystem, retail and
                    planning. Anand is currently building Retail-as-a-Service
                    enterprise suite for large scale retailers at Flipkart.{" "}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------13------------------------------------------ */}

          {speakerModal === "speaker13" && (
            <div
              id="speaker13"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Kinshuk_Bairagi.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Kinshuk Bairagi
                    </div>
                    <div className="text-md text-center w-full">
                      Architect, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/kinshukbairagi/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Kinshuk is working as an Architect in the Fulfilment
                    Services Group. Having spent almost 9 years in Flipkart he
                    has built systems like Journey Platform(Complex Realtime
                    Event Processing System), Connekt (Mutil Channel
                    Communications Platform), Strowger(Flipkart OSS Voice
                    Telecom Infrastructure), etc . At this Slash N, Kinshuk will
                    talk about building a Consistent Multi-Site Distributed KV
                    Store.{" "}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------14------------------------------------------ */}

          {speakerModal === "speaker14" && (
            <div
              id="speaker14"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal  overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Kaustav _Das.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Kaustav Das
                    </div>
                    <div className="text-md text-center w-full">
                      Software Developer, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/kaustav-das-1084b824/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Kaustav is working at Senior Engineer in Transact, have
                    joined Flipkart in 2013, has experience in building
                    resilient complex systems dealing with high scale, currently
                    working on transact platformization piece, He will be
                    sharing his experience about Distributed Transaction
                    management system built as part of platformization.{" "}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------15------------------------------------------ */}

          {speakerModal === "speaker15" && (
            <div
              id="speaker15"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Varsha_Saha.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Varsha Saha
                    </div>
                    <div className="text-md text-center w-full">
                      Tech Lead - Software Engineer, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/varsha-saha-0a10a0113"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Tech Lead at Flipkart working for the Next 200 mn team.
                    JavaScript enthusiast with almost a decade of web dev
                    experience. Love to code, shop, pet dogs and read thrillers.{" "}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------16------------------------------------------ */}

          {speakerModal === "speaker16" && (
            <div
              id="speaker16"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal  overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Govil_Khatri.png"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Govil Khatri
                    </div>
                    <div className="text-md text-center w-full">
                      Software Developer, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/govil-khatri-57368023/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Govil is working in Opus which is a Online Learning ML
                    platform which provides end to end ML solution to manage
                    data, model predictions, deployment and monitoring at scale.
                    He is also currently working in Morpheus which is a content
                    generation platform and serves content providers like Reco,
                    Neo, CRM etc. In the past he built Gift vouchers services,
                    Neo and CRM platform in Flipkart. He is passionate about
                    building and delivering high quality data intensive
                    applications.{" "}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------17------------------------------------------ */}

          {speakerModal === "speaker17" && (
            <div
              id="speaker17"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal  overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/IMG-0181.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Jain Johny
                    </div>
                    <div className="text-md text-center w-full">
                      Architect, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/jainjohny/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Jain Johny is part of the Flipkart Cloud Platform (FCP) team
                    since its inception in 2015. He has been part of three large
                    Data Centre deployments and contributed to the evolution of
                    hardware and software stacks in FCP. He is currently
                    responsible for the selection of server and storage hardware
                    components and platforms and driving the disaggregated
                    storage evolution at FCP. He has also worked on end to end
                    private cloud infrastructure software including servers and
                    storage provisioning, virtualisation and lifecycle
                    management systems.{" "}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------18------------------------------------------ */}

          {speakerModal === "speaker18" && (
            <div
              id="speaker18"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Abhishek_Tyagi.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Abhishek Tyagi
                    </div>
                    <div className="text-md text-center w-full">
                      Architect, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/abhishektyagi/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Abhishek has start programming at early age, and pursued
                    that passion. Abhishek works as an Architect for Promise
                    Ecosystem in Flipkart, where he works on various distributes
                    storage and computation challenges. His tech interest are
                    caching, storage and cryptography. He, with Shyam Akirala
                    will be presenting the journey of Flipkart’s serviceability
                    data remodeling, how various data optimization techniques
                    helped in reducing the data footprint by over 2000x thus
                    keeping the data in an app's memory.{" "}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------19------------------------------------------ */}

          {speakerModal === "speaker19" && (
            <div
              id="speaker19"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Rahul_Pradeep.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Rahul Pradeep
                    </div>
                    <div className="text-md text-center w-full">
                      Software Developer, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/rahulpradeep/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Rahul is an engineer in the AdTech and Discovery team. He
                    has spent almost 7 years in Flipkart and built systems like
                    Discovery Jockey (Page Federation Service) and Morpheus
                    (Content Generation Platform). He is currently working on
                    building a generic stream processing platform to create and
                    update denormalised views on changes in a normalised
                    dataset. He, along with Ruchika, will talk about the
                    architecture of Morpheus, which is responsible for
                    generating millions of visually rich content to power
                    Flipkart Homepage and Category Landing Pages.
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------20------------------------------------------ */}

          {speakerModal === "speaker20" && (
            <div
              id="speaker20"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Ruchika_Kundu.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Ruchika Kundu
                    </div>
                    <div className="text-md text-center w-full">
                      Software Developer, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/ruchikakundu/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Ruchika is a software developer in Flipkart, working in Ad
                    Tech and Discovery domain. She has been contributing to
                    content generation platform(Morpheus) since 2 years and is
                    currently working on generation of a highly scalable content
                    generation service for real time use cases. She has spent
                    almost 3 years in Flipkart and has also contributed to
                    Retail as a service suite. She, along with Rahul, will talk
                    about the architecture of Morpheus , our auto content
                    platform which generates millions of rich content for
                    various use cases across Flipkart homepage and Category
                    Landing Pages.
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------21------------------------------------------ */}

          {speakerModal === "speaker21" && (
            <div
              id="speaker21"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Sarthak_Agarwal.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Sarthak Agarwal
                    </div>
                    <div className="text-md text-center w-full">
                      Software Developer, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/sarthak-agarwal/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Sarthak is a Software Developer in the Merchandising,
                    Monetization & Marketing (M3) team of Flipkart. For the past
                    2 years, he has been working in Opus, an end to end ML
                    Platform focussed on Online learning, which helps in
                    managing data, model training, ranking, and monitoring at
                    scale. He is also working with Connekt, which is the last
                    mile delivery platform for all kinds of communications sent
                    to the users by Flipkart. He, along with Govil, will be
                    talking about the motivation behind Opus, its architecture,
                    and the significant business impact it has made by helping
                    Flipkart serve more personalised and engaging content to
                    users.
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------22------------------------------------------ */}

          {speakerModal === "speaker22" && (
            <div
              id="speaker22"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Anuj_Mittal.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Anuj Mittal
                    </div>
                    <div className="text-md text-center w-full">
                      Senior Architect, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/anuj-mittal-rcf/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    A sports aficionado & wishful artist surviving on the
                    adrenaline rush that technology provides, when it all comes
                    together to change the world. Officially titled as Senior
                    Architect @ Flipkart, but I imagine myself as problem solver
                    and enabler, knowing tech is the nuclear weapon in arsenal.
                    Data is the new the oil, refining it to unlock unimagined
                    business potential, is what keeps me busy these days.
                    Pulling strings to keep the complex BigData world simple and
                    cost effective @Flipkart Scale.
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------23------------------------------------------ */}

          {speakerModal === "speaker23" && (
            <div
              id="speaker23"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Sunil_Kumar.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Sunil Kumar
                    </div>
                    <div className="text-md text-center w-full">
                      Director Engineering, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/sunilkumar273/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Over 16 years of experience in building teams, directing and
                    delivering large scale business applications. A technology
                    leader with deep interest in Analytics and Machine learning
                    systems acting as key initiator in conceptualizing and
                    strategizing of Data based ecosystems. Currently Managing
                    everything BigData@Flipkart
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* -----------------------------------------23------------------------------------------ */}

          {speakerModal === "speaker24" && (
            <div
              id="speaker24"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/Robin.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Robin KC
                    </div>
                    <div className="text-md text-center w-full">
                      Senior Architect, Flipkart
                    </div>
                    {/* <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/sunilkumar273/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div> */}
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    I am Robin. Working as Senior Architect @ Flipkart with the
                    Flipkart Data Platform team I am Deeply plugged into Stream
                    Processing architectures at scale along with reporting and
                    analytics. Mostly working on open source tech stacks in the
                    Big Data domain like Spark, Flink, Kafka, Hbase, Druid,
                    ElasticSearch.
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* 25 */}
          {speakerModal === "speaker25" && (
            <div
              id="speaker25"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/md-mehrab.jpeg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Md Mehrab Alam
                    </div>
                    <div className="text-md text-center w-full">
                      Software Developer, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://linkedin.com/in/iammehrabalam"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    A Software developer in Flipkart Data Platform. My interest
                    is in BigData space. In Flipkart Data Platform, I am working
                    on Ingestion systems and Fstream (our internal Streaming
                    Framework) which internally usages Open source tech stacks
                    like Apache Kafka, Apache Spark, Apache Flink, HDFS etc.
                  </div>
                </div>
              </div>
            </div>
          )}
          {/*  */}
          {/* 26 */}
          {speakerModal === "speaker26" && (
            <div
              id="speaker26"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/sachin-sinivasan.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Sachin Srinivasan
                    </div>
                    <div className="text-md text-center w-full">
                      Software Developer, Flipkart Camera
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/sachin-srinivasan-03103895"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Sachin is a Software developer. Has experience working on
                    various web technologies like ReactJS, NodeJs, Java, AWS,
                    GCP, Docker and K8s. He was part of Scapic, an Augmented
                    reality startup that was acquired by Flipkart in Nov 2020.
                    Currently he is part of the Flipkart Camera team focused on
                    building immersive AR & 3D experiences for the Flipkart
                    userbase.{" "}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/*  */}
          {/* 27 */}
          {speakerModal === "speaker27" && (
            <div
              id="speaker27"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/ajay-venketsha.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Ajay Venkatesha
                    </div>
                    <div className="text-md text-center w-full">
                      Senior Engineering Manager, Flipkart Camera
                      <br /> (previously Co-Founder, Scapic)
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/ajay-ponna-venkatesh-b7521924/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    He was the Co-founder at Scapic, an Augmented reality
                    startup that was acquired by Flipkart in Nov 2020.
                    Currently, he leads the Flipkart Camera team. Flipkart
                    Camera is unifying all camera experiences across different
                    touchpoints in the FK app & offering an immersive experience
                    to FK users, mainly through 3D & Augmented Reality. Before
                    Scapic, Ajay has spent 2 years with Gridarster, a US based
                    AR/VR startup & 4.5 years at Cisco research team. He has 2
                    patents filed in the AR/VR domain, one of which was granted
                    earlier this year.
                  </div>
                </div>
              </div>
            </div>
          )}
          {/*  */}
          {/* 28 */}
          {speakerModal === "speaker28" && (
            <div
              id="speaker28"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/ayush-agarwal.jpeg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Ayush Agarwal
                    </div>
                    <div className="text-md text-center w-full">
                      Software Developer, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/ayush-agarwal-48251342/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    A Software developer in Flipkart Core Services. Worked on
                    multiple platforms like Journey (Event processing system) ,
                    USL (user state layer) , Connekt (Communication service),
                    Now part of Session service platform to manage session of
                    all Flipkart users. He will be talking about the motivation
                    behind Journey platorm, its features and the impact its
                    making to onboard any complex usecases in Flipkart easily.
                  </div>
                </div>
              </div>
            </div>
          )}
          {/*  */}
          {/* 29 */}
          {speakerModal === "speaker29" && (
            <div
              id="speaker29"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/avinash-vundyala.jpeg"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Avinash Vundyala
                    </div>
                    <div className="text-md text-center w-full">
                      Software Developer, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/avinash-vundyala/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Avinash is a Software Developer at Flipkart with the
                    Engagement team. Have worked on building various systems
                    that provide immersive and interactive video content.
                    Passionate about end-to-end product solutioning and building
                    date intensive distributed systems.
                  </div>
                </div>
              </div>
            </div>
          )}
          {/*  */}
          {/* 30 */}
          {speakerModal === "speaker30" && (
            <div
              id="speaker30"
              className="fixed top-0 left-0 z-50 w-screen h-screen smodal overflow-auto"
            >
              <div className="flex w-full h-full md:items-center justify-center md:p-20 p-10 ">
                <div className="bg-white rounded-lg shadow p-6 grid md:grid-cols-3 gap-6 relative overflow-auto">
                  <div
                    className="modal-close absolute right-5 top-5 cursor-pointer rounded-full w-6 h-6 flex items-center justify-center font-semibold"
                    style={{ backgroundColor: "#2E3192", color: "#fff" }}
                    onClick={() => setSpeakerModal("")}
                  >
                    X
                  </div>
                  <div className="md:col-span-1 rounded-lg p-3 shadow space-y-4">
                    <div
                      className="text-center w-full"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        className="w-full popup-avatar-size object-cover rounded-lg"
                        src="./assets/speakers/vikasmishra.png"
                        alt="avatar"
                      />
                    </div>
                    <div className="text-xl font-bold text-center w-full">
                      Vikas Mishra
                    </div>
                    <div className="text-md text-center w-full">
                      Software Developer, Flipkart
                    </div>
                    <div className="flex justify-center items-center text-sm">
                      <a
                        href="https://www.linkedin.com/in/mishravikas007/"
                        target="_blank"
                      >
                        Click here to connect on LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="md:col-span-2 text-md pt-6">
                    Vikas is a software developer at Flipkart with the
                    engagement team. Have spent seven years in Mobile
                    engineering on E-Comm, Banking. Have Worked on Gamification,
                    Data analytics tracking, Loyalty programs, Interactive
                    videos. Loves solving new technical challenges everyday.
                  </div>
                </div>
              </div>
            </div>
          )}
          {/*  */}
        </div>
      </section>
      {/* FAQ Section  */}

      <section>
        <div className="relative">
          <div className="container mx-auto px-10 py-16 pb-10">
            <div className="relative">
              <div className="text-right md:text-5xl text-3xl font-bold text-white md:px-32 px-16">
                FAQs
              </div>
              <img
                className
                style={{ marginTop: "-10px" }}
                src="./assets/underline.svg"
                alt="underline"
              />
            </div>
          </div>
          <div className="container mx-auto px-10 pb-16  relative">
            <div className="grid lg:grid-cols-2 gap-4 md:gap-x-12 md:gap-y-4 md:px-32 ">
              <div className="space-y-4">
                <div className="border border-indigo-500/50 p-3  text-white cursor-pointer">
                  <details className="rounded-lg text-white" closed>
                    <summary className="text-xl leading-6 font-semibold select-none flex items-center justify-between text-xl">
                      <div>How do we register for the event ?</div>
                      <img src="./assets/plus.png" alt="plus" />
                    </summary>
                    <div className="mt-3 text-sm md:text-base leading-6 text-gray-300">
                      <p>
                        The Top bar with the <strong>‘Register Now’</strong>{" "}
                        button will direct you to the page that has individual
                        registrations for Flipsters and Non-Flipsters
                        respectively. Please fill in the requested details and
                        submit the form to register for the event.
                      </p>
                    </div>
                  </details>
                </div>
                <div className="border border-indigo-500/50 p-3  text-white cursor-pointer">
                  <details className="rounded-lg text-white" closed>
                    <summary className="text-xl leading-6 font-semibold select-none flex items-center justify-between text-xl">
                      <div>Where can I learn more about slash n ?</div>
                      <img src="./assets/plus.png" alt="plus" />
                    </summary>
                    <div className="mt-3 text-sm md:text-base leading-6 text-gray-300">
                      <p>
                        Check out the <strong>‘About’</strong> tab on the slash
                        n website to know about slash n and watch some of the
                        throwback videos
                      </p>
                    </div>
                  </details>
                </div>
              </div>
              <div className="space-y-4">
                <div className="border border-indigo-500/50 p-3  text-white cursor-pointer">
                  <details className="rounded-lg text-white" closed>
                    <summary className="text-xl leading-6 font-semibold select-none flex items-center justify-between text-xl">
                      <div>Who are the speakers for the event ?</div>
                      <img src="./assets/plus.png" alt="plus" />
                    </summary>
                    <div className="mt-3 text-sm md:text-base leading-6 text-gray-300">
                      <p>
                        The speaker line-up is listed out on the{" "}
                        <strong>‘Speaker Tab’</strong> on the website along with
                        their bios.{" "}
                      </p>
                    </div>
                  </details>
                </div>
                <div className="border border-indigo-500/50 p-3  text-white cursor-pointer">
                  <details className="rounded-lg text-white" closed>
                    <summary className="text-xl leading-6 font-semibold select-none flex items-center justify-between text-xl">
                      <div>
                        Where can I find updates on tech hiring for Flipkart ?
                      </div>
                      <img src="./assets/plus.png" alt="plus" />
                    </summary>
                    <div className="mt-3 text-sm md:text-base leading-6 text-gray-00">
                      <p>
                        Log on to{" "}
                        <a
                          href="https://www.flipkartcareers.com/"
                          target="_blank"
                        >
                          www.flipkartcareers.com
                        </a>{" "}
                        for updates on careers at Flipkart.
                      </p>
                    </div>
                  </details>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ----------------------------------Footer section------------------------- */}

      {/* <div
          className="absolute top-0 left-0 h-full w-full"
          style={{ background: "rgba(0,0,0,0.6)", zIndex: "40" }}
        /> */}

      <footer>
        <div className="flex justify-between items-end pl-28">
          <img
            src="./assets/footer_left_image.png"
            style={{ width: 150, marginBottom: "3rem" }}
            alt="gear"
          />
          <img
            src="./assets/footer-right-image.png"
            style={{ width: 350 }}
            alt="image"
          />
        </div>
        <div className="bg-white p-16 text-center space-y-4">
          <div className="text-3xl font-medium">
            <Link
              to="/privacy-policy"
              // href="https://docs.google.com/document/d/1Xzn3i0SR6WCNVRoa6Lyu2BjUscrRCAnKqU-9fZFS_C4/edit"
              // target="_blank"
            >
              Privacy Policy
            </Link>{" "}
            And{" "}
            <Link
              to="/terms-of-use"
              // href="https://docs.google.com/document/d/1WPvPzekjo1uW9lzeFSQRa0hgAlVcr1bey5YiqD1-FrM/edit"
              // target="_blank"
            >
              Terms Of Use
            </Link>
          </div>
        </div>
      </footer>
      {/* </Layout> */}
    </div>
  );
}

export default HomePage;
