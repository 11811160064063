import React from "react";
import { FaRegPlayCircle } from "react-icons/fa";
import { BiPlay } from "react-icons/bi";

function SleekButton({ link, setVideolink }) {
  return (
    <div
      className="rounded py-0.5 pl-2 pr-1 flex justify-center items-center divide-x divide-blue-800 cursor-pointer"
      style={{
        background: "linear-gradient(90deg, #2E3192 -0.01%, #662D91 99.99%)",
      }}
      onClick={() => setVideolink(link)}
    >
      {/* <span className="text-xs italic pr-1">Play</span> */}
      <BiPlay className=" text-xl" />
    </div>
  );
}

export default SleekButton;
